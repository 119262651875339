/* eslint-disable no-nested-ternary */
import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Filters, Card, Toast, SkeletonPage, Button, Page, DataTable, EmptyState, Spinner, Stack, Thumbnail, Badge } from '@shopify/polaris';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
// import { EmbedMinor } from '@shopify/polaris-icons';
import useDebounce from '../../hooks/useDebounce';
import { stateConverter } from '../FilterEngine/filterParams';
import { appliedFiltersQuery, handleFilterChange } from '../FilterEngine/FilterEngine';
import TablePagination from '../FilterEngine/TablePagination/TablePagination';
import defaultImage from '../../assets/images/default_product.jpg';
import Badges from '../Shared/Badges';
import EmbeddedProductsModal from './EmbeddedProductsModal';
import productTypeFilterDefinition from '../FilterEngine/ProductType/productTypeFilterDefinition';
import productTypeFilterActive from '../FilterEngine/ProductType/productTypeFilterActive';
import statusFilterDefinition from '../FilterEngine/Status/statusFilterDefinition';
import statusFilterActive from '../FilterEngine/Status/statusFilterActive';
import isEmpty from '../Shared/isEmpty';

const Products = (props) => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [lastPage, setLastPage] = useState(null);
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [filterValues, setFilterValues] = useState({
    product_types: [],
    statuses: []
  });

  const [toastActive, setToastActive] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const { t } = useTranslation();
  const location = useLocation();
  const history = useNavigate();

  const [tableFilters, setTableFilters] = useState(stateConverter(location));
  const debouncedSearch = useDebounce(tableFilters, 400);

  const appliedFilters = useCallback(() => {
    const af = [];

    if (!isEmpty(tableFilters.product_type)) {
      af.push(productTypeFilterActive(t, { history, location }, setTableFilters, tableFilters.product_type));
    }
    if (!isEmpty(tableFilters.status)) {
      af.push(statusFilterActive(t, { history, location }, setTableFilters, tableFilters.status));
    }
    return af;
  }, [history, location, t, tableFilters.product_type, tableFilters.status]);

  const fetchProduct = useCallback(() => {
    const params = {
      per_page: 30,
      page: tableFilters.page,
      search: tableFilters.queryValue,
      q: appliedFiltersQuery(appliedFilters()),
      s: { sorts: tableFilters.sorts }
    };

    axios.post('/v1/products/search', params)
      .then((res) => {
        setLastPage(res.data.lastPage);
        setProducts(res.data.products);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [debouncedSearch]);

  const filters = [
    productTypeFilterDefinition(t, { history, location }, setTableFilters, filterValues.product_types, tableFilters.product_type),
    statusFilterDefinition({ history, location }, setTableFilters, filterValues.statuses, tableFilters.status)
  ];

  const placeHolderText = t('product.filter');

  const filterControl = (
    <Filters
      queryValue={tableFilters.queryValue}
      filters={filters}
      onQueryChange={handleFilterChange({ history, location }, setTableFilters, 'queryValue')}
      onQueryClear={handleFilterChange({ history, location }, setTableFilters, 'queryValue', 'reset', '')}
      onClearAll={handleFilterChange({ history, location }, setTableFilters, '/products', 'resetAll')}
      appliedFilters={appliedFilters()}
      queryPlaceholder={placeHolderText}
    />
  );

  useEffect(() => {
    fetchProduct();
  }, [fetchProduct]);

  const fetchFilterValues = useCallback(() => {
    axios.get('/v1/products/filter_values')
      .then((res) => {
        setFilterValues(res.data);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    fetchFilterValues();
  }, [fetchFilterValues]);

  let productHeadings = [
    t('page_builder.title'),
    t('user.status'),
    t('shared.price'),
    t('products.owner'),
    'Tags',
    t('shared.type')
  ];

  if (!props.hasInvoiceAccess) {
    productHeadings = [
      t('page_builder.title'),
      t('user.status'),
      t('products.owner'),
      'Tags',
      t('shared.type')
    ];
  }

  const getProductPath = (type) => {
    switch (type) {
      case 'TimeSlot': return 'products';
      case 'Membership': return 'memberships';
      case 'LessonPackage': return 'event_package';
      default: return 'products';
    }
  };

  const getProductBadgeColor = (type) => {
    switch (type) {
      case 'TimeSlot': return 'success';
      case 'Membership': return 'warning';
      case 'LessonPackage': return 'info';
      default: return 'warning';
    }
  };

  const productsTableModifier = (tableData) => tableData.map((row) => {
    const modifiedRow = [];

    modifiedRow.push(
      <Stack alignment="center" spacing="extraTight">
        <Thumbnail size="large" alt="Product" source={row.product_image || defaultImage} />
        <Button plain url={`/${getProductPath(row.type)}/${row.token}`}>{row.title}</Button>
      </Stack>
    );
    modifiedRow.push(<Badge status={row.active ? 'info' : 'critical'}>{row.active ? t('shared.active') : t('client.inactive')}</Badge>);
    if (props.hasInvoiceAccess) modifiedRow.push(row.price);
    modifiedRow.push(<Badge status="warning">{row.owner.name}</Badge>);
    modifiedRow.push(<Badges data={row.tags} />);
    modifiedRow.push(<Badge status={getProductBadgeColor(row.type)}>{t(`memberships.${row.type}`)}</Badge>);

    return modifiedRow;
  });

  let embeddedProductsModal = null;
  if (showProductsModal) {
    embeddedProductsModal = (
      <EmbeddedProductsModal
        title={t('embed.products')}
        cleanup={() => setShowProductsModal(false)}
      />
    );
  }

  return (
    <Page
      primaryAction={((props.isStaff && props.bookingPageAccess) || !props.isStaff) && { content: t('products.new'), onClick: () => history('/products/new') }}
      // secondaryActions={!props.isStaff && [{ icon: EmbedMinor, content: 'Embedded Link', onClick: () => setShowProductsModal(true) }]}
    >
      {embeddedProductsModal}
      {toastMarkup}
      {!loading ? (
        <Card sectioned>
          <Card.Subsection>
            {filterControl}
          </Card.Subsection>
          <Card.Subsection>
            {!loading ? (
              products?.length ? (
                <DataTable
                  verticalAlign="middle"
                  columnContentTypes={[
                    'text',
                    'text',
                    'text',
                    'text'
                  ]}
                  headings={productHeadings}
                  rows={productsTableModifier(products)}
                  footerContent={`${products?.length} ${t('products.singular')}`}
                />
              ) : <EmptyState image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png" />
            ) : <Spinner />}
          </Card.Subsection>
        </Card>
      ) : <SkeletonPage />}
      <TablePagination
        pageFilter={tableFilters.page}
        setTableFilters={setTableFilters}
        records={products}
        lastPage={lastPage}
      />
    </Page>
  );
};

const mapStateToProps = (state) => ({
  isPremiumPlusMember: state.auth.premiumPlusMember,
  hasInvoiceAccess: state.auth.hasInvoiceAccess,
  bookingPageAccess: state.auth.bookingPageAccess,
  isStaff: state.auth.role === 'staff'
});

export default connect(mapStateToProps)(Products);
