/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Banner, Card, TextStyle, Page, Spinner, Button } from '@shopify/polaris';
import moment from 'moment-timezone';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import ExternalLink from '../Shared/ExternalLink';
import { ReactFormGenerator } from '../Shared/FormBuilder';
import PaymentOptions from '../Shared/PaymentOptions';

const ProductBooking = () => {
  const [productBooking, setProductBooking] = useState({
    event: {},
    client: {},
    custom_fields: []
  });
  const [loading, setLoading] = useState(true);
  const matchParams = useParams();
  const history = useNavigate();

  const fetchProductBooking = useCallback(() => {
    axios.get(`/v1/product_bookings/${matchParams.token}`)
      .then((res) => {
        setProductBooking(res.data);
      })
      .catch(() => {
      })
      .then(() => {
        setLoading(false);
      });
  }, [matchParams.token]);

  useEffect(() => {
    fetchProductBooking();
  }, [fetchProductBooking]);

  const { t } = useTranslation();

  const customFields = () => {
    const items = [];
    productBooking.custom_fields.forEach((cf, i) => {
      items.push((
        <Card.Section key={i} title={cf.label}>
          <TextStyle>{cf.value}</TextStyle>
        </Card.Section>
      ));
    });

    return items;
  };

  const downloadInvoice = (id) => {
    axios.get(`/v1/invoices/${id}/download`)
      .then((response) => {
        window.open(response.data.url);
      })
      .catch(() => {});
  };

  return (
    <Page
      title="Copy of Booking Confirmation"
      breadcrumbs={[
        {
          content: t('shared.back'),
          onAction: () => history(-1)
        }
      ]}
      separator
    >
      <Card sectioned>
        {loading ? <Spinner /> : (
          productBooking.type === 'ProductBooking::TimeSlot' ? (
            <>
              <Banner title={`${t('calendar.signed_up')} - ${productBooking.event.title}`} status="success">
                <p>{`${t('product.confirmation_was_sent')} ${productBooking.client.email}`}</p>
                <p>{`${t('calendar.registered_on')} ${moment(productBooking.created_at).format('LLLL')}`}</p>
              </Banner>
              <Card.Section title={t('shared.start')}>
                {moment(productBooking.event?.start).format('LLLL')}
              </Card.Section>
              <Card.Section title={t('shared.end')}>
                {moment(productBooking.event?.end).format('LLLL')}
              </Card.Section>
              <Card.Section title={t('instructor.instructor')}>
                <Button plain onClick={() => history(`/staffs/${productBooking.user?.token}`)}>{productBooking.user?.name}</Button>
              </Card.Section>
              <Card.Section title={t('client.client')}>
                <Button plain onClick={() => history(`/clients/${productBooking.client?.token}`)}>{productBooking.client?.name}</Button>
              </Card.Section>
              {productBooking.event?.pretty_price ? (
                <Card.Section title={t('shared.price')}>
                  {productBooking.event.pretty_price}
                </Card.Section>
              ) : ''}
              {productBooking.event?.description ? (
                <Card.Section title={t('shared.description')}>
                  {productBooking.event.description}
                </Card.Section>
              ) : ''}
              {Number.isInteger(productBooking.event?.spots_remaining) ? (
                <Card.Section title={t('events.spots_remaining')}>
                  {productBooking.event?.spots_remaining}
                </Card.Section>
              ) : ''}
              {productBooking.event?.location ? (
                <Card.Section title={t('address.location')}>
                  {productBooking.event.location}
                </Card.Section>
              ) : ''}
              {productBooking.event?.online ? (
                productBooking.event.conference_url ? (
                  <Card.Section title="Online">
                    <ExternalLink url={productBooking.event.conference_url} label={productBooking.event.conference_url} />
                  </Card.Section>
                ) : (
                  <Card.Section title="Online">
                    {t('booking.online_event')}
                  </Card.Section>
                )
              ) : ''}
              <div className="border-t">
                <div className="ml-4">
                  <ReactFormGenerator
                    read_only
                    hide_actions
                    data={JSON.parse(productBooking.viewReply?.data || '[]')}
                    answer_data={productBooking.viewReply?.answerData || []}
                  />
                </div>
              </div>
              <Card.Section title="Payment method">
                {PaymentOptions(t).find((option) => option.value === productBooking.payment_method)?.label}
              </Card.Section>
              <Card.Section title="Invoice">
                <Button plain onClick={() => downloadInvoice(productBooking.invoice?.token)}>{productBooking.invoice?.number}</Button>
              </Card.Section>
              {customFields()}
            </>
          ) : (
            <>
              <Banner status={productBooking.membership?.state === 'active' ? 'success' : 'info'}>
                <p>{`${t('product.confirmation_was_sent')} ${productBooking.client.email}`}</p>
                <p>{moment(productBooking.created_at).format('LLLL')}</p>
              </Banner>
              <Card.Section title={t('client.client')}>
                <Button plain onClick={() => history(`/clients/${productBooking.client?.token}`)}>{productBooking.client?.name}</Button>
              </Card.Section>
              <Card.Section title={t('client.fields.status')}>
                {t(`membership.states.${productBooking.membership?.state}`)}
              </Card.Section>
              <Card.Section title={t('shared.price')}>
                {productBooking.membership?.price}
              </Card.Section>
              {customFields()}
            </>
          )
        )}
      </Card>
    </Page>
  );
};

export default ProductBooking;
