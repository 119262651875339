import { CalendarIcon, ClipboardDocumentCheckIcon, GiftIcon, UserIcon } from '@heroicons/react/24/outline';

const Templates = (t, subdomain) => {
  const items = [
    {
      name: t('email_template.templates.name.product_booking'),
      description: t('email_template.templates.name.product_booking_desc'),
      path: 'booking_mailer/client_confirmation',
      iconColor: 'bg-purple-500',
      icon: ClipboardDocumentCheckIcon,
      default: {
        subject: `${t('email_template.templates.booking_confirmation', { subdomain })} - {{product_title}} ${t('shared.with')} {{name}} - {{start_time}}`,
        body: t('email_template.templates.product_booking', { subdomain })
      }
    },
    {
      name: t('email_template.product_booking_staff_notification'),
      description: 'Staff confirmation email when an event has been booked',
      path: 'booking_mailer/instructor_new_booking',
      iconColor: 'bg-purple-700',
      icon: ClipboardDocumentCheckIcon,
      default: {
        subject: '',
        body: ''
      }
    },
    {
      name: t('email_template.templates.name.event_registration'),
      description: t('email_template.templates.name.event_registration_desc'),
      path: 'user_mailer/event_registration',
      iconColor: 'bg-green-500',
      icon: CalendarIcon,
      default: {
        subject: t('email_template.templates.new_event', { subdomain }),
        body: t('email_template.templates.event_registration', { subdomain })
      }
    },
    {
      name: t('email_template.templates.event_cancellation_subject'),
      description: t('email_template.templates.name.event_cancellation_desc'),
      path: 'user_mailer/event_cancellation',
      iconColor: 'bg-red-500',
      icon: CalendarIcon,
      default: {
        subject: t('email_template.templates.event_cancellation_subject', { subdomain }),
        body: t('email_template.templates.event_cancellation', { subdomain })
      }
    },
    {
      name: t('email_template.event_canacellation_staff'),
      description: t('email_template.templates.name.event_cancellation_desc'),
      path: 'user_mailer/event_client_cancellation_notification',
      iconColor: 'bg-red-500',
      icon: CalendarIcon,
      default: {
        subject: t('email_template.templates.event_cancellation_subject', { subdomain }),
        body: ''
      }
    },
    {
      name: t('email_template.event_update'),
      description: t('email_template.email_update_desc'),
      path: 'user_mailer/event_update',
      iconColor: 'bg-orange-500',
      icon: CalendarIcon,
      default: {
        subject: t('email_template.event_update'),
        body: ''
      }
    },
    {
      name: t('shared.new_client'),
      description: t('email_template.templates.name.new_client_desc'),
      path: 'user_mailer/welcome_email',
      iconColor: 'bg-blue-500',
      icon: UserIcon,
      default: {
        subject: t('email_template.templates.new_client_subj', { subdomain }),
        body: t('email_template.templates.new_client', { subdomain })
      }
    },
    {
      name: 'New Staff',
      description: 'Initial email to staff on creation',
      path: 'user_mailer/welcome_staff_email',
      iconColor: 'bg-teal-400',
      icon: UserIcon,
      default: {
        subject: t('email_template.templates.new_client_subj', { subdomain }),
        body: t('email_template.templates.new_staff', { subdomain })
      }
    },
    {
      name: 'New gift card receipt',
      description: 'Send notification to client about their new gift card',
      path: 'user_mailer/send_gift_card_receipt',
      iconColor: 'bg-green-500',
      icon: GiftIcon,
      default: {
        subject: '',
        body: ''
      }
    }
    // {
    //   name: 'Invoice',
    //   description: 'Notify user when a new invoice is created',
    //   href: '#',
    //   iconColor: 'bg-pink-500',
    //   icon: SpeakerphoneIcon
    // }
  ];

  return items;
};

export default Templates;
