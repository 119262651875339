import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import { Filters, Card, SkeletonPage, Page, DataTable, EmptyState, Button, Popover, ActionList } from '@shopify/polaris';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DeleteMajor, MobileVerticalDotsMajor } from '@shopify/polaris-icons';
import useDebounce from '../../hooks/useDebounce';
import { stateConverter } from '../FilterEngine/filterParams';
import { handleFilterChange, searchFilterToURL, appliedFiltersQuery } from '../FilterEngine/FilterEngine';
import TablePagination from '../FilterEngine/TablePagination/TablePagination';
import { defaultSortDirection, handleSort, initialSortColumnIndex } from '../FilterEngine/Sort/sort';

const EmailTemplates = () => {
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState([]);
  const [mounted, setMounted] = useState(true);
  const [init, setInit] = useState(false);
  const [lastPage, setLastPage] = useState(null);

  const { t } = useTranslation();
  const history = useNavigate();
  const location = useLocation();

  const [tableFilters, setTableFilters] = useState(stateConverter(location));
  const debouncedSearch = useDebounce(tableFilters, 400);

  const filters = [];

  const appliedFilters = useCallback(() => {
    const af = [];

    return af;
  }, []);

  const [rowActionsActive, setRowActionsActive] = useState({});
  const toggleRowActionsActive = (i) => setRowActionsActive({ [i]: !rowActionsActive[i] });

  const modifier = (tableData) => tableData.map((row, i) => {
    const modifiedRow = [];

    modifiedRow.push(<Button plain url={`/email_settings/email_template/${row.token}`}>{row.subject}</Button>);

    const actions = [];
    actions.push({
      content: t('shared.delete'),
      destructive: true,
      icon: DeleteMajor,
      onAction: () => destroySurvey(row)
    });

    modifiedRow.push(
      <Popover
        active={rowActionsActive[i]}
        activator={<Button plain onClick={() => toggleRowActionsActive(i)} icon={MobileVerticalDotsMajor} />}
        onClose={() => toggleRowActionsActive(i)}
      >
        <ActionList items={actions} />
      </Popover>
    );

    return modifiedRow;
  });

  const fetchData = useCallback(() => {
    const params = {
      per_page: 30,
      page: tableFilters.page,
      search: tableFilters.queryValue,
      status: tableFilters.tab === 'All' ? '' : tableFilters.tab,
      q: appliedFiltersQuery(appliedFilters())
    };

    axios.post('/v1/email_templates/search', params)
      .then((res) => {
        if (mounted) {
          setRecords(res.data.email_templates);
          setLastPage(res.data.lastPage);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [debouncedSearch]);

  const destroySurvey = (s) => {
    if (!window.confirm(`${t('shared.are_you_sure')}${s.name}`)) {
      return;
    }

    axios.delete(`/v1/email_templates/${s.token}`)
      .then(() => {
        fetchData();
      })
      .catch(() => {
      });
  };

  const filterControl = (
    <Filters
      queryValue={tableFilters.queryValue}
      filters={filters}
      onQueryChange={handleFilterChange({ history, location }, setTableFilters, 'queryValue')}
      onQueryClear={handleFilterChange({ history, location }, setTableFilters, 'queryValue', 'reset', '')}
      onClearAll={handleFilterChange({ history, location }, setTableFilters, '/surveys', 'resetAll')}
      appliedFilters={appliedFilters()}
      queryPlaceholder={t('user.search')}
    />
  );

  useEffect(() => {
    setMounted(true);
    fetchData();
    return () => {
      setMounted(false);
    };
  }, [fetchData]);

  useEffect(() => {
    if (init) {
      setTableFilters((f) => ({ ...f, page: 1 }));
      searchFilterToURL({ history, location }, 'page', null, 'delete');
    }
    setInit(true);
    // eslint-disable-next-line
  }, [tableFilters.queryValue, tableFilters.role, tableFilters.userType]);

  const indexSortMapping = {
    status: true,
    created_at: false
  };

  const headings = [
    t('shared.title'),
    ''
  ];

  const content = (
    <DataTable
      columnContentTypes={[
        'text',
        'text'
      ]}
      verticalAlign="middle"
      headings={headings}
      rows={modifier(records)}
      sortable={Object.values(indexSortMapping)}
      defaultSortDirection={defaultSortDirection(tableFilters.sorts)}
      initialSortColumnIndex={initialSortColumnIndex(indexSortMapping, tableFilters.sorts)}
      onSort={handleSort({ location, history }, setTableFilters, indexSortMapping)}
    />
  );

  return (
    <Page
      title="Email Templates"
      fullWidth
      separator
      primaryAction={{ content: 'New', onAction: () => history('/email_settings/email_template/new') }}
    >
      {!loading ? (
        <Card sectioned>
          <Card.Subsection>
            {filterControl}
          </Card.Subsection>
          <Card.Subsection>
            {content}
            {!records.length && (
              <EmptyState
                heading=""
                image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
              />
            )}
          </Card.Subsection>
        </Card>
      ) : <SkeletonPage />}
      <TablePagination
        pageFilter={tableFilters.page}
        setTableFilters={setTableFilters}
        records={records}
        lastPage={lastPage}
      />
    </Page>
  );
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.admin
});

export default connect(mapStateToProps)(EmailTemplates);
