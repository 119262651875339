/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Filters, Card, Toast, SkeletonPage, Button, IndexTable, Avatar, useIndexResourceState, EmptySearchResult } from '@shopify/polaris';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Banners from '../Shared/Banners';
import useDebounce from '../../hooks/useDebounce';
import { stateConverter } from '../FilterEngine/filterParams';
import { handleFilterChange } from '../FilterEngine/FilterEngine';
import TablePagination from '../FilterEngine/TablePagination/TablePagination';
import StatusBadge from '../Shared/StatusBadge';
import Badges from '../Shared/Badges';

const StaffAccounts = (props) => {
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(true);
  const [init, setInit] = useState(false);
  const [staffAccounts, setStaffAccounts] = useState([]);
  const [lastPage, setLastPage] = useState(null);
  const [mounted, setMounted] = useState(true);

  const [toastActive, setToastActive] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const { t } = useTranslation();
  const location = useLocation();
  const matchParams = useParams();
  const history = useNavigate();

  const [tableFilters, setTableFilters] = useState(stateConverter(location));
  const debouncedSearch = useDebounce(tableFilters, 400);

  const fetchAssignedStaffAccounts = (staffs) => {
    axios.get(`/v1/products/${matchParams.token}/assigned_staffs`)
      .then((response) => {
        assignAssignedStaff(staffs, response.data);
      })
      .catch(() => {})
      .then(() => {
        setLoading(false);
      });
  };

  const fetchStaffAccounts = useCallback(() => {
    const params = {
      per_page: 30,
      page: tableFilters.page,
      search: tableFilters.queryValue,
      q: '',
      s: { sorts: tableFilters.sorts }
    };

    axios.post('/v1/staffs/search', params)
      .then((res) => {
        setLastPage(res.data.lastPage);
        fetchAssignedStaffAccounts(res.data.staff_accounts.filter((staff) => staff.email !== props.owner));
      })
      .catch((err) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: err.response }]);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [debouncedSearch]);

  const filters = [];

  const placeHolderText = 'Filter by staff name, email or tag';

  const filterControl = (
    <Filters
      queryValue={tableFilters.queryValue}
      filters={filters}
      onQueryChange={handleFilterChange({ history, location }, setTableFilters, 'queryValue')}
      onQueryClear={handleFilterChange({ history, location }, setTableFilters, 'queryValue', 'reset', '')}
      onClearAll={() => {}}
      appliedFilters={[]}
      queryPlaceholder={placeHolderText}
    />
  );

  const handleProductAccessToggle = () => {
    const params = {
      ids: selectedResources
    };

    axios.post(`/v1/products/${matchParams.token}/assign_staffs`, params)
      .then(() => {
        toggleToastActive(t('shared.success'));
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
        fetchStaffAccounts();
      })
      .catch(() => {
        toggleToastActive(t('shared.something_went_wrong'));
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
      });
  };

  const assignAssignedStaff = (staffs, assigned) => {
    const temp = [...staffs];

    assigned.forEach((token) => {
      const index = temp.findIndex((staff) => staff.id === token);
      if (index >= 0) temp[index].assigned = true;
    });

    setStaffAccounts(temp);
  };

  useEffect(() => {
    setMounted(true);
    fetchStaffAccounts();
    return () => {
      setMounted(false);
    };
  }, [fetchStaffAccounts]);

  const dismissBanner = () => {
    setBanner([]);
  };

  const headings = [
    { title: '' },
    { title: t('user.name') },
    { title: t('user.email') },
    { title: t('staff.assigned') },
    { title: 'Tags' }
  ];

  const resourceName = {
    singular: t('staff.staff'),
    plural: t('staff.staff')
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange
  } = useIndexResourceState(staffAccounts);

  const rowMarkup = staffAccounts.map(({ id, name, avatar, email, assigned, tags }, index) => (email !== props.owner && (
    <IndexTable.Row
      id={id}
      key={id}
      selected={selectedResources?.includes(id)}
      position={index}
    >
      <IndexTable.Cell>
        <Avatar size="small" name="client_logo" source={avatar} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        {props.isStaff ? <div>{name}</div> : <Button plain url={`/staffs/${id}?product=${matchParams.token}`}>{name}</Button>}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {email}
      </IndexTable.Cell>
      <IndexTable.Cell>
        <StatusBadge active={assigned} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Badges data={tags} />
      </IndexTable.Cell>
    </IndexTable.Row>
  )));

  const promotedBulkActions = [
    {
      content: 'Toggle Product Access',
      onAction: () => handleProductAccessToggle()
    }
  ];

  const emptyStateMarkup = (
    <EmptySearchResult
      title="No staffs available"
      description="Add staff members or ask your administrator if you have enough access rights"
      withIllustration
    />
  );

  // eslint-disable-next-line no-unused-vars
  const content = (
    <IndexTable
      resourceName={resourceName}
      headings={headings}
      rows={staffAccounts}
      itemCount={staffAccounts.length}
      selectedItemsCount={
        allResourcesSelected ? 'All' : selectedResources.length
      }
      onSelectionChange={handleSelectionChange}
      emptyState={emptyStateMarkup}
      promotedBulkActions={promotedBulkActions}
    >
      {rowMarkup}
    </IndexTable>
  );

  useEffect(() => {
    if (init) {
      setTableFilters((f) => ({ ...f, page: 1 }));
    }
    setInit(true);
  // eslint-disable-next-line
  }, [tableFilters.queryValue]);

  return (
    <>
      <Banners banners={banner} onDismissBanner={dismissBanner} />
      {toastMarkup}
      {!loading ? (
        <Card sectioned>
          <Card.Subsection>
            {filterControl}
          </Card.Subsection>
          <Card.Subsection>
            {content}
          </Card.Subsection>
        </Card>
      ) : <SkeletonPage />}
      <TablePagination
        pageFilter={tableFilters.page}
        setTableFilters={setTableFilters}
        records={staffAccounts}
        lastPage={lastPage}
      />
    </>
  );
};

export default StaffAccounts;
