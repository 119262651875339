/* eslint-disable no-unused-vars */
import { Banner, Card, Form, Button } from '@shopify/polaris';
import axios from 'axios';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PublicBooking = () => {
  const [event, setEvent] = useState({});
  const [participation, setParticipation] = useState({});
  const [allowed, setAllowed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [done, setDone] = useState(false);

  const matchParams = useParams();
  const location = useLocation();
  const { t } = useTranslation();

  const fetchBooking = useCallback(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const authToken = query.get('auth_token');

    const params = {
      event_token: matchParams.token,
      participation_token: token,
      participation_auth_token: authToken
    };

    axios.post(`/v1/events/${matchParams.token}/participations/show_cancel`, params)
      .then((res) => {
        setEvent(res.data.event);
        setParticipation(res.data.participation);
        setAllowed(true);
      })
      .catch(() => {
        setAllowed(false);
      })
      .then(() => {
        setLoading(false);
      });
  }, [location.search, matchParams.token]);

  const cancelEvent = () => {
    if (!window.confirm(t('shared.are_you_sure'))) return;

    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const authToken = query.get('auth_token');

    const params = {
      event_token: matchParams.token,
      participation_token: token,
      participation_auth_token: authToken
    };

    axios.post(`/v1/events/${matchParams.token}/participations/cancel_participation`, params)
      .then(() => {
        setDone(true);
      })
      .catch(() => {
        setAllowed(false);
      })
      .then(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchBooking();
  }, [fetchBooking]);

  const page = (
    !loading && !allowed ? (
      <div>Not allowed</div>
    ) : (
      <div style={{ marginTop: '2rem', marginLeft: 'auto', marginRight: 'auto', maxWidth: '700px' }}>
        <Form>
          {participation.state === 'show' ? (
            <Banner title={`${t('calendar.signed_up')} - ${event.title}`} status="success">
              <p>{`${t('calendar.registered_on')} ${moment(participation.created_at).format('LLLL')}`}</p>
            </Banner>
          ) : (
            <Banner title={event.title} status="info">
              <p>{t('event.cancelled')}</p>
            </Banner>
          )}
          <Card.Section title={t('shared.start')}>
            {moment(event?.start).format('LLLL')}
          </Card.Section>
          <Card.Section title={t('shared.end')}>
            {moment(event?.end).format('LLLL')}
          </Card.Section>
          {event.pretty_price ? (
            <Card.Section title={t('shared.price')}>
              {event.pretty_price}
            </Card.Section>
          ) : ''}
          {event?.description ? (
            <Card.Section title={t('shared.description')}>
              {event.description}
            </Card.Section>
          ) : ''}
          {Number.isInteger(event?.spots_remaining) ? (
            <Card.Section title={t('events.spots_remaining')}>
              {event?.spots_remaining}
            </Card.Section>
          ) : ''}
          {event?.location ? (
            <Card.Section title={t('address.location')}>
              {event.location}
            </Card.Section>
          ) : ''}
          {participation.state === 'show' ? (
            <div className="flex">
              <div className="flex-1" />
              <Button destructive onClick={() => cancelEvent()}>Cancel</Button>
            </div>
          ) : null}
        </Form>
      </div>
    )
  );

  return (
    done ? (
      <div style={{ marginTop: '2rem', marginLeft: 'auto', marginRight: 'auto', maxWidth: '700px' }}>
        <Banner status="success">
          <p>{t('shared.success')}</p>
        </Banner>
      </div>
    ) : page
  );
};

export default PublicBooking;
