/* eslint-disable */
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Filters, Card, Toast, SkeletonPage, Button, IndexTable, useIndexResourceState, EmptySearchResult, Page } from '@shopify/polaris';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { PlusMinor } from '@shopify/polaris-icons';
import Banners from '../Shared/Banners';
import useDebounce from '../../hooks/useDebounce';
import { stateConverter } from '../FilterEngine/filterParams';
import { handleFilterChange } from '../FilterEngine/FilterEngine';
import TablePagination from '../FilterEngine/TablePagination/TablePagination';
import moment from 'moment';
import NewGiftCardModal from './NewGiftCardModal';

const GiftCards = (props) => {
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(true);
  const [giftCards, setGiftCards] = useState([]);
  const [lastPage, setLastPage] = useState(null);
  const [showGiftCardModal, setShowGiftCardModal] = useState(false);

  const [toastActive, setToastActive] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={20000} />
  ) : null;

  const { t } = useTranslation();
  const location = useLocation();
  const matchParams = useParams();
  const history = useNavigate();

  const [tableFilters, setTableFilters] = useState(stateConverter(location));
  const debouncedSearch = useDebounce(tableFilters, 400);

  const fetchGiftCards = useCallback(() => {
    const params = {
      per_page: 30,
      page: tableFilters.page,
      search: tableFilters.queryValue,
      q: '',
      s: { sorts: tableFilters.sorts }
    };

    axios.post('/v1/gift_cards/search', params)
      .then((res) => {
        setLastPage(res.data.lastPage);
        setGiftCards(res.data.gift_cards);
        setLoading(false);
      })
      .catch((err) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: err.response }]);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [debouncedSearch]);

  const filters = [];

  const placeHolderText = 'Search';

  const filterControl = (
    <Filters
      queryValue={tableFilters.queryValue}
      filters={filters}
      onQueryChange={handleFilterChange({ history, location }, setTableFilters, 'queryValue')}
      onQueryClear={handleFilterChange({ history, location }, setTableFilters, 'queryValue', 'reset', '')}
      onClearAll={() => {}}
      appliedFilters={[]}
      queryPlaceholder={placeHolderText}
    />
  );

  const handleProductAccessToggle = () => {
    const params = {
      codes: selectedResources
    };

    axios.post(`/v1/gift_cards/delete_all`, params)
      .then(() => {
        toggleToastActive(t('shared.success'));
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
        fetchGiftCards();
      })
      .catch(() => {
        toggleToastActive(t('shared.something_went_wrong'));
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
      });
  };

  useEffect(() => {
    fetchGiftCards();
  }, [fetchGiftCards]);

  const dismissBanner = () => {
    setBanner([]);
  };

  const handleNew = (params) => {
    axios.post(`/v1/gift_cards`, params)
      .then(() => {
        fetchGiftCards();
      })
      .catch((error) => {
        toggleToastActive(error.response?.data?.exception || t('shared.something_went_wrong'));
      });
  };

  let newGiftCardModal = null;

  if (showGiftCardModal) {
    newGiftCardModal = (
      <NewGiftCardModal
        title="New Gift Card"
        handleSubmit={handleNew}
        close={() => setShowGiftCardModal(false)}
      />
    );
  }

  const headings = [
    { title: 'Name' },
    { title: 'Client' },
    { title: 'Email' },
    { title: 'Note' },
    { title: 'Expires at' },
    { title: 'Code' },
    { title: 'Original Value' },
    { title: 'Current Value' }
  ];

  const resourceName = {
    singular: t('staff.staff'),
    plural: t('staff.staff')
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange
  } = useIndexResourceState(giftCards);

  const rowMarkup = giftCards.map(({ id, code, token, client, email, name, note, original_value, current_value, created_at, expires_at }, index) => (
    <IndexTable.Row
      id={code}
      key={code}
      selected={selectedResources?.includes(code)}
      position={index}
    >
      <IndexTable.Cell>
        <Button plain url={`/gift_cards/${token}/gift_card_transactions`}>{name}</Button>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Button plain url={`/clients/${client.token}`}>{client.name}</Button>
      </IndexTable.Cell>
      <IndexTable.Cell>
        {client.email}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {note || '--'}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {expires_at ? moment(expires_at).format('LL') : '--'}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {code}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {original_value}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {current_value}
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  const promotedBulkActions = [
    {
      content: 'Destroy',
      onAction: () => handleProductAccessToggle()
    }
  ];

  const emptyStateMarkup = (
    <EmptySearchResult
      title="No gift cards available"
      description="Create a gift card"
      withIllustration
    />
  );

  // eslint-disable-next-line no-unused-vars
  const content = (
    <IndexTable
      resourceName={resourceName}
      headings={headings}
      rows={giftCards}
      itemCount={giftCards.length}
      selectedItemsCount={
        allResourcesSelected ? 'All' : selectedResources.length
      }
      onSelectionChange={handleSelectionChange}
      emptyState={emptyStateMarkup}
      promotedBulkActions={promotedBulkActions}
    >
      {rowMarkup}
    </IndexTable>
  );

  return (
    <Page
      title="Gift Cards"
      fullWidth
      primaryAction={{ icon: PlusMinor, content: 'New Gift Card', onAction: () => setShowGiftCardModal(true)}}
      separator
    >
      <Banners banners={banner} onDismissBanner={dismissBanner} />
      {toastMarkup}
      {newGiftCardModal}
      {!loading ? (
        <Card sectioned>
          <Card.Subsection>
            {filterControl}
          </Card.Subsection>
          <Card.Subsection>
            {content}
          </Card.Subsection>
        </Card>
      ) : <SkeletonPage />}
      <TablePagination
        pageFilter={tableFilters.page}
        setTableFilters={setTableFilters}
        records={giftCards}
        lastPage={lastPage}
      />
    </Page>
  );
};

export default GiftCards;
