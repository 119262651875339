/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@shopify/polaris';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ArrowLeftMinor } from '@shopify/polaris-icons';
import { connect } from 'react-redux';
import PublicProduct from './PublicProduct';
import Unlock from './Unlock';
import ViewableProducts from './ViewableProducts';
import PublicMembership from './PublicMembership';
import PublicProfile from './PublicProfile';

const ProductCard = (props) => {
  const [selected, setSelected] = useState(props.selected);
  const [loginConfirm, setLoginConfirm] = useState(Boolean(props.token));
  const history = useNavigate();
  const matchParams = useParams();
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');
  const profile_token = searchParams.get('profile_token');

  // useEffect(() => {
  //   if (props.productOnly && token) {
  //     history(`/booking/${matchParams.name}?tab=booking&token=${token}&productOnly=true`);
  //     const p = props.products.find((product) => product.token === token);
  //     setSelected(p);
  //     props.setSelected(true);
  //   } else if (token) {
  //     history(`/booking/${matchParams.name}?tab=booking&token=${token}`);
  //     const p = props.products.find((product) => product.token === token);
  //     setSelected(p);
  //     props.setSelected(true);
  //   } else if (profile_token) {
  //     history(`/booking/${matchParams.name}?tab=booking&profile_token=${profile_token}`);
  //     const p = props.products.find((product) => product.token === profile_token);
  //     setSelected(p);
  //     props.setIndividual(p);
  //     props.setSelected(true);
  //   }
  // // eslint-disable-next-line
  // }, [props.products, selected, selected?.token, matchParams.name]);

  useEffect(() => {
    if (!token && !profile_token) {
      setSelected('');
      props.setSelected(false);
    }
  // eslint-disable-next-line
  }, [searchParams]);

  useEffect(() => {
    setTimeout(() => {
      if (props.token) {
        setLoginConfirm(true);
      }
    }, 2500);
  // eslint-disable-next-line
  }, [props.token]);

  // useEffect(() => {
  //   if (selected?.token && props.productOnly) {
  //     if (props.type === 'profile') {
  //       history(`/booking/${matchParams.name}?tab=booking&profile_token=${selected.token}&productOnly=true`);
  //     } else {
  //       history(`/booking/${matchParams.name}?tab=booking&token=${selected.token}&productOnly=true`);
  //     }
  //     props.setSelected(true);
  //   } else if (selected?.token) {
  //     if (props.type === 'profile') {
  //       history(`/booking/${matchParams.name}?tab=booking&profile_token=${selected.token}`);
  //     } else {
  //       history(`/booking/${matchParams.name}?tab=booking&token=${selected.token}`);
  //     }
  //     props.setSelected(true);
  //   }
  // // eslint-disable-next-line
  // }, [matchParams.name, selected, selected?.token]);

  const navigateBackAction = () => {
    props.setSelected(false);
    setSelected('');
  };

  const getProduct = useCallback((type) => {
    const timeslot = <PublicProduct publicProfile={props.publicProfile} token={selected?.token} setSelected={setSelected} hideSearch={props.setSelected} />;

    switch (type) {
      case 'TimeSlot': return timeslot;
      case 'Membership': return <PublicMembership token={selected?.token} setSelected={setSelected} hideSearch={props.setSelected} />;
      case 'profile': return <PublicProfile publicProfile={props.publicProfile} individual={props.individual} setIndividual={props.setIndividual} type={type} token={selected?.token} setSelected={setSelected} hideSearch={props.setSelected} />;
      default: return timeslot;
    }
  }, [props.setIndividual, props.publicProfile, props.setSelected, selected?.token, props.individual]);

  const productDisplay = (type) => {
    if (type === 'profile') {
      return (
        selected?.requires_login && !loginConfirm ? (
          <>
            <div style={{ paddingBottom: '9px' }}><Button icon={ArrowLeftMinor} onClick={() => navigateBackAction()} /></div>
            <Unlock />
          </>
        ) : <PublicProfile publicProfile={props.publicProfile} individual={props.individual} setIndividual={props.setIndividual} type={type} token={selected?.token} setSelected={setSelected} hideSearch={props.setSelected} />
      );
    }

    return (
      selected.requires_login && !loginConfirm ? (
        <>
          <div style={{ paddingBottom: '9px' }}><Button icon={ArrowLeftMinor} onClick={() => navigateBackAction()} /></div>
          <Unlock />
        </>
      ) : getProduct(selected.type)
    );
  };

  return (
    <div>
      {(profile_token || selected?.token) ? productDisplay(props.type) : <ViewableProducts products={props.products} setSelected={setSelected} type={props.type} lang={props.lang} hideSearch={props.setSelected} />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  lang: state.auth.lang
});

export default connect(mapStateToProps)(ProductCard);
