import React, { useCallback, useEffect, useState } from 'react';
import { Button, Filters, IndexTable, Modal, Stack, useIndexResourceState } from '@shopify/polaris';
import axios from 'axios';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { stateConverter } from '../FilterEngine/filterParams';
import useDebounce from '../../hooks/useDebounce';
import { handleFilterChange } from '../FilterEngine/FilterEngine';
import TablePagination from '../FilterEngine/TablePagination/TablePagination';
import StatusBadge from '../Shared/StatusBadge';

const AddProductModal = (props) => {
  const [showProducts, setShowProducts] = useState(true);
  const [lastPage, setLastPage] = useState(null);
  const [products, setProducts] = useState([]);
  const [loadingLength] = useState(20);

  const { t } = useTranslation();
  const location = useLocation();
  const matchParams = useParams();
  const history = useNavigate();

  const [tableFilters, setTableFilters] = useState(stateConverter(location));
  const debouncedSearch = useDebounce(tableFilters, 400);

  const closeModal = () => {
    setShowProducts(false);
    props.close();
  };

  const handleSubmit = () => {
    const params = {
      product_tokens: selectedResources
    };

    axios.post(`/v1/public_profiles/${props.publicProfile.url_name}/assign_products`, params)
      .then(() => {
        props.handleSubmit();
        props.close();
      })
      .catch(() => {
      });
  };

  const fetchProducts = useCallback(() => {
    const params = {
      per_page: loadingLength,
      page: tableFilters.page,
      search: tableFilters.queryValue,
      q: '',
      s: { sorts: tableFilters.sorts }
    };

    axios.post('/v1/products/search', params)
      .then((response) => {
        const temp = [...response.data.products];

        props.selectedProducts.forEach((token) => {
          const index = temp.findIndex((product) => product.token === token);
          if (index >= 0) temp[index].assigned = true;
        });

        setProducts(temp);
        setLastPage(response.data.lastPage);
      })
      .catch(() => {});
    // eslint-disable-next-line
  }, [debouncedSearch]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const appliedFilters = useCallback(() => {
    const af = [];

    return af;
  }, []);

  const resourceName = {
    singular: 'Product',
    plural: 'Products'
  };

  const filters = [];

  const placeHolderText = t('user.search');

  const filterControl = (
    <Filters
      queryValue={tableFilters.queryValue}
      filters={filters}
      onQueryChange={handleFilterChange({ history, location }, setTableFilters, 'queryValue')}
      onQueryClear={handleFilterChange({ history, location }, setTableFilters, 'queryValue', 'reset', '')}
      appliedFilters={appliedFilters()}
      queryPlaceholder={placeHolderText}
    />
  );

  const headings = [
    { title: 'Title' },
    { title: 'Duration' },
    { title: 'Assigned' }
  ];

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange
  } = useIndexResourceState(products);

  const rowMarkup = products.map(({ id, status, token, title, duration, assigned }, index) => (
    <IndexTable.Row
      id={id}
      key={id}
      status={status}
      selected={selectedResources?.includes(id)}
      position={index}
    >
      <IndexTable.Cell>
        <Button plain url={`/products/${token}?staff=${matchParams.token}`}>{title}</Button>
      </IndexTable.Cell>
      <IndexTable.Cell>
        {duration}
      </IndexTable.Cell>
      <IndexTable.Cell>
        <StatusBadge active={assigned} />
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  const promotedBulkActions = [
    {
      content: 'Toggle Access',
      onAction: () => handleSubmit()
    }
  ];

  return (
    <Modal
      open={showProducts}
      onClose={closeModal}
      title={props.title}
    >
      <Modal.Section>
        <Stack>
          <Stack.Item fill>
            {filterControl}
          </Stack.Item>
        </Stack>
        <IndexTable
          resourceName={resourceName}
          headings={headings}
          rows={products}
          itemCount={products.length}
          selectedItemsCount={
            allResourcesSelected ? 'All' : selectedResources.length
          }
          onSelectionChange={handleSelectionChange}
          promotedBulkActions={promotedBulkActions}
        >
          {rowMarkup}
        </IndexTable>
        <TablePagination
          resetOnRefresh
          pageFilter={tableFilters.page}
          setTableFilters={setTableFilters}
          records={products}
          lastPage={lastPage}
          length={loadingLength}
        />
      </Modal.Section>
    </Modal>
  );
};

export default AddProductModal;
