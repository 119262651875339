/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import { Banner, Card, Form } from '@shopify/polaris';
import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ExternalLink from '../Shared/ExternalLink';

const BookingPage = (props) => {
  // Add product and instructor
  const { event, created_at, pretty_price } = props.booking;

  const { t } = useTranslation();

  const formatDisplay = () => {
    switch (props.booking.product_type) {
      case 'Product::LessonPackage': {
        return (
          <Form>
            <Banner title="Purchase successful!" status="success">
              <p>{`${t('booking.confirmation_will_be_sent')} ${props.booking.registered_user_email}`}</p>
              <p>{moment(created_at).format('LLLL')}</p>
            </Banner>
            {pretty_price ? (
              <Card.Section title={t('shared.price')}>
                {pretty_price}
              </Card.Section>
            ) : ''}
            {event?.description ? (
              <Card.Section title={t('shared.description')}>
                {event.description}
              </Card.Section>
            ) : ''}
          </Form>
        );
      }
      default: return (
        <Form>
          <Banner title={`${t('calendar.signed_up')} - ${event.title}`} status="success">
            <p>{`${t('booking.confirmation_will_be_sent')} ${props.booking.registered_user_email}`}</p>
            <p>{`${t('calendar.registered_on')} ${moment(created_at).format('LLLL')}`}</p>
          </Banner>
          <Card.Section title={t('shared.start')}>
            {moment(event?.start_time).format('LLLL')}
          </Card.Section>
          <Card.Section title={t('shared.end')}>
            {moment(event?.end_time).format('LLLL')}
          </Card.Section>
          {pretty_price ? (
            <Card.Section title={t('shared.price')}>
              {pretty_price}
            </Card.Section>
          ) : ''}
          {event?.description ? (
            <Card.Section title={t('shared.description')}>
              {event.description}
            </Card.Section>
          ) : ''}
          {Number.isInteger(event?.spots_remaining) ? (
            <Card.Section title={t('events.spots_remaining')}>
              {event?.spots_remaining}
            </Card.Section>
          ) : ''}
          {event?.location ? (
            <Card.Section title={t('address.location')}>
              {event.location}
            </Card.Section>
          ) : ''}
          {event?.online ? (
            event.conference_url ? (
              <Card.Section title="Online">
                <ExternalLink url={event.conference_url} label={event.conference_url} />
              </Card.Section>
            ) : (
              <Card.Section title="Online">
                {t('booking.online_event')}
              </Card.Section>
            )
          ) : ''}
        </Form>
      );
    }
  };

  return (
    <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '1100px' }}>
      {formatDisplay()}
    </div>
  );
};

export default BookingPage;
