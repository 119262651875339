/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { Card, Tabs, Spinner, Stack, Filters } from '@shopify/polaris';
import Select from 'react-select';
import axios from 'axios';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MomentTimezone } from 'moment-timezone';
import moment from 'moment';
import deMoment from 'moment/locale/de';
import frMoment from 'moment/locale/fr';
import CookieConsent from 'react-cookie-consent';
import ProductCard from '../Products/ProductCard';
import { stateConverter } from '../FilterEngine/filterParams';
import { getTabIndex, getTabId, onTabSelect } from '../FilterEngine/Tabs/tabs';
import ViewFaq from './ViewFaq';
import TablePagination from '../FilterEngine/TablePagination/TablePagination';
import { searchFilterToURL, handleFilterChange, appliedFiltersQuery } from '../FilterEngine/FilterEngine';
import useDebounce from '../../hooks/useDebounce';
import ExternalLink from '../Shared/ExternalLink';
import PublicProfileFooter from './PublicProfileFooter';
import * as actions from '../../store/actions/index';
import i18n from '../../i18n';
import ReadOnlyDefaultStyle from './ReadOnlyDefaultStyle';
import Unlock from '../Products/Unlock';
import tagFilterDefinition from '../FilterEngine/Tags/tagFilterDefinition';
import isEmpty from '../Shared/isEmpty';
import tagFilterActive from '../FilterEngine/Tags/tagFilterActive';
import programSpaceFilterDefinition from '../FilterEngine/ProgramSpace/programSpaceFilterDefinition';
import programSpaceFilterActive from '../FilterEngine/ProgramSpace/programSpaceFilterActive';
import languageFilterActive from '../FilterEngine/Languages/languageFilterActive';
import languageFilterDefinition from '../FilterEngine/Languages/languageFilterDefinition';
import AccessDenied from './AccessDenied';
import PageNotFound from './PageNotFound';

const ReadOnly = (props) => {
  const [init, setInit] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const [lastPage, setLastPage] = useState(null);
  const [loadingLength] = useState(12);
  const [notFound, setNotFound] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);
  const [publicProfile, setPublicProfile] = useState({
    faqs: []
  });
  const [profiles, setProfiles] = useState([]);
  const [filterValues, setFilterValues] = useState({
    tags: [],
    program_spaces: [],
    languages: [],
    product_tags: []
  });
  const [loading, setLoading] = useState(true);
  const [inputTagFilter, setInputTagFilter] = useState('');
  const [inputLanguageFilter, setInputLanguageFilter] = useState('');
  const [nothingHere, setNothingHere] = useState(false);
  const [selected, setSelected] = useState(false);
  const [products, setProducts] = useState([]);
  const [pages, setPages] = useState([]);
  const [activeProfileSearch, setActivateProfileSearch] = useState(false);
  const [individual, setIndividual] = useState('');
  const [requireLogin, setRequireLogin] = useState(false);
  const [initLoading, setInitLoading] = useState(false);

  const { t } = useTranslation();
  const location = useLocation();
  const matchParams = useParams();
  const history = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const productOnly = queryParams.get('productOnly');
  const profileToken = queryParams.get('profile_token');

  const [tableFilters, setTableFilters] = useState(stateConverter(location));
  const debouncedSearch = useDebounce(tableFilters, 400);

  useEffect(() => {
    props.onTryAutoSignin();
  // eslint-disable-next-line
  }, [props.onTryAutoSignin]);

  const fetchFilterValues = useCallback((type = '') => {
    axios.get(`/v1/public_profiles/${encodeURIComponent(matchParams.name || props.name)}/filter_values`)
      .then((res) => {
        setFilterValues(res.data);
      })
      .catch(() => {});
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.token) {
      setInitLoading(true);
      fetchProfile();
    }
  // eslint-disable-next-line
  }, [props.token]);

  const language = (lang) => {
    switch (lang) {
      case 'en': {
        moment.locale('en');
        return;
      }
      case 'de': {
        // MomentTimezone.locale('de', deMoment);
        // moment.locale('de', deMoment);
        moment.locale('de', deMoment);
        return;
      }
      case 'fr': {
        // MomentTimezone.locale('fr', frMoment);
        moment.locale('fr', frMoment);
        return;
      }
      default: {
        moment.locale('en');
      }
    }
  };

  const fetchProfile = useCallback(() => {
    const programSpace = queryParams.get('program_space') ?? '';

    axios.get(`/v1/public_profiles/${encodeURIComponent(matchParams.name || props.name)}?program_space=${programSpace}`)
      .then((response) => {
        setRequireLogin(response.data.require_login);
        setNothingHere(response.data.nothing_here);
        setAccessDenied(response.data.access_denied);
        setPublicProfile(response.data);
        setLastPage(1);
        if (!response.data.pages) {
          setPages(filteredTabs());
        } else {
          setPages(response.data.pages);
        }
        setFaqs(response.data.faqs);
        i18n.changeLanguage(props.lang || response.data.lang);
        props.setLang(props.lang || response.data.lang);
        language(props.lang || response.data.lang);
        if (response.data.book_individuals && !init) {
          fetchProfiles(true);
        } else {
          fetchFilterValues('products');
          setInitLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setNotFound(true);
      })
      .then(() => {
        setLoading(false);
      });
  // eslint-disable-next-line
  }, []);

  const fetchProfiles = () => {
    if (!publicProfile.book_individuals && activeProfileSearch) return;

    setActivateProfileSearch(true);

    const params = {
      per_page: loadingLength,
      page: tableFilters.page,
      search: tableFilters.queryValue,
      // q: '',
      q: appliedFiltersQuery(appliedFilters()),
      // tags: tableFilters.tags?.map((tag) => tag.label),
      s: { sorts: tableFilters.sorts }
    };

    const programSpace = queryParams.get('program_space') ?? '';

    axios.post(`/v1/public_profiles/${encodeURIComponent(matchParams.name || props.name)}/search_profiles?program_space=${programSpace}`, params)
      .then((response) => {
        setProfiles(response.data.profiles);
        setLastPage(response.data.lastPage);
        fetchFilterValues('profiles');
      })
      .catch(() => {})
      .then(() => {
        setInitLoading(false);
      });
  };

  useEffect(() => {
    fetchProfiles();
    fetchProducts();
  // eslint-disable-next-line
  }, [debouncedSearch]);

  const fetchProducts = useCallback(() => {
    if (publicProfile.book_individuals) return;

    const params = {
      per_page: loadingLength,
      page: tableFilters.page,
      search: tableFilters.queryValue,
      q: appliedFiltersQuery(appliedFilters()),
      s: { sorts: tableFilters.sorts }
    };

    const programSpace = queryParams.get('program_space') ?? '';

    axios.post(`/v1/public_profiles/${encodeURIComponent(matchParams.name || props.name)}/search_products?program_space=${programSpace}`, params)
      .then((response) => {
        setProducts(response.data.products);
        setLastPage(response.data.lastPage);
      })
      .catch(() => {});
    // eslint-disable-next-line
  }, [debouncedSearch]);

  useEffect(() => {
    fetchProfile();
    fetchProducts();
  // eslint-disable-next-line
  }, []);

  const appliedFilters = useCallback(() => {
    const af = [];

    if (!isEmpty(tableFilters.tags)) {
      af.push(tagFilterActive(t, { history, location }, setTableFilters, tableFilters.tags, publicProfile.book_individuals ? 'booking_tags_label_in' : 'tags_label_in'));
    }

    if (!isEmpty(tableFilters.languages)) {
      af.push(languageFilterActive(t, { history, location }, setTableFilters, tableFilters.languages));
    }

    if (publicProfile.program_space === 'default' && !isEmpty(tableFilters.program_space)) {
      af.push(programSpaceFilterActive(t, { history, location, queryParams }, setTableFilters, tableFilters.program_space, filterValues.program_spaces));
    }

    return af;
  // eslint-disable-next-line
  }, [history, location, t, publicProfile.program_space, tableFilters.tags, filterValues.program_spaces]);

  const filters = [];

  const placeHolderText = t('user.search');

  const profileFilters = [
    tagFilterDefinition(t, { history, location }, setTableFilters, filterValues.tags.filter((opt) => opt.language === props.lang), tableFilters.tags, { value: inputTagFilter, setValue: setInputTagFilter })
  ];

  const productFilters = [
    tagFilterDefinition(t, { history, location }, setTableFilters, filterValues.product_tags, tableFilters.tags, { value: inputTagFilter, setValue: setInputTagFilter })
  ];

  const filterControl = (
    <Filters
      queryValue={tableFilters.queryValue}
      filters={productFilters}
      onQueryChange={handleFilterChange({ history, location }, setTableFilters, 'queryValue')}
      onQueryClear={handleFilterChange({ history, location }, setTableFilters, 'queryValue', 'reset', '')}
      appliedFilters={appliedFilters()}
      queryPlaceholder={placeHolderText}
    />
  );

  if (filterValues.languages.length) {
    profileFilters.push(languageFilterDefinition(t, { history, location }, setTableFilters, filterValues.languages, tableFilters.languages, { value: inputLanguageFilter, setValue: setInputLanguageFilter }));
  }

  if (filterValues.program_spaces.length > 2) {
    profileFilters.push(programSpaceFilterDefinition(t, { history, location, queryParams }, setTableFilters, filterValues.program_spaces, tableFilters.program_space));
  }

  const profileFilterControl = (
    <Filters
      queryValue={tableFilters.queryValue}
      filters={profileFilters}
      onQueryChange={handleFilterChange({ history, location }, setTableFilters, 'queryValue')}
      onQueryClear={handleFilterChange({ history, location }, setTableFilters, 'queryValue', 'reset', '')}
      appliedFilters={appliedFilters()}
      queryPlaceholder={placeHolderText}
    />
  );

  const getAdditionalPages = () => {
    const temp = pages.filter((page) => !['home', 'booking', 'faq'].includes(page.id));

    return (
      temp.map((page) => (
        getTabId(pages, tableFilters.tab) === page.id && (
          <ReadOnlyDefaultStyle publicProfile={publicProfile} tabName={page.id} key={page.id}>
            <Card.Section key={page.id}>
              <div dangerouslySetInnerHTML={{ __html: page.data }} />
            </Card.Section>
          </ReadOnlyDefaultStyle>
        )
      ))
    );
  };

  const deselect = () => {
    setSelected(false);
    setIndividual({});
  };

  const filteredTabs = () => {
    if (pages && pages.length) {
      let temp = [...pages];

      temp = temp.filter((page) => !page.hidden);

      const faqIndex = temp.findIndex((page) => page.id === 'faq');

      if (faqIndex !== -1 && !faqs.length) {
        temp.splice(faqIndex, 1);
      }

      temp.forEach((entry) => {
        if (entry.customMeta) {
          if (entry.imageURL && !entry.externalURL) {
            // eslint-disable-next-line
            entry.content = <img src={entry.imageURL} alt={entry.accessibilityLabel} />;
          } else if (entry.imageURL && entry.externalURL && entry.isEternalLink) {
            // eslint-disable-next-line
            entry.content = (
              <a href={entry.externalURL} target="_blank" rel="noopener noreferrer" className="flex justify-center">
                <img src={entry.imageURL} alt={entry.accessibilityLabel} className="h-14" />
              </a>
            );
          } else if (!entry.imageURL && entry.externalURL && entry.isEternalLink) {
            // eslint-disable-next-line
            entry.content = (
              <a href={entry.externalURL} target="_blank" rel="noopener noreferrer" className="flex justify-center">
                {entry.content}
              </a>
            );
          }
        }
        // eslint-disable-next-line
        entry.accessibilityLabel = entry.panelID;
      });

      return temp;
    }

    const data = [{
      id: 'home',
      content: t('navigation.home'),
      accessibilityLabel: 'Home',
      disabled: true,
      panelID: 'home'
    }];

    data.push({
      id: 'booking',
      content: t('public_profile.booking'),
      accessibilityLabel: 'booking',
      panelID: 'booking'
    });

    data.push({
      id: 'faq',
      content: t('public_profile.faq.name'),
      accessibilityLabel: 'faq',
      panelID: 'faq',
      disabled: true
    });

    return data;
  };

  useEffect(() => {
    if (init) {
      setTableFilters((f) => ({ ...f, page: 1 }));
      searchFilterToURL({ history, location, matchParams }, 'page', null, 'delete');
    }
    setInit(true);
  // eslint-disable-next-line
  }, [tableFilters.queryValue]);

  const debugInfo = () => <div>{JSON.stringify(tableFilters)}</div>;

  const loginDiv = (
    <div className="m-auto max-w-7xl px-4 pt-12 sm:px-6 lg:px-8">
      <div className="m-auto max-w-3xl">
        <Unlock logo={publicProfile.loginLogoUrl} loading={props.isLoading} />
      </div>
    </div>
  );

  const bookingPage = (productOnly === 'true' ? (
    <Card>
      {!selected && (
        <Card.Subsection>
          <Stack>
            <Stack.Item fill>
              {/* {filterControl} */}
            </Stack.Item>
          </Stack>
        </Card.Subsection>
      )}
      <Card.Subsection>
        <ProductCard publicProfile={publicProfile} products={products} individual={individual} setIndividual={setIndividual} setSelected={setSelected} productOnly={productOnly === 'true'} />
      </Card.Subsection>
      {!selected && (
        <TablePagination
          pageFilter={tableFilters.page}
          setTableFilters={setTableFilters}
          records={products}
          lastPage={lastPage}
          length={12}
        />
      )}
    </Card>
  ) : (
    !loading ? (
      accessDenied ? (!props.token ? loginDiv : <AccessDenied loading={props.loading || initLoading} />) : (
        <div className="max-w-screen-xl mx-auto lg:px-8">
          <div className="max-w-screen-xl mx-auto bg-white">
            <Tabs
              fitted={publicProfile?.full_width_tabs}
              tabs={filteredTabs()}
              selected={getTabIndex(filteredTabs(), tableFilters.tab)}
              onSelect={(tabIndex) => onTabSelect({ history, location, matchParams }, filteredTabs(), tabIndex, setTableFilters, deselect)}
            >
              {getTabId(pages, tableFilters.tab) === 'home' && !pages.find((page) => page.id === 'home').hidden && (
                <ReadOnlyDefaultStyle publicProfile={publicProfile} tabName="home">
                  {pages.find((page) => page.id === 'home').data ? <div dangerouslySetInnerHTML={{ __html: pages.find((page) => page.id === 'home').data }} /> : <div dangerouslySetInnerHTML={{ __html: publicProfile.data }} />}
                </ReadOnlyDefaultStyle>
              )}
              {((getTabId(pages, tableFilters.tab) === 'booking' && publicProfile.show_bookings) || (getTabId(pages, tableFilters.tab) === 'home' && pages.find((page) => page.id === 'home').hidden && publicProfile.show_bookings)) && (
                <>
                  {publicProfile.book_individuals && (
                    <ReadOnlyDefaultStyle individual={individual} publicProfile={publicProfile} tabName="booking">
                      <Card.Section>
                        <div dangerouslySetInnerHTML={{ __html: pages.find((page) => page.id === 'booking').data }} />
                        {!selected && (
                          <Card.Subsection>
                            <div className="flex flex-1">
                              <div className="flex-1">
                                {profileFilterControl}
                              </div>
                              {/* <div className="max-w-32 react-select">
                                <Select
                                  // cacheOptions
                                  inputValue={inputTagFilter}
                                  // isClearable
                                  onInputChange={setInputTagFilter}
                                  value={tableFilters.tags}
                                  onChange={handleFilterChange({ history, location }, setTableFilters, 'tags', 'tags')}
                                  // noOptionsMessage="Loading"
                                  defaultOptions={filterValues.tags}
                                  isMulti
                                  options={filterValues.tags}
                                  getOptionValue={(user) => user.value}
                                  getOptionLabel={(user) => user.label}
                                /> */}
                              {/* </div> */}
                            </div>
                          </Card.Subsection>
                        )}
                        <Card.Subsection>
                          <ProductCard publicProfile={publicProfile} individual={individual} setIndividual={setIndividual} selected={selected} products={profiles} setSelected={setSelected} type="profile" />
                        </Card.Subsection>
                        {!selected && (
                          <TablePagination
                            pageFilter={tableFilters.page}
                            setTableFilters={setTableFilters}
                            records={profiles}
                            lastPage={lastPage}
                            length={loadingLength}
                          />
                        )}
                      </Card.Section>
                    </ReadOnlyDefaultStyle>
                  )}
                  {!publicProfile.book_individuals && (
                    <ReadOnlyDefaultStyle publicProfile={publicProfile} tabName="booking">
                      <Card.Section>
                        <div dangerouslySetInnerHTML={{ __html: pages.find((page) => page.id === 'booking').data }} />
                        {!selected && (
                          <Card.Subsection>
                            <Stack>
                              <Stack.Item fill>
                                {filterControl}
                              </Stack.Item>
                            </Stack>
                          </Card.Subsection>
                        )}
                        <Card.Subsection>
                          <ProductCard publicProfile={publicProfile} products={products} setSelected={setSelected} />
                        </Card.Subsection>
                        {!selected && (
                          <TablePagination
                            pageFilter={tableFilters.page}
                            setTableFilters={setTableFilters}
                            records={products}
                            lastPage={lastPage}
                            length={loadingLength}
                          />
                        )}
                      </Card.Section>
                    </ReadOnlyDefaultStyle>
                  )}
                </>
              )}
              {getTabId(pages, tableFilters.tab) === 'faq' && faqs.length ? (
                <ReadOnlyDefaultStyle publicProfile={publicProfile} tabName="faq">
                  <Card.Section>
                    <div className="max-w-screen-md m-auto">
                      <div dangerouslySetInnerHTML={{ __html: pages.find((page) => page.id === 'faq').data }} />
                      <ViewFaq faqs={faqs} />
                    </div>
                  </Card.Section>
                </ReadOnlyDefaultStyle>
              ) : ''}
              {getAdditionalPages()}
            </Tabs>
            <CookieConsent
              location="bottom"
              declineButtonText={t('shared.cookie.decline')}
              buttonText={t('shared.cookie.accept')}
              cookieName="planubocookie"
              style={{ background: '#2B373B' }}
              buttonStyle={{ fontSize: '13px', backgroundColor: 'white' }}
              expires={150}
            >
              {t('shared.cookie.desc')}
              {' '}
              <ExternalLink url="https://planubo.com/privacy-policy/" label={t('user.privacy_policy')} hideIcon />
              .
            </CookieConsent>
            <PublicProfileFooter footer={publicProfile.footer} />
          </div>
        </div>
      )
    ) : (
      <Spinner />
    )
  ));

  return (
    <>
      {notFound ? <PageNotFound /> : (
        requireLogin && !props.token ? loginDiv : bookingPage
      )}
      {/* {debugInfo()} */}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setLang: (lang) => dispatch(actions.setLang(lang)),
  onTryAutoSignin: () => dispatch(actions.authCheckState())
});

const mapStateToProps = (state) => ({
  token: state.auth.token,
  loading: state.auth.loading,
  lang: state.auth.lang
});

export default connect(mapStateToProps, mapDispatchToProps)(ReadOnly);
