/* eslint-disable camelcase */
import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ExportMinor, InfoMinor, PlusMinor } from '@shopify/polaris-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { Filters, Card, Page, Toast, SkeletonPage, Button, IndexTable, Avatar, useIndexResourceState, Tooltip, Icon, Tabs } from '@shopify/polaris';
import moment from 'moment';
import Banners from '../Shared/Banners';
import NewStaffAccountModal from './NewStaffModal';
import useDebounce from '../../hooks/useDebounce';
import { stateConverter } from '../FilterEngine/filterParams';
import { appliedFiltersQuery, handleFilterChange } from '../FilterEngine/FilterEngine';
import TablePagination from '../FilterEngine/TablePagination/TablePagination';
import StatusBadge from '../Shared/StatusBadge';
import Badges from '../Shared/Badges';
import PnbStats from '../Tailwind/PnbStats';
import { getTabId, getTabIndex, onTabSelect } from '../FilterEngine/Tabs/tabs';
import stateFilterActive from '../FilterEngine/State/stateFilterActive';
import Download from '../Shared/Download';

const StaffAccounts = (props) => {
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState([]);
  const [staffaccounts, setStaffAccounts] = useState([]);
  const [lastPage, setLastPage] = useState(null);
  const [showNewStaffAccountModal, setShowNewStaffAccountModal] = useState(false);
  const [mounted, setMounted] = useState(true);
  const [isExporting, setIsExporting] = useState(false);

  const [toastActive, setToastActive] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const { t } = useTranslation();
  const location = useLocation();
  const history = useNavigate();

  const [tableFilters, setTableFilters] = useState(stateConverter(location, [{ key: 'state', value: ['completed'] }]));
  const debouncedSearch = useDebounce(tableFilters, 400);

  const appliedFilters = useCallback(() => {
    const af = [];

    if (tableFilters.state) af.push(stateFilterActive(t, { history, location }, setTableFilters, tableFilters.state));

    return af;
  // eslint-disable-next-line
  }, [t, tableFilters.state]);

  const tabs = [
    {
      id: 'access',
      content: 'General access',
      accessibilityLabel: 'General access',
      panelID: 'access'
    },
    {
      id: 'profiles',
      content: 'Profiles',
      accessibilityLabel: 'Profiles',
      panelID: 'profiles'
    }
  ];

  const fetchStaffAccounts = useCallback(() => {
    const params = {
      per_page: 30,
      page: tableFilters.page,
      search: tableFilters.queryValue,
      q: appliedFiltersQuery(appliedFilters()),
      s: { sorts: tableFilters.sorts }
    };

    axios.post('/v1/staffs/search', params)
      .then((res) => {
        if (mounted) {
          setStaffAccounts(res.data.staff_accounts);
          setLastPage(res.data.lastPage);
          setLoading(false);
          setStats(res.data.stats);
        }
      })
      .catch((err) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: err.response }]);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [debouncedSearch]);

  const exportEvents = () => {
    setIsExporting(true);

    const params = {
      per_page: 25,
      page: tableFilters.page,
      search: tableFilters.queryValue,
      q: appliedFiltersQuery(appliedFilters(), false),
      m: appliedFiltersQuery(appliedFilters(), true),
      s: { sorts: tableFilters.sorts }
    };

    axios.post('/v1/staffs/export', params, { responseType: 'blob' })
      .then((res) => {
        Download(res);
        setIsExporting(false);
      })
      .catch((err) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: err.response.data.errors }]);
        setIsExporting(false);
      });
  };

  const filters = [];

  const placeHolderText = t('staff_accounts.filter');

  const filterControl = (
    <Filters
      queryValue={tableFilters.queryValue}
      filters={filters}
      onQueryChange={handleFilterChange({ history, location }, setTableFilters, 'queryValue')}
      onQueryClear={handleFilterChange({ history, location }, setTableFilters, 'queryValue', 'reset', '')}
      onClearAll={() => {}}
      appliedFilters={[]}
      queryPlaceholder={placeHolderText}
    />
  );

  const handleNew = (client) => {
    axios.post('/v1/staffs', client)
      .then(() => {
        setLoading(false);
        setBanner([{ title: t('staff.added'), status: 'success' }]);
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
        fetchStaffAccounts();
      })
      .catch((err) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: err.response.data.errors }]);
      });
  };

  const handleStatusToggle = (type) => {
    const params = {
      ids: selectedResources
    };

    axios.post(`/v1/staffs/${type}`, params)
      .then(() => {
        toggleToastActive(t('shared.success'));
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
        fetchStaffAccounts();
      })
      .catch(() => {
        toggleToastActive(t('shared.something_went_wrong'));
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
      });
  };

  useEffect(() => {
    setMounted(true);
    fetchStaffAccounts();
    return () => {
      setMounted(false);
    };
  }, [fetchStaffAccounts]);

  const dismissBanner = () => {
    setBanner([]);
  };

  let newStaffAccountForm = null;

  if (showNewStaffAccountModal) {
    newStaffAccountForm = (
      <NewStaffAccountModal
        title={t('staff.add_new')}
        handleSubmit={handleNew}
        close={() => setShowNewStaffAccountModal(false)}
      />
    );
  }

  const profileHeadings = [
    { title: '' },
    { title: t('user.name') },
    { title: t('user.email') },
    { title: 'State' },
    { title: 'Google connected' },
    { title: 'TwoWaySync' },
    { title: 'Zoom connected' },
    { title: 'Video preference' },
    { title: 'Set availability' },
    { title: 'Last updated' }
  ];

  const headings = [
    { title: '' },
    { title: t('user.name') },
    { title: t('user.email') },
    { title: t('user.active') },
    { title: t('staff_accounts.invoice_access') },
    { title: t('staff_accounts.booking_portal_access') },
    { title: (
      <Tooltip
        content={(
          <div className="space-y-4">
            <p>{t('staff_accounts.media_library.desc_part_one')}</p>
            <p>{t('staff_accounts.media_library.desc_part_two')}</p>
            <p>{t('staff_accounts.media_library.desc_part_three')}</p>
            <p>{t('staff_accounts.media_library.desc_part_four')}</p>
          </div>
        )}
      >
        <div className="flex">
          <div>{t('staff_accounts.media_library.access')}</div>
          <Icon color="highlight" source={InfoMinor} />
        </div>
      </Tooltip>
    ) },
    { title: 'Tags' }
  ];

  const resourceName = {
    singular: t('staff.staff'),
    plural: t('staff.staff')
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange
  } = useIndexResourceState(staffaccounts);

  const rowMarkup = staffaccounts.map(({ id, name, avatar, email, status, access, tags }, index) => (
    <IndexTable.Row
      id={id}
      key={id}
      selected={selectedResources?.includes(id)}
      position={index}
    >
      <IndexTable.Cell>
        <Avatar size="small" name="client_logo" source={avatar} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Button plain url={`/staffs/${id}`}>{name}</Button>
      </IndexTable.Cell>
      <IndexTable.Cell>
        {email}
      </IndexTable.Cell>
      <IndexTable.Cell>
        <StatusBadge active={status} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <StatusBadge active={access.invoices} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <StatusBadge active={access.booking_page} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <StatusBadge active={access.media_library} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Badges data={tags} />
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  const rowProfileMarkup = staffaccounts.map(({ id, has_google_connected, has_zoom_connected, googleTwoWaySync, has_availabilities_set, video_preference, name, email, avatar, state, lastSignInAt }, index) => (
    <IndexTable.Row
      id={id}
      key={id}
      selected={selectedResources?.includes(id)}
      position={index}
    >
      <IndexTable.Cell>
        <Avatar size="small" name="client_logo" source={avatar} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Button plain url={`/staffs/${id}`}>{name}</Button>
      </IndexTable.Cell>
      <IndexTable.Cell>
        {email}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {state}
      </IndexTable.Cell>
      <IndexTable.Cell>
        <StatusBadge active={has_google_connected} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <StatusBadge active={googleTwoWaySync} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <StatusBadge active={has_zoom_connected} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        {video_preference || '--'}
      </IndexTable.Cell>
      <IndexTable.Cell>
        <StatusBadge active={has_availabilities_set} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        {lastSignInAt ? moment(lastSignInAt).format('LLLL') : '--'}
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  const promotedBulkActions = [
    {
      content: t('staff_accounts.toggles.status'),
      onAction: () => handleStatusToggle('toggle_status')
    },
    {
      content: t('staff_accounts.toggles.invoices'),
      onAction: () => handleStatusToggle('toggle_invoice_access')
    },
    {
      content: t('staff_accounts.toggles.booking_portal'),
      onAction: () => handleStatusToggle('toggle_booking_portal_access')
    },
    {
      content: t('staff_accounts.toggles.media_library'),
      onAction: () => handleStatusToggle('toggle_media_library_access')
    }
  ];

  const profileBulkActions = [
    {
      content: 'Approve',
      onAction: () => handleStatusToggle('approve')
    },
    {
      content: 'New registration',
      onAction: () => handleStatusToggle('new_registration')
    },
    {
      content: 'Deny',
      onAction: () => handleStatusToggle('deny')
    },
    {
      content: 'Pending',
      onAction: () => handleStatusToggle('move_to_pending')
    },
    {
      content: 'Complete',
      onAction: () => handleStatusToggle('complete')
    },
    {
      content: 'On hold',
      onAction: () => handleStatusToggle('on_hold')
    },
    {
      content: 'Cancel',
      onAction: () => handleStatusToggle('cancel')
    }
  ];

  const profileContent = (
    <IndexTable
      resourceName={resourceName}
      headings={profileHeadings}
      rows={staffaccounts}
      itemCount={staffaccounts.length}
      selectedItemsCount={
        allResourcesSelected ? 'All' : selectedResources.length
      }
      onSelectionChange={handleSelectionChange}
      promotedBulkActions={profileBulkActions}
    >
      {rowProfileMarkup}
    </IndexTable>
  );

  const content = (
    <IndexTable
      resourceName={resourceName}
      headings={headings}
      rows={staffaccounts}
      itemCount={staffaccounts.length}
      selectedItemsCount={
        allResourcesSelected ? 'All' : selectedResources.length
      }
      onSelectionChange={handleSelectionChange}
      promotedBulkActions={promotedBulkActions}
    >
      {rowMarkup}
    </IndexTable>
  );

  const pnbStatsFilterChange = (action) => {
    if (!action) {
      handleFilterChange({ history, location }, setTableFilters, 'state', 'reset', '')('', '');
    } else {
      handleFilterChange({ history, location }, setTableFilters, 'state')([action]);
    }
  };

  return (
    <Page
      title={t('staff-accounts')}
      primaryAction={{ icon: PlusMinor, content: t('staff.add_new'), onAction: () => setShowNewStaffAccountModal(true) }}
      secondaryActions={(props.isAdmin || props.isInstructor) && [{ icon: ExportMinor, content: 'Export', onAction: () => exportEvents(), disabled: isExporting, loading: isExporting }]}
      separator
      fullWidth
    >
      <Banners banners={banner} onDismissBanner={dismissBanner} />
      <PnbStats stats={stats} onClick={pnbStatsFilterChange} activeFilter={tableFilters.state?.[0]} />
      {newStaffAccountForm}
      {toastMarkup}
      <Tabs
        tabs={tabs}
        selected={getTabIndex(tabs, tableFilters.tab)}
        onSelect={(tabIndex) => onTabSelect({ history, location }, tabs, tabIndex, setTableFilters)}
      >
        <br />
        {/* Details */}
        {getTabId(tabs, tableFilters.tab) === 'profiles' && (
          <>
            {!loading ? (
              <Card sectioned>
                <Card.Subsection>
                  {filterControl}
                </Card.Subsection>
                <Card.Subsection>
                  {profileContent}
                </Card.Subsection>
              </Card>
            ) : <SkeletonPage />}
            <TablePagination
              pageFilter={tableFilters.page}
              setTableFilters={setTableFilters}
              records={staffaccounts}
              lastPage={lastPage}
            />
          </>
        )}
        {/* Events */}
        {getTabId(tabs, tableFilters.tab) === 'access' && (
          <>
            {!loading ? (
              <Card sectioned>
                <Card.Subsection>
                  {filterControl}
                </Card.Subsection>
                <Card.Subsection>
                  {content}
                </Card.Subsection>
              </Card>
            ) : <SkeletonPage />}
            <TablePagination
              pageFilter={tableFilters.page}
              setTableFilters={setTableFilters}
              records={staffaccounts}
              lastPage={lastPage}
            />
          </>
        )}
      </Tabs>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.admin,
  isInstructor: state.auth.instructor
});

export default connect(mapStateToProps)(StaffAccounts);
