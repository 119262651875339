/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { Card, FormLayout, Page, Layout, TextField, Label, Toast, ContextualSaveBar, Select, Banner, Tabs, DropZone, Stack, Icon, TextStyle, Checkbox } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import { DeleteMajor, HideMinor, ViewMinor } from '@shopify/polaris-icons';
import { getTabId, getTabIndex, onTabSelect } from '../FilterEngine/Tabs/tabs';
import { stateConverter } from '../FilterEngine/filterParams';
import ProductBookings from '../ProductBookings/ProductBookings';
import EmailTemplate from '../Settings/EmailTemplates/EmailTemplate';
import CustomFields from './CustomFields';
import StaffAccounts from './StaffAccounts';
import AvailabilityTab from './AvailabilityTab';
import Tags from '../Tags/Tags';
import TagModifier from '../Tags/TagModifier';
import usePrevious from '../../hooks/usePrevious';
import Banners from '../Shared/Banners';

const Membership = (props) => {
  const [init, setInit] = useState(false);
  const [initTags, setInitTags] = useState(false);
  const [banner, setBanner] = useState([]);

  const [membership, setMembership] = useState({});
  const [showSaveBar, setShowSaveBar] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [currency, setCurrency] = useState('');
  const [loading, setLoading] = useState(false);
  const [owner, setOwner] = useState({});

  const { t } = useTranslation();
  const history = useNavigate();
  const matchParams = useParams();
  const location = useLocation();

  const [selectedTags, setSelectedTags] = useState([]);
  const [initialTags, setInitialTags] = useState([]);

  const [productImage, setProductImage] = useState(null);
  const [customFields, setCustomFields] = useState([]);
  const [customFieldError, setCustomFieldError] = useState(false);

  const [toastMessage, setToastMessage] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const handleDragnDropChange = (items) => {
    setCustomFields(items);
    setShowSaveBar(true);
  };

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const [tableFilters, setTableFilters] = useState(stateConverter(location));

  const fetchCurrency = useCallback(() => {
    axios.get('/v1/settings/invoice_settings')
      .then((response) => {
        setCurrency(response.data.invoiceSettings.currency);
      })
      .catch(() => {});
  }, []);

  const previousTags = usePrevious(selectedTags);

  const updateTags = useCallback(() => {
    setInitTags(false);

    const params = {
      product: {
        tags_attributes: TagModifier(selectedTags, initialTags)
      }
    };

    axios.patch(`/v1/products/${matchParams.token}`, params)
      .then((response) => {
        setSelectedTags(response.data.selectedTags);
        setInitialTags(response.data.selectedTags);
        setInitTags(true);
      })
      .catch(() => {
      });
  }, [matchParams.token, selectedTags, initialTags]);

  useEffect(() => {
    if (initTags && JSON.stringify(previousTags) !== JSON.stringify(selectedTags)) {
      updateTags();
    }
  // eslint-disable-next-line
  }, [selectedTags]);

  const fetchProductData = useCallback(() => {
    if (matchParams.token) {
      setLoading(true);
      axios.get(`/v1/products/${matchParams.token}`)
        .then((res) => {
          if (res.data.product_image) setProductImage(new File([''], res.data.product_image));
          if (res.data.schedule) res.data.schedule = JSON.parse(res.data.schedule);
          setMembership(res.data);
          setOwner(res.data.owner);
          setSelectedTags(res.data.selectedTags);
          setInitialTags(res.data.selectedTags);
          setInitTags(true);
          res.data.custom_fields.forEach((r, i) => {
            if (!r.order_number) {
              // eslint-disable-next-line no-param-reassign
              r.order_number = i;
            }
          });

          setCustomFields(res.data.custom_fields);
        })
        .catch(() => {})
        .then(() => {
          setLoading(false);
        });
    }
  // eslint-disable-next-line
  }, [matchParams.token]);

  const dismissBanner = () => {
    setBanner([]);
  };

  useEffect(() => {
    fetchProductData();
    fetchCurrency();
  }, [fetchCurrency, fetchProductData]);

  const handleChange = (field) => (value) => {
    const temp = { ...membership, [field]: value };

    setMembership(temp);

    if (Object.values(temp).some((v) => v)) {
      setShowSaveBar(true);
    } else {
      setShowSaveBar(false);
    }
  };

  const handleTinymceChange = (field) => (value) => {
    if (init) setShowSaveBar(true);
    setMembership({ ...membership, [field]: value });
    setInit(true);
  };

  const formValid = () => {
    const errors = {};

    if (!membership.title) {
      errors.name = t('shared.validations.required');
    }
    if (!membership.tax_behavior) {
      errors.tax_behavior = t('shared.validations.required');
    }
    if (!membership.price) {
      errors.price = t('shared.validations.required');
    }
    if (!membership.interval) {
      errors.interval = t('shared.validations.required');
    }
    if (!membership.interval_count) {
      errors.interval_count = t('shared.validations.required');
    }
    if (!membership.sessions) {
      errors.sessions = t('shared.validations.required');
    }

    setFormErrors(errors);
    return Object.entries(errors).length === 0;
  };

  const handleSubmit = () => {
    if (!formValid()) return;

    setLoading(true);

    if (customFields.some((customField) => !customField.label && customField._destroy !== '1')) {
      setCustomFieldError(true);
      setLoading(false);
      return;
    }

    const formData = new FormData();

    formData.append('product[title]', membership.title || '');
    formData.append('product[description]', membership.description || '');
    formData.append('product[big_description]', membership.big_description || '');
    formData.append('product[tax_behavior]', membership.tax_behavior || '');
    formData.append('product[price]', membership.price || '');
    formData.append('product[sessions]', membership.sessions || '');
    formData.append('product[interval]', membership.interval || '');
    formData.append('product[interval_count]', membership.interval_count || '');
    formData.append('product[cancel_at]', membership.cancel_at || null);
    formData.append('product[start_date]', membership.start_date || null);
    formData.append('product[requires_payment]', membership.requires_payment || false);
    formData.append('product[requires_login]', membership.requires_login || false);
    formData.append('product[renewable_sessions]', membership.renewable_sessions || false);
    formData.append('product[type]', 'membership');
    formData.append('product[requires_to_be_client]', membership.requires_to_be_client || false);
    formData.append('product[custom_schedule]', membership.custom_schedule || false);
    // if (props.hasInvoiceAccess) formData.append('product[price]', membership.price || 0);
    formData.append('product[product_settings_attributes][id]', membership.product_settings?.id || '');
    formData.append('product[product_settings_attributes][start_date]', membership.product_settings?.start_date || '');
    formData.append('product[product_settings_attributes][end_date]', membership.product_settings?.end_date || '');
    formData.append('product[product_settings_attributes][buffer]', membership.product_settings?.buffer || '');
    formData.append('product[product_settings_attributes][cutoff_days]', membership.product_settings?.cutoff_days || '');
    formData.append('product[product_settings_attributes][cutoff_hours', membership.product_settings?.cutoff_hours || '');
    formData.append('product[product_settings_attributes][cutoff_minutes]', membership.product_settings?.cutoff_minutes || '');
    formData.append('product[product_settings_attributes][book_days_in_the_future]', membership.product_settings?.book_days_in_the_future || '');

    if (Array.isArray(membership.schedule)) {
      formData.append('product[schedule]', JSON.stringify(membership.schedule) || '');
    }

    if (productImage?.size > 0) {
      formData.append('product[product_image]', productImage);
    }

    if (!productImage) {
      formData.append('remove_product_image', true);
    }

    if (customFields.length) {
      customFields.forEach((customField, i) => {
        if (customField.id) formData.append(`product[custom_fields_attributes][${i}][id]`, customField.id);
        formData.append(`product[custom_fields_attributes][${i}][label]`, customField.label);
        formData.append(`product[custom_fields_attributes][${i}][value]`, customField.value);
        formData.append(`product[custom_fields_attributes][${i}][order_number]`, customField.order_number);

        if (customField._destroy) {
          formData.append(`product[custom_fields_attributes][${i}][_destroy]`, customField._destroy);
        }
      });
    }

    if (!matchParams.token) {
      axios.post('/v1/products', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          toggleToastActive(t('shared.saved'));
          setTimeout(() => {
            history(`/memberships/${response.data.token}`);
          });
        })
        .catch(() => {
          toggleToastActive(t('shared.unable_to_save'));
        })
        .then(() => {
          setShowSaveBar(false);
          setLoading(false);
        });
    } else {
      axios.patch(`/v1/products/${membership.token}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          setMembership(response.data);
          setCustomFields(response.data.custom_fields);
          toggleToastActive(t('shared.saved'));
        })
        .catch(() => {
          toggleToastActive(t('shared.unable_to_save'));
        })
        .then(() => {
          setShowSaveBar(false);
          setLoading(false);
        });
    }
  };

  const handleCustomFieldChange = (idx) => (field) => (value) => {
    const temp = [...customFields];
    temp[idx][field] = value;

    setCustomFields(temp);
    setShowSaveBar(true);
  };

  const deleteCustomField = (id) => {
    const temp = [...customFields];
    // eslint-disable-next-line
    temp[id]._destroy = '1';
    setCustomFields([...temp]);
    setShowSaveBar(true);
  };

  const addCustomField = () => {
    setCustomFields([...customFields, {
      order_number: customFields.length
    }]);
  };

  const getIntervalWeekMonthText = (interval) => {
    switch (interval) {
      case 'day': return t('shared.daily');
      case 'week': return t('shared.weekly');
      case 'month': return t('shared.monthly');
      case 'year': return t('shared.yearly');
      default: return '';
    }
  };

  const getIntervalPluralMonthText = (interval) => {
    switch (interval) {
      case 'day': return t('shared.days');
      case 'week': return t('shared.weeks');
      case 'month': return t('shared.months');
      case 'year': return t('shared.years');
      default: return '';
    }
  };

  // eslint-disable-next-line arrow-body-style
  const getIntervalText = () => {
    if (!membership.interval_count || !membership.interval) {
      return t('membership.select_an_interval');
    }

    if (membership.interval_count === '1') {
      return `${t('membership.will _renew_single')} ${getIntervalWeekMonthText(membership.interval)}`;
    }

    return `${t('membership.will_renew')} ${membership.interval_count} ${getIntervalPluralMonthText(membership.interval)}`;
  };

  const tabs = [
    {
      id: 'Product',
      content: t('products.plural'),
      accessibilityLabel: t('products.plural'),
      panelID: 'Product'
    },
    {
      id: 'Staff',
      content: t('staff.staff'),
      accessibilityLabel: 'Staff',
      panelID: 'Staff'
    },
    {
      id: 'BookingFields',
      content: t('product.custom_booking_fields'),
      accessibilityLabel: t('product.custom_booking_fields'),
      panelID: 'BookingFields'
    },
    {
      id: 'EmailTemplate',
      content: t('email_template.title'),
      accessibilityLabel: t('email_template.title'),
      panelID: 'EmailTemplate'
    },
    {
      id: 'Bookings',
      content: t('client.bookings'),
      accessibilityLabel: t('client.bookings'),
      panelID: 'Bookings'
    }
  ];

  if (props.isStaff) {
    tabs.splice(tabs.findIndex((i) => i.id === 'EmailTemplate'), 1);
  }

  const resetProductImage = () => {
    setShowSaveBar(true);
    setProductImage(null);
  };

  const handleProductImageDropZoneDrop = useCallback((_dropFiles, acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      setBanner([{ title: t('shared.upload_avatar_validation'), status: 'critical' }]);
      setTimeout(() => {
        setBanner([]);
      }, 3000);
    } else {
      setMembership({ ...membership, product_image: window.URL.createObjectURL(acceptedFiles[0]) });
      setProductImage(acceptedFiles[0]);
      setShowSaveBar(true);
    }
  }, [t, membership]);

  const uploadedProductImageFile = productImage && (
    <Stack alignment="center">
      <img src={membership.product_image} alt="product" width="50%" />
    </Stack>
  );

  const handleActiveToggle = (id, action) => {
    axios.post(`/v1/products/${id}/${action}`)
      .then(() => {
        setMembership({ ...membership, status: membership.active ? 'inactive' : 'active', active: !membership.active });
        toggleToastActive(t('shared.saved'));
      })
      .catch(() => {
        toggleToastActive(t('shared.unable_to_save'));
      });
  };

  const handleDelete = (token) => {
    if (!window.confirm(`${t('shared.are_you_sure')} ${t('products.plural')}`)) return;

    axios.delete(`/v1/products/${token}`)
      .then(() => {
        history('/products');
        toggleToastActive(t('shared.success'));
      })
      .catch(() => {
        toggleToastActive(t('shared.something_went_wrong'));
      });
  };

  const pageActions = () => {
    if (!membership || loading || matchParams.token === 'new_event') {
      return {};
    }

    const toggleContent = membership.active ? 'Inactivate' : 'Activate';
    const content = membership.active ? t('client.inactivate') : t('client.activate');

    const actions = [{
      icon: membership.active ? HideMinor : ViewMinor,
      content,
      onAction: () => handleActiveToggle(membership.token, toggleContent.toLowerCase())
    }];

    actions.push({
      icon: DeleteMajor,
      content: 'Delete',
      onAction: () => handleDelete(membership.token)
    });

    return actions;
  };

  return (
    <Page
      fullWidth
      breadcrumbs={[{ content: t('shared.back'), onAction: () => history('/memberships') }]}
      secondaryActions={pageActions()}
      title={membership.title || t('membership.new')}
    >
      {toastMarkup}
      {showSaveBar && (
        <ContextualSaveBar
          message={t('membership.create_new')}
          saveAction={{
            onAction: handleSubmit,
            loading,
            disabled: false
          }}
          discardAction={{
            onAction: () => window.location.reload()
          }}
        />
      )}
      <Banners banners={banner} onDismissBanner={dismissBanner} />
      <Tabs
        tabs={tabs}
        selected={getTabIndex(tabs, tableFilters.tab)}
        onSelect={(tabIndex) => onTabSelect({ history, location }, tabs, tabIndex, setTableFilters)}
      >
        <br />
        {getTabId(tabs, tableFilters.tab) === 'Product' && (
          <Layout>
            <Layout.Section oneHalf>
              <Card sectioned>
                <Card.Section title={t('product.image')} actions={[{ content: t('shared.change'), onAction: () => resetProductImage() }]}>
                  {!productImage ? (
                    <DropZone type="image" accept="image/*" allowMultiple={false} onDrop={handleProductImageDropZoneDrop}>
                      <DropZone.FileUpload />
                    </DropZone>
                  ) : uploadedProductImageFile}
                </Card.Section>
                <Card.Section>
                  <TextField
                    label={t('shared.title')}
                    requiredIndicator
                    value={membership.title}
                    onChange={handleChange('title')}
                  />
                </Card.Section>
                <Card.Section title={t('shared.description')}>
                  <TextField
                    label={t('payments.bank_statement')}
                    value={membership.description}
                    onChange={handleTinymceChange('description')}
                  />
                </Card.Section>
              </Card>
            </Layout.Section>
            <Layout.Section oneHalf>
              {matchParams.token && (
                <Card
                  sectioned
                  title="Tags"
                >
                  <Tags
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    tagUrl={`/v1/products/${matchParams.token}/tags`}
                  />
                  <div className="mt-2">
                    <TextStyle variation="subdued">{t('membership.tags')}</TextStyle>
                  </div>
                </Card>
              )}
              <Card
                sectioned
              >
                <Card.Section>
                  <FormLayout>
                    <Select
                      requiredIndicator
                      options={[{ value: '', label: t('shared.select_one') }, { value: 'inclusive', label: t('membership.inclusive') }, { value: 'exclusive', label: t('membership.exclusive') }]}
                      value={membership.tax_behavior}
                      label={t('membership.tax_behavior')}
                      error={formErrors.tax_behavior}
                      onChange={handleChange('tax_behavior')}
                    />
                    <TextField
                      requiredIndicator
                      label={t('shared.price')}
                      type="currency"
                      value={membership.price}
                      prefix={currency}
                      error={formErrors.price}
                      onChange={handleChange('price')}
                    />
                    <div className="flex items-center">
                      <TextField
                        requiredIndicator
                        label={t('membership.sessions')}
                        type="number"
                        error={formErrors.sessions}
                        value={membership.sessions}
                        min={1}
                        onChange={handleChange('sessions')}
                        helpText={t('membership.set_amount_of_sessions')}
                      />
                      <div className="ml-4">
                        <Checkbox
                          label={t('product.renew_sessions')}
                          checked={membership.renewable_sessions}
                          onChange={handleChange('renewable_sessions')}
                        />
                      </div>
                    </div>
                    <FormLayout.Group condensed helpText={getIntervalText()}>
                      <TextField
                        requiredIndicator
                        label={t('membership.interval_count')}
                        type="number"
                        error={formErrors.interval_count}
                        value={membership.interval_count}
                        min={1}
                        onChange={handleChange('interval_count')}
                      />
                      <Select
                        requiredIndicator
                        options={[{ value: '', label: t('shared.select_one') }, { value: 'day', label: t('shared.daily') }, { value: 'week', label: t('shared.weekly') }, { value: 'month', label: t('shared.monthly') }, { value: 'year', label: t('shared.yearly') }]}
                        value={membership.interval}
                        label={t('product.billing_cycle')}
                        error={formErrors.interval}
                        onChange={handleChange('interval')}
                      />
                    </FormLayout.Group>
                    <FormLayout.Group condensed>
                      <TextField
                        type="date"
                        label={t('shared.start_date')}
                        value={membership.start_date}
                        onChange={handleChange('start_date')}
                        helpText={t('product.start_date_description')}
                      />
                      <TextField
                        type="date"
                        label={t('shared.cancel_at')}
                        value={membership.cancel_at}
                        onChange={handleChange('cancel_at')}
                        helpText={t('product.cancel_at_desc')}
                      />
                    </FormLayout.Group>
                  </FormLayout>
                </Card.Section>
              </Card>
            </Layout.Section>
          </Layout>
        )}
        {getTabId(tabs, tableFilters.tab) === 'Availability' && !membership.one_time_event && (
          matchParams.token !== 'new_event' ? <AvailabilityTab /> : (
            <Banner status="info" >
              <p>{t('product.needs_to_be_created_before_availability')}</p>
            </Banner>
          )
        )}
        {getTabId(tabs, tableFilters.tab) === 'Staff' && (
          matchParams.token !== 'new_event' ? <StaffAccounts owner={owner.email} isStaff={props.isStaff} /> : (
            <Banner status="info" >
              <p>{t('product.created_before_staff_can_be_added')}</p>
            </Banner>
          )
        )}
        {getTabId(tabs, tableFilters.tab) === 'BookingFields' && (
          <CustomFields
            customFields={customFields}
            handleCustomFieldChange={handleCustomFieldChange}
            deleteCustomField={deleteCustomField}
            addCustomField={addCustomField}
            error={customFieldError}
            setError={setCustomFieldError}
            setCustomFields={handleDragnDropChange}
            title={t('custom_fields.plural')}
          />
        )}
        {getTabId(tabs, tableFilters.tab) === 'EmailTemplate' && (
          <EmailTemplate path="booking_mailer/client_confirmation" hideNav subtype={membership.token} isIndividualProduct />
        )}
        {getTabId(tabs, tableFilters.tab) === 'Bookings' && (
          membership.id && <ProductBookings product_id={membership.id} />
        )}
      </Tabs>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  canDoOnlineEvent: state.auth.canDoOnlineEvent,
  hasInvoiceAccess: state.auth.hasInvoiceAccess,
  isStaff: state.auth.role === 'staff',
  email: state.auth.email
});

export default connect(mapStateToProps)(Membership);
