import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
// import Instructors from '../components/Instructors/Instructors';
import Home from '../components/Clients/Home';
import Profile from '../components/Settings/Profile';
import Invoices from '../components/Invoices/Invoices';
import Payments from '../components/Clients/Payments';
import Pay from '../components/Payments/Pay';
import PlanSettings from '../components/Settings/PlanSettings';
import Help from '../components/Help/Help';
import ReadOnly from '../components/PublicProfile/ReadOnly';
import PublicProduct from '../components/Products/PublicProduct';
import Calendar from '../components/Calendar/Client/Calendar';
import Documentation from './Documentation';
import ZoomDocumentation from './ZoomDocumentation';
import MediaLibrary from '../components/MediaLibrary/MediaLibrary';
import PublicBooking from '../components/Products/PublicBooking';
import FillOutForm from '../components/Surveys/FillOutForm';
import FilledOutForms from '../components/Surveys/FilledOutForms';
import ForwardBookingPortal from '../components/Shared/ForwardBookingPortal';

const ClientRoutes = () => (
  <Routes>
    <Route path="/home" element={<Home />} />
    {/* <Route exact path="/instructors" element={<Instructors />} /> */}
    <Route exact path="/calendar" element={<Calendar />} />
    <Route exact path="/payments" element={<Payments />} />
    <Route exact path="/help" element={<Help />} />
    <Route exact path="/invoices" element={<Invoices />} />
    <Route exact path="/invoices/:id/pay" element={<Pay />} />
    <Route exact path="/embeddedbooking/:token" element={<PublicProduct />} />
    <Route exact path="/profile_settings" element={<Profile />} />
    <Route exact path="/survey_reply/:token" element={<FillOutForm />} />
    <Route exact path="/answered_surveys" element={<FilledOutForms />} />
    <Route exact path="/booking/:name" element={<ReadOnly />} />
    <Route exact path="/plan" element={<PlanSettings />} />
    <Route exact path="/documentation" element={<Documentation />} />
    <Route exact path="/manage_booking/:token" element={<PublicBooking />} />
    <Route exact path="/media_library" element={<MediaLibrary />} />
    <Route exact path="/zoom_documentation" element={<ZoomDocumentation />} />
    <Route exact path="/my_portal" element={<ForwardBookingPortal />} />
    <Route path="*" element={<Navigate to="/home" />} />
  </Routes>
);

export default ClientRoutes;
