/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { PlusSmallIcon as PlusSmallIconOutline, MinusSmallIcon as MinusSmallIconOutline } from '@heroicons/react/24/outline';
// import { PlusSmallIcon as PlusSmallIconSolid } from '@heroicons/react/20/solid';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Page, Layout, Card, FormLayout, Form, Toast, Stack, Badge, Button, ProgressBar, Icon, Tooltip } from '@shopify/polaris';
import { AlertMinor } from '@shopify/polaris-icons';
import StatusBadge from '../Shared/StatusBadge';
import * as actions from '../../store/actions/index';
import loadPaddle from '../../services/loadPaddle';

const PlanSettings = (props) => {
  const [init, setInit] = useState(false);
  const [appSumoExpiresOn, setAppSumoExpiresOn] = useState(null);
  const [expiresIn, setExpiresIn] = useState('');
  const [accountState, setAccountState] = useState('');
  const [staffAccountUsage, setStaffAccountUsage] = useState({});
  const [clientUsage, setClientUsage] = useState({});
  const [storageUsage, setStorageUsage] = useState({});
  const [allowedServices, setAllowedServices] = useState({});
  const [currentPlanOptions, setCurrentPlanOptions] = useState({});
  const [hideStaffAccountUsage, setHideStaffAccountUsage] = useState(false);
  const [development, setDevelopment] = useState('');
  const { t } = useTranslation();

  const [toastMessage, setToastMessage] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  useEffect(() => {
    loadPaddle(() => {});
  });

  const fetchSettings = () => {
    axios.get(`/v1/users/${props.token}/plan`)
      .then((res) => {
        setInit(true);
        setAppSumoExpiresOn(res.data.appSumoExpiresOn);
        if (res.data.expiresIn) setExpiresIn(res.data.expiresIn);
        setAccountState(res.data.accountState);
        setClientUsage(res.data.clientUsage);
        setStorageUsage(res.data.storageUsage);
        setAllowedServices(res.data.allowedServices);
        setStaffAccountUsage(res.data.staffAccountUsage);
        setDevelopment(res.data.devData);
        setHideStaffAccountUsage(res.data.hideStaffAccountUsage);
      })
      .catch(() => {});
  };

  const getCurrentPlanOptions = () => {
    axios.get('/v1/paddle/check')
      .then((res) => {
        if (res.data.plan !== 'no plan') {
          setCurrentPlanOptions(res.data.plan);
        }
      })
      .catch(() => {
        toggleToastActive(t('shared.something_went_wrong'));
      });
  };

  const modifyStaffAccounts = (type) => {
    const params = {
      staff_type: type
    };

    axios.post('/v1/paddle/staff_modifier', params)
      .then((res) => {
        fetchSettings();
        getCurrentPlanOptions();
        if (res.data.plan === 'nope') {
          toggleToastActive("You can't delete more staffs than your minimum plan allowance");
        } else {
          toggleToastActive('Your upcoming payment has been modified');
        }
      })
      .catch(() => {
      });
  };

  useEffect(() => {
    getCurrentPlanOptions();
    fetchSettings();
  // eslint-disable-next-line
  }, []);

  const membership = () => {
    if (appSumoExpiresOn) return `AppSumo (${appSumoExpiresOn} ${t('plan.days_remaining')})`;
    if (accountState === 'trial') return `${t('plan.trial')} (${expiresIn} ${t('plan.days_remaining')})`;

    switch (props.planType) {
      case 'month': return t('plan.monthly_membership');
      case 'year': return t('plan.yearly_membership');
      default: return null;
    }
  };

  const allowedClients = (c) => (c > 10000 ? t('plan.unlimited') : c);

  const studentUsageText = () => {
    if (clientUsage.clients / clientUsage.allowed_clients >= 1.0) {
      return (
        <Stack>
          <Stack.Item>{`${clientUsage.clients} / ${allowedClients(clientUsage.allowed_clients)}`}</Stack.Item>
          <Tooltip content={t('plan.usage_limit_reached')}>
            <Icon color="critical" source={AlertMinor} />
          </Tooltip>
        </Stack>
      );
    }

    return <Stack.Item>{`${clientUsage.clients} / ${allowedClients(clientUsage.allowed_clients)}`}</Stack.Item>;
  };

  const storageUsageText = () => {
    if (storageUsage.used_storage / storageUsage.allowed_storage >= 1.0) {
      return (
        <Stack>
          <Stack.Item>{`${storageUsage.used_storage} / ${storageUsage.allowed_storage} GB`}</Stack.Item>
          <Tooltip content={t('plan.usage_limit_reached')}>
            <Icon color="critical" source={AlertMinor} />
          </Tooltip>
        </Stack>
      );
    }

    return <Stack.Item>{`${storageUsage.used_storage} / ${storageUsage.allowed_storage} GB`}</Stack.Item>;
  };

  const staffAccountUsageText = () => {
    if (staffAccountUsage.staffs / staffAccountUsage.allowed_staffs >= 1.0) {
      return (
        <Stack>
          <Stack.Item>{`${staffAccountUsage.staffs} / ${staffAccountUsage.allowed_staffs}`}</Stack.Item>
          <Tooltip content={t('plan.usage_limit_reached')}>
            <Icon color="critical" source={AlertMinor} />
          </Tooltip>
        </Stack>
      );
    }

    return <Stack.Item>{`${staffAccountUsage.staffs} / ${staffAccountUsage.allowed_staffs}`}</Stack.Item>;
  };

  const paymentMethod = (value) => {
    switch (value) {
      case 'paypal': return 'PayPal';
      case 'card': return t('plan.credit_card');
      default: return '';
    }
  };

  const cancelSubscription = () => {
    const Paddle = window.Paddle;
    Paddle.Setup({ vendor: 123186 });
    Paddle.Checkout.open({
      override: currentPlanOptions.cancel_url,
      successCallback: () => props.onLogout()
    });
  };

  const updatePlan = () => {
    const Paddle = window.Paddle;
    Paddle.Setup({ vendor: 123186 });
    Paddle.Checkout.open({
      override: currentPlanOptions.update_url
    });
  };

  const upgrade = () => {
    if (!window.confirm(t('plan.cancel_upgrade'))) {
      return;
    }

    axios.post('/v1/paddle/cancel_plan')
      .then(() => {
        if (process.env.NODE_ENV === 'development') {
          window.location.assign(`http://${props.subdomain}.localhost:3000`);
        } else {
          window.location.assign(`https://${props.subdomain}.planubo.com`);
        }
      })
      .catch(() => {});
  };
  const settingsForm = (
    <Form>
      <FormLayout>
        <Layout>
          <Layout.AnnotatedSection
            title={t('settings.change_plan_description')}
          >
            <Card sectioned>
              <Card.Section>
                <Stack>
                  <Stack.Item fill>
                    <Badge status="success">{props.plan}</Badge>
                    {props.planType && <Badge status="info">{membership()}</Badge>}
                  </Stack.Item>
                  <Button primary plain onClick={() => upgrade()}>{t('shared.change')}</Button>
                </Stack>
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title={t('settings.usage')}
            description={t('settings.usage_description')}
          >
            <Card sectioned>
              {!hideStaffAccountUsage && (
                <Card.Section>
                  {/* {development ? (
                    <div className="p-10 border rounded">
                      <div>Development Log</div>
                      <div>{`Paddle Subscription ID: ${development.paddle_subscription_id}`}</div>
                    </div>
                  ) : null} */}
                  <Stack>
                    <Stack.Item fill>Staff Account Usage</Stack.Item>
                    {staffAccountUsageText()}
                  </Stack>
                  <ProgressBar progress={staffAccountUsage.percentage} />
                  <div className="flex space-x-2 mt-2 items-center">
                    <button
                      type="button"
                      // eslint-disable-next-line max-len
                      className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-green-300 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-700"
                      onClick={() => modifyStaffAccounts('remove')}
                    >
                      <MinusSmallIconOutline className="h-3 w-3" aria-hidden="true" />
                    </button>
                    <button
                      type="button"
                      // eslint-disable-next-line max-len
                      className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => modifyStaffAccounts('add')}
                    >
                      <PlusSmallIconOutline className="h-3 w-3" aria-hidden="true" />
                    </button>
                    <div>{t('settings.plan_staff')}</div>
                  </div>
                </Card.Section>
              )}
              <Card.Section>
                <Stack>
                  <Stack.Item fill>{t('plan.student_usage')}</Stack.Item>
                  {studentUsageText()}
                </Stack>
                <ProgressBar progress={clientUsage.percentage} />
              </Card.Section>
              <Card.Section>
                <Stack>
                  <Stack.Item fill>{t('plan.storage_usage')}</Stack.Item>
                  {storageUsageText()}
                </Stack>
                <ProgressBar progress={storageUsage.percentage} />
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
          {Object.keys(currentPlanOptions).length ? (
            <>
              <Layout.AnnotatedSection
                title={t('plan.subscription_info')}
                description={t('plan.subscription_info_description')}
              >
                <Card sectioned>
                  <Card.Section>
                    <Stack>
                      <Stack.Item fill>{t('plan.signup_date')}</Stack.Item>
                      <Stack.Item>{moment(new Date(currentPlanOptions.signup_date)).format('LL')}</Stack.Item>
                    </Stack>
                  </Card.Section>
                  <Card.Section>
                    <Stack>
                      <Stack.Item fill>{t('plan.last_payment')}</Stack.Item>
                      <Stack.Item>
                        {`${currentPlanOptions.last_payment?.currency} ${currentPlanOptions.last_payment?.amount},- / `}
                        {moment(new Date(currentPlanOptions.last_payment?.date)).format('LL')}
                      </Stack.Item>
                    </Stack>
                  </Card.Section>
                  <Card.Section>
                    <Stack>
                      <Stack.Item fill>{t('plan.next_payment')}</Stack.Item>
                      <Stack.Item>
                        {`${currentPlanOptions.next_payment?.currency} ${currentPlanOptions.next_payment?.amount},- / `}
                        {moment(new Date(currentPlanOptions.next_payment?.date)).format('LL')}
                      </Stack.Item>
                    </Stack>
                  </Card.Section>
                  <Card.Section>
                    <Stack>
                      <Stack.Item fill>{t('invoices.payment_method')}</Stack.Item>
                      <Stack.Item>{paymentMethod(currentPlanOptions.payment_information?.payment_method)}</Stack.Item>
                      <Stack.Item>
                        <Button plain onClick={() => updatePlan()}>{t('shared.change')}</Button>
                      </Stack.Item>
                    </Stack>
                  </Card.Section>
                </Card>
              </Layout.AnnotatedSection>
              <Layout.AnnotatedSection
                title={t('plan.available_features')}
                description={t('plan.available_features_description')}
              >
                <Card sectioned>
                  <Card.Section>
                    <FormLayout>
                      <Stack>
                        <Stack.Item fill>{t('user.feedback')}</Stack.Item>
                        {allowedServices['Feedback Library'] ? <StatusBadge active /> : <Button onClick={() => upgrade()} plain>Upgrade</Button>}
                      </Stack>
                      <Stack>
                        <Stack.Item fill>{t('invoices.invoices')}</Stack.Item>
                        {allowedServices.Invoices ? <StatusBadge active /> : <Button onClick={() => upgrade()} plain>Upgrade</Button>}
                      </Stack>
                      <Stack>
                        <Stack.Item fill>{t('payments.payments')}</Stack.Item>
                        {allowedServices.Payments ? <StatusBadge active /> : <Button onClick={() => upgrade()} plain>Upgrade</Button>}
                      </Stack>
                      <Stack>
                        <Stack.Item fill>{t('settings.personal_branding')}</Stack.Item>
                        {allowedServices['Personal Branding'] ? <StatusBadge active /> : <Button onClick={() => upgrade()} plain>Upgrade</Button>}
                      </Stack>
                    </FormLayout>
                  </Card.Section>
                </Card>
              </Layout.AnnotatedSection>
              <Layout.AnnotatedSection
                description={t('plan.cancel_subscription')}
              >
                <Card sectioned>
                  <Card.Section>
                    <Button plain destructive onClick={() => cancelSubscription()}>{t('shared.cancel')}</Button>
                  </Card.Section>
                </Card>
              </Layout.AnnotatedSection>
            </>
          ) : ''}
        </Layout>
      </FormLayout>
    </Form>
  );

  return (
    <Page
      title={t('settings.plan_settings')}
      separator
    >
      {toastMarkup}
      {init && settingsForm}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  plan: state.auth.plan,
  planType: state.auth.planType,
  sudomain: state.auth.subdomain
});

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch(actions.logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanSettings);
