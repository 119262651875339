/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Banner, Stack, Button, Card, FormLayout, Icon, TextField, Select, Checkbox } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CirclePlusMajor, DeleteMajor } from '@shopify/polaris-icons';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : '',

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : '',
  padding: grid
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  // eslint-disable-next-line no-param-reassign
  result.forEach((r, i) => { r.order_number = i; });
  return result;
};

const CustomFields = (props) => {
  const { t } = useTranslation();

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    // perform reorder
    const cfs = reorder(
      props.customFields,
      result.source.index,
      result.destination.index
    );

    // call parent handler with new state representation
    props.setCustomFields(cfs);
  };

  const displayCustomFields = () => props.customFields.map((customField, idx) => {
    if (customField._destroy === '1') return '';
    // eslint-disable-next-line implicit-arrow-linebreak
    return (
      <Draggable draggableId={customField.order_number?.toString()} index={idx}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            <Card sectioned title={t('product.booking_field')}>
              <Stack alignment="center">
                <Stack.Item fill>
                  <TextField
                    type="text"
                    value={customField.label}
                    onChange={props.handleCustomFieldChange(idx)('label')}
                    connectedRight={(
                      <Select
                        value={customField.category}
                        label="Type"
                        onChange={props.handleCustomFieldChange(idx)('category')}
                        labelHidden
                        options={[{ label: 'Text', value: 'text' }, { label: 'Checkbox', value: 'checkbox' }]}
                      />
                    )}
                  />
                </Stack.Item>
                <Button plain icon={DeleteMajor} destructive onClick={() => props.deleteCustomField(idx)} />
              </Stack>
              <Checkbox
                checked={customField.required}
                label="Required"
                onChange={props.handleCustomFieldChange(idx)('required')}
              />
            </Card>
          </div>
        )}
      </Draggable>
    );
  });

  return (
    <div style={{ maxWidth: '800px', margin: 'auto' }}>
      <FormLayout>
        <Banner title="Info (deprecated - will be removed in future releases)" status="warning">
          <p>{t('product.custom_fields_desc')}</p>
        </Banner>
        {props.error ? (
          <Banner title="Error" status="warning" onDismiss={() => props.setError(false)}>
            <p>{t('custom_fields.labels_error')}</p>
          </Banner>
        ) : null}
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {displayCustomFields()}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Button fullWidth plain onClick={() => props.addCustomField()}>
          <Icon color="success" source={CirclePlusMajor} />
        </Button>
      </FormLayout>
    </div>
  );
};

export default CustomFields;
