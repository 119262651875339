import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CalendarIcon, BanknotesIcon, CogIcon, CreditCardIcon, InformationCircleIcon, BuildingLibraryIcon, UserCircleIcon, UsersIcon } from '@heroicons/react/20/solid';
import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';
import { connect } from 'react-redux';
import Menu from './Menu/Menu';

const ClientNavigation = (props) => {
  const { t } = useTranslation('translation');
  const location = useLocation();

  const activeRoute = (route) => location.pathname.split('/')[1] === route;

  const settingsItems = [
    {
      url: '/profile_settings',
      label: t('navigation.profile'),
      selected: activeRoute('profile_settings'),
      condition: true
    }
  ];

  const navigation = [
    {
      url: '/home',
      label: t('navigation.home'),
      icon: UserCircleIcon,
      selected: activeRoute('home'),
      condition: true
    },
    // {
    //   url: '/feedback',
    //   label: t('user.feedback'),
    //   icon: ChatBubbleOvalLeftEllipsisIcon,
    //   selected: activeRoute('feedback'),
    //   condition: true
    //   // badge: props.unread ? props.unread.toString() : null
    // },
    {
      label: t('media_library.title'),
      icon: BuildingLibraryIcon,
      selected: activeRoute('media_library'),
      url: '/media_library',
      condition: true
    },
    {
      label: t('survey.filled_out_forms'),
      icon: ClipboardDocumentCheckIcon,
      selected: activeRoute('answered_surveys'),
      url: '/answered_surveys',
      condition: true
    },
    {
      url: '/instructors',
      label: t('navigation.instructor'),
      icon: UsersIcon,
      selected: activeRoute('instructors'),
      condition: false,
      boarderTop: true
    },
    {
      label: t('navigation.calendar'),
      icon: CalendarIcon,
      selected: activeRoute('calendar'),
      url: '/calendar',
      condition: true
    },
    {
      url: '/invoices',
      label: t('invoices.invoices'),
      icon: BanknotesIcon,
      selected: activeRoute('invoices'),
      condition: true,
      boarderTop: true
      // badge: props.unpaidInvoices ? props.unpaidInvoices.toString() : null
    },
    {
      url: '/payments',
      label: t('payments.payments'),
      icon: CreditCardIcon,
      selected: activeRoute('payments'),
      onClick: props.toggle,
      condition: true
    },
    {
      label: t('settings.settings'),
      selected: settingsItems.some((h) => h.url.split('/')[1] === location.pathname.split('/')[1]),
      children: settingsItems,
      icon: CogIcon,
      condition: true,
      boarderTop: true
    },
    {
      label: 'Help Center',
      selected: activeRoute('help'),
      condition: true,
      icon: InformationCircleIcon,
      boarderTop: true,
      children: [
        {
          url: '/help',
          label: t('help.help'),
          selected: activeRoute('help'),
          condition: true
        }
      ]
    }
  ];

  return (
    <Menu toggle={props.toggle} navigation={navigation} />
  );
};

const mapStateToProps = (state) => ({
  unread: state.auth.unread,
  unpaidInvoices: state.auth.unpaidInvoices
});

export default connect(mapStateToProps)(ClientNavigation);
