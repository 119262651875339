import React, { useState, useRef, useEffect } from 'react';
import { Checkbox, TextField, TextStyle } from '@shopify/polaris';
import { TrashIcon } from '@heroicons/react/20/solid';
import axios from 'axios';
import { RadioGroup } from '@headlessui/react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ReactFormGenerator } from '../Shared/FormBuilder';
import CurrencyFormatter from '../Shared/CurrencyFormatter';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Checkout = (props) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(props.products[0].self.has_credit_card ? 'credit-card' : '');
  const [giftCardValue, setGiftCardValue] = useState(0);
  const [rawGiftCardValue, setRawGiftCardValue] = useState(0);
  const [childSubmitComponent, setChildSubmitComponent] = useState({});
  const [selected, setSelected] = useState({});
  const [customFields, setCustomFields] = useState(props.customFields);
  const [formErrors, setFormErrors] = useState({});
  const [eventPackages, setEventPackages] = useState([]);
  const [expired, setExpired] = useState(false);

  const { t } = useTranslation();

  const formRef = useRef();

  const paymentMethods = [];

  if (props.products[0].self.requires_payment && props.products[0].self.has_credit_card) {
    paymentMethods.push({ id: 'credit-card', title: 'Credit card' });
  }

  if (props.products[0].product_settings?.enable_gift_card) {
    paymentMethods.push({ id: 'gift-card', title: 'Gift card' });
  }

  if (props.products[0].product_settings?.enable_event_package) {
    paymentMethods.push({ id: 'event-package', title: 'Event package' });
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    childSubmitComponent.handleSubmit();
  };

  const checkGiftCard = () => {
    axios.post('/v1/public_profiles/check_gift_card', { code: formRef.current['gift-card-code'].value })
      .then((res) => {
        setGiftCardValue(res.data.value);
        setRawGiftCardValue(res.data.raw_value);
        setExpired(res.data.expired);
      })
      .catch(() => {
      });
  };

  const searchEventPackages = () => {
    axios.post('/v1/event_packages/search')
      .then((res) => {
        setEventPackages(res.data.event_packages);
      })
      .catch(() => {
      });
  };

  useEffect(() => {
    searchEventPackages();
  }, []);

  const handleCustomFieldChange = (idx) => (value) => {
    const temp = [...customFields];
    temp[idx].value = value;
    temp[idx].touched = true;
    setCustomFields(temp);
  };

  const displayCustomFields = () => customFields.map((customField, idx) => {
    switch (customField.category) {
      case 'text': return (
        <TextField
          key={idx}
          requiredIndicator={customField.required}
          label={customField.label}
          value={customField.value}
          error={formErrors[customField.label]}
          onChange={handleCustomFieldChange(idx)}
        />
      );
      case 'checkbox': return (
        <Checkbox
          requiredIndicator={customField.required}
          checked={customField.value}
          label={(<div dangerouslySetInnerHTML={{ __html: customField.label }} />)}
          onChange={handleCustomFieldChange(idx)}
          error={formErrors[customField.label]}
        />
      );
      default: return (
        <TextField
          key={idx}
          requiredIndicator={customField.required}
          label={customField.label}
          value={customField.value}
          error={formErrors[customField.label]}
          onChange={handleCustomFieldChange(idx)}
        />
      );
    }
  });

  const calc = () => {
    if (expired) {
      return props.price;
    }

    // Later, loop over all products, for now we only have one product
    const result = (Number(props.products[0].product_raw_price) - rawGiftCardValue).toPrecision(4);

    if (result < 0) return <CurrencyFormatter value={0} />;

    return <CurrencyFormatter value={result} />;
  };

  const submitButtonDisabled = () => {
    if (props.isLoading) {
      return true;
    }

    if (selectedPaymentMethod === 'event-package' && !Object.keys(selected).length) {
      return true;
    }

    return false;
  };

  const formValid = () => {
    const e = {};

    customFields.forEach((cf) => {
      if (cf.required && !cf.value) {
        e[cf.label] = t('shared.validations.required');
      }
    });

    setFormErrors(e);
    return Object.entries(e).length === 0;
  };

  const finalSubmit = (data) => {
    if (!formValid()) return;

    setFormErrors({});

    let pbCustomFields = [];

    if (customFields.length) {
      const temp = [...customFields];
      temp.forEach((cf) => {
        if (cf.value) {
          // eslint-disable-next-line
          cf.value = cf.value.toString();
        }
      });

      pbCustomFields = customFields;
    }

    props.onSubmit({
      form_data_reply: data,
      email: props.user.email || formRef.current['email-address'].value,
      firstName: props.user.firstName || formRef.current['first-name'].value,
      lastName: props.user.lastName || formRef.current['last-name'].value,
      code: formRef.current['gift-card-code']?.value,
      paymentMethod: selectedPaymentMethod,
      eventPackage: selected.id,
      pbCustomFields
    });
  };

  const handlePaymentChange = (value) => {
    setSelectedPaymentMethod(value);
    setSelected({});
    resetGiftCard();
  };

  const resetGiftCard = () => {
    if (formRef.current['gift-card-code']?.value) {
      formRef.current['gift-card-code'].value = '';
    }

    setGiftCardValue(0);
    setRawGiftCardValue(0);
  };

  useEffect(() => {
    if (props.isLoggedIn) {
      formRef.current['email-address'].value = props.user.email;
      formRef.current['first-name'].value = props.user.firstName;
      formRef.current['last-name'].value = props.user.lastName;
    }
  }, [props.isLoggedIn, props.user.email, props.user.firstName, props.user.lastName]);

  return (
    <div className="">
      <h2 className="sr-only">Checkout</h2>

      <form className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16" ref={formRef} onSubmit={handleSubmit}>
        <div>
          <h2 className="text-lg font-medium text-gray-900">Contact information</h2>
          <div className="mt-4">
            <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
              {t('user.email')}
            </label>
            <div className="mt-1">
              <input
                disabled={props.isLoggedIn}
                type="email"
                id="email-address"
                required
                name="email-address"
                autoComplete="email"
                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${props.isLoggedIn && 'cursor-not-allowed bg-gray-100'}`}
              />
            </div>
          </div>

          <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4 mb-6">
            <div>
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                {t('user.first_name')}
              </label>
              <div className="mt-1">
                <input
                  disabled={props.isLoggedIn}
                  type="text"
                  id="first-name"
                  required
                  name="first-name"
                  autoComplete="given-name"
                  className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${props.isLoggedIn && 'cursor-not-allowed bg-gray-100'}`}
                />
              </div>
            </div>

            <div>
              <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                {t('user.last_name')}
              </label>
              <div className="mt-1">
                <input
                  disabled={props.isLoggedIn}
                  type="text"
                  id="last-name"
                  required
                  name="last-name"
                  autoComplete="family-name"
                  className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${props.isLoggedIn && 'cursor-not-allowed bg-gray-100'}`}
                />
              </div>
            </div>
          </div>
          {/* )} */}

          {/* <div className="mt-10 border-t border-gray-200 pt-10">
            <h2 className="text-lg font-medium text-gray-900">Shipping information</h2>

            <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">

              <div className="sm:col-span-2">
                <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                  Company
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="company"
                    id="company"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                  Address
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="address"
                    id="address"
                    autoComplete="street-address"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label htmlFor="apartment" className="block text-sm font-medium text-gray-700">
                  Apartment, suite, etc.
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="apartment"
                    id="apartment"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                  City
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="city"
                    id="city"
                    autoComplete="address-level2"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                  Country
                </label>
                <div className="mt-1">
                  <select
                    id="country"
                    name="country"
                    autoComplete="country-name"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  >
                    <option>United States</option>
                    <option>Canada</option>
                    <option>Mexico</option>
                  </select>
                </div>
              </div>

              <div>
                <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                  State / Province
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="region"
                    id="region"
                    autoComplete="address-level1"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                  Postal code
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="postal-code"
                    id="postal-code"
                    autoComplete="postal-code"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                  Phone
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    autoComplete="tel"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
          </div> */}

          {/* Custom Fields */}
          <div className="mt-0 border-t border-gray-200 pt-4" id="additional-information">
            <h2 className="text-lg font-medium text-gray-900">{t('checkout.additional_info')}</h2>
            {displayCustomFields()}
            {/* We only have one product for now */}
            <ReactFormGenerator
              setChildSubmitComponent={setChildSubmitComponent}
              hideSubmit
              onSubmit={(data) => finalSubmit(data)}
              data={JSON.parse(props.products[0].bookingFields)}
            />
          </div>

          {/* Payment */}
          {paymentMethods.length ? (
            <div className="mt-0 border-t border-gray-200 pt-4">
              <h2 className="text-lg font-medium text-gray-900">{t('payments.payment')}</h2>

              <fieldset className="mt-4">
                <legend className="sr-only">{t('payments.type')}</legend>
                <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                  {paymentMethods.map((paymentMethod, paymentMethodIdx) => (
                    <div key={paymentMethod.id} className="flex items-center">
                      {paymentMethodIdx === 0 ? (
                        <input
                          id={paymentMethod.id}
                          name="payment-type"
                          checked={paymentMethod.id === selectedPaymentMethod}
                          type="radio"
                          onChange={() => handlePaymentChange(paymentMethod.id)}
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                      ) : (
                        <input
                          id={paymentMethod.id}
                          name="payment-type"
                          checked={paymentMethod.id === selectedPaymentMethod}
                          onChange={() => handlePaymentChange(paymentMethod.id)}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                      )}

                      <label htmlFor={paymentMethod.id} className="ml-3 block text-sm font-medium text-gray-700">
                        {paymentMethod.title}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>

              {selectedPaymentMethod === 'credit-card' && (
              <>
                <div className="mt-2">{props.payNotification}</div>
                {/* <div className="grid grid-cols-4 gap-x-4 gap-y-6">
       <div className="col-span-4">
         <label htmlFor="card-number" className="block text-sm font-medium text-gray-700">
           Card number
         </label>
         <div className="mt-1">
           <input
             type="text"
             id="card-number"
             name="card-number"
             autoComplete="cc-number"
             className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
           />
         </div>
       </div>

       <div className="col-span-4">
         <label htmlFor="name-on-card" className="block text-sm font-medium text-gray-700">
           Name on card
         </label>
         <div className="mt-1">
           <input
             type="text"
             id="name-on-card"
             name="name-on-card"
             autoComplete="cc-name"
             className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
           />
         </div>
       </div>

       <div className="col-span-3">
         <label htmlFor="expiration-date" className="block text-sm font-medium text-gray-700">
           Expiration date (MM/YY)
         </label>
         <div className="mt-1">
           <input
             type="text"
             name="expiration-date"
             id="expiration-date"
             autoComplete="cc-exp"
             className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
           />
         </div>
       </div>

       <div>
         <label htmlFor="cvc" className="block text-sm font-medium text-gray-700">
           CVC
         </label>
         <div className="mt-1">
           <input
             type="text"
             name="cvc"
             id="cvc"
             autoComplete="csc"
             className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
           />
         </div>
       </div>
     </div> */}
              </>
              )}

              {selectedPaymentMethod === 'gift-card' && (
              <div className="grid grid-cols-4 gap-x-4 gap-y-6 mt-4">
                <div className="col-span-3">
                  <label htmlFor="card-number" className="block text-sm font-medium text-gray-700">
                    Code
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="gift-card-code"
                      name="gift-card-code"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div className="col-span-1 mt-6">
                  <button
                    type="button"
                    className="content-end w-full rounded-sm bg-transparent px-2 py-1 text-base font-medium hover:bg-gray-100 focus:outline-none"
                    onClick={() => checkGiftCard()}
                  >
                    {t('shared.use')}
                  </button>
                </div>
              </div>
              )}

              {selectedPaymentMethod === 'event-package' && (
              <RadioGroup value={selected} onChange={setSelected}>
                <RadioGroup.Label className="sr-only">{t('event_package.plural')}</RadioGroup.Label>
                <div className="relative mt-4 -space-y-px rounded-md bg-white">
                  {eventPackages.map((plan, planIdx) => (
                    <RadioGroup.Option
                      key={plan.name}
                      value={plan}
                      className={({ checked }) => classNames(
                        planIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                        planIdx === eventPackages.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                        checked ? 'z-10 border-indigo-200 bg-indigo-50' : 'border-gray-200',
                        'relative flex cursor-pointer flex-col border p-4 focus:outline-none md:grid md:grid-cols-2 md:pl-4 md:pr-6'
                      )}
                    >
                      {({ active, checked }) => (
                        <>
                          <span className="flex items-center text-sm">
                            <span
                              className={classNames(
                                checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300',
                                active ? 'ring-2 ring-offset-2 ring-indigo-600' : '',
                                'h-4 w-4 rounded-full border flex items-center justify-center'
                              )}
                              aria-hidden="true"
                            >
                              <span className="rounded-full bg-white w-1.5 h-1.5" />
                            </span>
                            <RadioGroup.Label
                              as="span"
                              className={classNames(checked ? 'text-indigo-900' : 'text-gray-900', 'ml-3 font-medium')}
                            >
                              {plan.name}
                            </RadioGroup.Label>
                          </span>
                          <RadioGroup.Description
                            as="span"
                            className={classNames(
                              checked ? 'text-indigo-700' : 'text-gray-500',
                              'ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right'
                            )}
                          >
                            {plan.current_value}
                          </RadioGroup.Description>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
              )}
            </div>
          ) : ''}
        </div>

        {/* Order summary */}
        <div className="mt-10 lg:mt-0">
          <h2 className="text-lg font-medium text-gray-900">{t('checkout.order_summary')}</h2>

          <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
            <h3 className="sr-only">{t('checkout.items_in_cart')}</h3>
            <ul className="divide-y divide-gray-200">
              {props.products.map((product) => (
                <li key={product.id} className="flex px-4 py-6 sm:px-6">
                  <div className="flex-shrink-0">
                    <img src={product.imageSrc} alt={product.imageAlt} className="w-20 rounded-md" />
                  </div>

                  <div className="ml-6 flex flex-1 flex-col">
                    <div className="flex">
                      <div className="min-w-0 flex-1">
                        <h4 className="text-sm">
                          <a href={product.href} className="font-medium text-gray-700 hover:text-gray-800">
                            {product.title}
                          </a>
                        </h4>
                        <p className="mt-1 text-sm text-gray-500">{product.instructorName}</p>
                        {product.self.type !== 'Product::LessonPackage' ? (
                          <p className="mt-1 text-sm text-gray-500">
                            {product.self.one_time_event ? (
                              <>
                                <p>
                                  {`${t('time.from')} ${moment(product.self.p_start_time).format('LL')}`}
                                </p>
                                <p>
                                  {`${t('time.to')} ${moment(product.self.p_end_time).format('LL')}`}
                                </p>
                              </>
                            ) : product.timeslot}
                          </p>
                        ) : ''}
                        {product.self.type !== 'Product::LessonPackage' ? (
                          <p className="mt-1 text-sm text-gray-500">{product.duration}</p>
                        ) : ''}
                      </div>

                      <div className="ml-4 flow-root flex-shrink-0">
                        <button
                          type="button"
                          onClick={() => props.close()}
                          className="-m-2.5 flex items-center justify-center bg-white p-2.5 text-gray-400 hover:text-gray-500"
                        >
                          <span className="sr-only">Remove</span>
                          <TrashIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>

                    <div className="flex flex-1 items-end justify-between pt-2">
                      {/* <p className="mt-1 text-sm font-medium text-gray-900">{product.price}</p> */}

                      {/* <div className="ml-4">
                        <label htmlFor="quantity" className="sr-only">
                          Quantity
                        </label>
                        <select
                          id="quantity"
                          name="quantity"
                          className="rounded-md border border-gray-300 text-left text-base font-medium text-gray-700 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                        >
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                          <option value={6}>6</option>
                          <option value={7}>7</option>
                          <option value={8}>8</option>
                        </select>
                      </div> */}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <dl className="space-y-6 border-t border-gray-200 px-4 py-6 sm:px-6">
              <div className="flex items-center justify-between">
                <dt className="text-sm">{t('invoices.subtotal')}</dt>
                <dd className="text-sm font-medium text-gray-900">{props.price}</dd>
              </div>
              {/* <div className="flex items-center justify-between">
                <dt className="text-sm">Shipping</dt>
                <dd className="text-sm font-medium text-gray-900">$5.00</dd>
              </div>
              <div className="flex items-center justify-between">
                <dt className="text-sm">Taxes</dt>
                <dd className="text-sm font-medium text-gray-900">$5.52</dd>
              </div> */}
              {giftCardValue ? (
                <div className="flex items-center justify-between">
                  <dt className="text-sm flex">
                    {t('shared.gift_card')}
                    <button
                      type="button"
                      onClick={() => resetGiftCard()}
                      className="flex items-center justify-center bg-white text-gray-400 hover:text-gray-500"
                    >
                      <span className="sr-only">{t('shared.remove')}</span>
                      <TrashIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </dt>
                  <dd className="text-sm font-medium text-gray-900">
                    <TextStyle subdued>{expired ? <div className="inline-flex items-center rounded-md bg-red-50 text-red-700 ring-red-600/20 px-2 py-1 text-xs font-medium ring-1 ring-inset">Expired</div> : giftCardValue}</TextStyle>
                  </dd>
                </div>
              ) : null}
              <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                <dt className="text-base font-medium">Total</dt>
                <dd className="text-base font-medium text-gray-900">{calc()}</dd>
              </div>
            </dl>

            <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
              <button
                type="submit"
                disabled={submitButtonDisabled()}
                className={classNames(
                  submitButtonDisabled() ? 'cursor-not-allowed bg-gray-200 hover:bg-gray-100' : 'bg-indigo-600 hover:bg-indigo-700',
                  'w-full rounded-md border border-transparent px-4 py-3 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50'
                )}
              >
                {t('shared.confirm')}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Checkout;
