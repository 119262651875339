/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { Card, Filters, Page, Toast, ProgressBar, Stack, Tooltip, Icon } from '@shopify/polaris';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { AlertMinor } from '@shopify/polaris-icons';
import { connect } from 'react-redux';
import SidePanel from './SidePanel';
import Breadcrumbs from './Container/Breadcrumbs';
import useDebounce from '../../hooks/useDebounce';
import { stateConverter } from '../FilterEngine/filterParams';
import { handleFilterChange } from '../FilterEngine/FilterEngine';
import DroppableStory from './Container/Droppables/DroppableStory';
import Upload from './Upload';

const MediaLibrary = (props) => {
  const [init, setInit] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [mediaLibraryToken, setMediaLibraryToken] = useState('');
  const [sidepanelOpen, setSidepanelOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState({});
  const [selectedPath, setSelectedPath] = useState([]);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(null);
  const [permissions, setPermissions] = useState({});

  const location = useLocation();
  const history = useNavigate();

  const [tableFilters, setTableFilters] = useState(stateConverter(location));
  const debouncedSearch = useDebounce(tableFilters, 400);

  const [files, setFiles] = useState([]);
  const [folders, setFolders] = useState([]);
  const [uploads, setUploads] = useState([]);
  const [storage, setStorage] = useState(null);
  const [showPermissions, setShowPermissions] = useState(false);

  const [toastMessage, setToastMessage] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const { t } = useTranslation();

  const searchFiles = useCallback(() => {
    const params = {
      per_page: 100,
      page: 1,
      search: tableFilters.queryValue,
      q: '',
      s: { sorts: tableFilters.sorts }
    };

    if (tableFilters.queryValue.length) {
      axios.post(`/v1/media_libraries/${mediaLibraryToken}/media_library_files/search`, params)
        .then((response) => {
          setFiles(response.data.files);
          setFolders(response.data.folders);
          setStorage(response.data.storageUsage);
          setPermissions(response.data.permissions);
        })
        .catch(() => {});
    } else {
      fetchContent();
    }
  // eslint-disable-next-line
  }, [debouncedSearch]);

  useEffect(() => {
    if (init) {
      searchFiles();
    }
  // eslint-disable-next-line
  }, [debouncedSearch]);

  const fetchContent = useCallback(async (path = '') => {
    const mediaLibraryIds = await axios.get('/v1/media_libraries')
      .then((res) => res.data)
      .catch(() => []);

    if (selectedFolder.id || path) {
      axios.get(`/v1/media_libraries/${mediaLibraryToken}/media_library_files/${selectedFolder.id || path}`)
        .then((response) => {
          setFiles(response.data.files);
          setFolders(response.data.folders);
          setStorage(response.data.storageUsage);
          setPermissions(response.data.permissions);
        })
        .catch(() => {});
    } else {
      // eslint-disable-next-line no-unused-vars
      const responses = await axios.get(`/v1/media_libraries/${mediaLibraryIds[0].token}`)
        .then((res) => res.data)
        .catch(() => []);

      // await mediaLibraryIds.forEach(async (library) => axios.get(`/v1/media_libraries/${library.token}`).then((res) => responses.push(res.data)));

      setMediaLibraryToken(mediaLibraryIds[0].token);
      setSelectedFolder({});
      setStorage(responses.storageUsage);
      setFiles(responses.files);
      setFolders(responses.folders);
      setLoading(false);
      setInit(true);
    }
  // eslint-disable-next-line
  }, [selectedFolder.id]);

  const uploadFile = useCallback((a) => {
    const formData = new FormData();

    formData.append('[media_library_file][name]', a.name);
    formData.append('[media_library_file][content_type]', 'file');

    if (selectedFolder.id) {
      formData.append('[media_library_file][parent_id]', selectedFolder.id);
    }

    if (a?.size > 0) {
      formData.append('upload', a);
    }

    axios.post(
      `/v1/media_libraries/${mediaLibraryToken}/media_library_files`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => setProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100)),
        maxContentLength: Infinity,
        maxBodyLength: Infinity
      }
    )
      .then(() => {
        fetchContent(selectedFolder.id);
        toggleToastActive('Upload successful');
        setUploads([]);
      })
      .catch((error) => {
        setUploads([]);
        toggleToastActive(error.response.data?.errors);
      })
      .then(() => {
        setProgress(null);
      });
  // eslint-disable-next-line
  }, [fetchContent, mediaLibraryToken, selectedFolder.id]);

  const fetchSelectedFile = (id, load = true) => {
    if (load) setLoading(true);

    axios.get(`/v1/media_libraries/${mediaLibraryToken}/media_library_files/${id}`)
      .then((response) => {
        setSelectedFile(response.data);
        setPermissions(response.data.permissions);
      })
      .catch(() => {})
      .then(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchContent();
  }, [fetchContent]);

  useEffect(() => {
    if (uploads.length) {
      uploads.forEach((upload) => {
        uploadFile(upload);
      });
    }
  }, [uploads, uploadFile]);

  const updateFile = () => {
    const params = {
      media_library_file: {
        name: selectedFile.name,
        favorite: selectedFile.favorite,
        description: selectedFile.description
      }
    };

    axios.patch(`/v1/media_libraries/${mediaLibraryToken}/media_library_files/${selectedFile.id}`, params)
      .then(() => {
        fetchContent();
      })
      .catch(() => {});
  };

  const filters = [];

  const placeHolderText = 'Search';

  const newFolder = () => {
    const params = {
      media_library_file: {
        name: 'New Folder',
        content_type: 'folder',
        parent_id: selectedFolder.id
      }
    };

    axios.post(`/v1/media_libraries/${mediaLibraryToken}/media_library_files`, params)
      .then(() => {
        fetchContent();
      })
      .catch(() => {});
  };

  const menu = [
    { name: `+ ${t('media_library.new_folder')}`, action: newFolder }
  ];

  const moveFile = (data) => {
    if (data.active?.id && data.over?.id) {
      const params = {
        active: data.active.id,
        over: data.over.id
      };

      axios.post(`/v1/media_libraries/${mediaLibraryToken}/media_library_files/move`, params)
        .then(() => {
          toggleToastActive(t('media_library.file_moved_successfully'));
          fetchContent();
        })
        .catch(() => {
        });
    }
  };

  const deleteItem = (id) => {
    axios.delete(`/v1/media_libraries/${mediaLibraryToken}/media_library_files/${id}`)
      .then(() => {
        fetchContent();
        setSidepanelOpen(false);
      })
      .catch(() => {});
  };

  const sidePanelCloseAction = () => {
    setSidepanelOpen(false);
    setTimeout(() => {
      setSelectedFile(null);
    }, [1000]);
  };

  const handleSingleClick = (data) => {
    fetchSelectedFile(data.id);
    setSidepanelOpen(true);
  };

  const revokePermissions = (id) => {
    const params = {
      user_id: id
    };

    axios.post(`/v1/media_libraries/${mediaLibraryToken}/media_library_files/${selectedFile.id}/revoke_permissions`, params)
      .then(() => {
        fetchSelectedFile(selectedFile.id, false);
        fetchContent();
      })
      .catch(() => {
        toggleToastActive('Please try again');
      });
  };

  const createPermissions = (params) => {
    axios.post(`/v1/media_libraries/${mediaLibraryToken}/media_library_files/${selectedFile.id}/create_permissions`, params)
      .then(() => {
        fetchSelectedFile(selectedFile.id, false);
        fetchContent();
      })
      .catch(() => {
        toggleToastActive(t('media_library.please_try_again'));
      });
  };

  const handleDoubleClick = (data) => {
    setSelectedFolder(data);
    setSelectedPath([...selectedPath, data]);

    axios.get(`/v1/media_libraries/${mediaLibraryToken}/media_library_files/${data.id}`)
      .then((response) => {
        setFiles(response.data.files);
        setFolders(response.data.folders);
      })
      .catch(() => {});
  };

  const resetPath = () => {
    setSelectedPath([]);
    setSelectedFolder({});
  };

  const changePath = (data) => {
    const temp = [...selectedPath];

    const index = selectedPath.findIndex((path) => path.id === data.id);

    temp.length = index + 1;

    setSelectedFolder(data);
    setSelectedPath(temp);
  };

  const storageUsageText = () => {
    if (!Object.keys(storage).length || props.isClient) {
      return '';
    }

    if (storage.used_storage / storage.allowed_storage >= 1.0) {
      return (
        <div className="flex justify-center">
          <div>{`${storage.used_storage} / ${storage.allowed_storage} GB`}</div>
          <Tooltip content={t('plan.usage_limit_reached')}>
            <Icon color="critical" source={AlertMinor} />
          </Tooltip>
        </div>
      );
    }

    return <Stack.Item>{`${storage.used_storage} / ${storage.allowed_storage} GB`}</Stack.Item>;
  };

  return (
    <Page
      separator
      title={t('media_library.title')}
      fullWidth
    >
      {toastMarkup}
      <Card sectioned>
        <SidePanel
          isLoading={loading}
          open={sidepanelOpen}
          close={sidePanelCloseAction}
          deleteItem={deleteItem}
          file={selectedFile}
          setFile={setSelectedFile}
          permissions={permissions}
          createPermissions={createPermissions}
          mediaLibraryToken={mediaLibraryToken}
          updateFile={updateFile}
          revokePermissions={revokePermissions}
          setShowPermissions={setShowPermissions}
        />
        <div id="contextMenu">
          {/* Content area */}
          <div className="flex-1 flex flex-col overflow-hidden">
            <div className="mt-2">
              <Upload setFiles={setUploads} files={uploads} outline={false} onClick={() => {}} showPreview={false}>
                <DroppableStory
                  header={(
                    <header className="w-full px-4">
                      <div className="flex flex-1">
                        <Breadcrumbs
                          pages={selectedPath}
                          resetPath={resetPath}
                          changePath={changePath}
                          moveFile={moveFile}
                        />
                        {storage && (
                          <div className="mb-2">
                            {storageUsageText()}
                            <ProgressBar progress={storage?.percentage} size="small" />
                          </div>
                        )}
                      </div>
                      <Filters
                        queryValue={tableFilters.queryValue}
                        filters={filters}
                        onQueryChange={handleFilterChange({ history, location }, setTableFilters, 'queryValue')}
                        onQueryClear={handleFilterChange({ history, location }, setTableFilters, 'queryValue', 'reset', '')}
                        onClearAll={() => {}}
                        appliedFilters={[]}
                        queryPlaceholder={placeHolderText}
                      />
                    </header>
                  )}
                  files={files}
                  folders={folders}
                  isLoading={loading}
                  handleSingleClick={handleSingleClick}
                  handleDoubleClick={handleDoubleClick}
                  moveFile={moveFile}
                  newFolder={newFolder}
                />
              </Upload>
              {progress ? (
                <div className="mb-4">
                  <ProgressBar progress={progress} color="success" />
                </div>
              ) : ''}
              <Upload setFiles={setUploads} files={uploads} outline showPreview />
            </div>
          </div>
        </div>
      </Card>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  isClient: state.auth.client
});

export default connect(mapStateToProps)(MediaLibrary);
