/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Banner, Stack, Button, Card, FormLayout, Icon, ButtonGroup } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CirclePlusMajor, DeleteMajor } from '@shopify/polaris-icons';
import TinymceEditor from '../Tinymce/TinymceEditor';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : '',

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : '',
  padding: grid
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  // eslint-disable-next-line no-param-reassign
  result.forEach((r, i) => { r.order_number = i; });
  return result;
};

const StaffSectionBlocks = (props) => {
  const { t } = useTranslation();

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    // perform reorder
    const cfs = reorder(
      props.customFields,
      result.source.index,
      result.destination.index
    );

    // call parent handler with new state representation
    props.setCustomFields(cfs);
  };

  const displayCustomFields = () => props.customFields.map((customField, idx) => {
    if (customField._destroy === '1') return '';
    if (customField.language !== props.customFieldLang) return '';
    // eslint-disable-next-line implicit-arrow-linebreak

    return (
      <Draggable draggableId={customField.order_number?.toString()} index={idx}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            <Card sectioned title="Section">
              <Stack alignment="center">
                <Stack.Item fill>
                  <TinymceEditor
                    height="600"
                    value={customField.value}
                    handleChange={props.handleCustomFieldChange(idx)('value')}
                  />
                </Stack.Item>
                <Button plain icon={DeleteMajor} destructive onClick={() => props.deleteCustomField(idx)} />
              </Stack>
            </Card>
          </div>
        )}
      </Draggable>
    );
  });

  return (
    <FormLayout>
      {props.error ? (
        <Banner title="Error" status="warning" onDismiss={() => props.setError(false)}>
          <p>{t('custom_fields.labels_error')}</p>
        </Banner>
      ) : null}
      <ButtonGroup segmented fullWidth>
        <Button pressed={props.customFieldLang === 'en'} onClick={() => props.setCustomFieldLang('en')}>English</Button>
        <Button pressed={props.customFieldLang === 'de'} onClick={() => props.setCustomFieldLang('de')}>German</Button>
        <Button pressed={props.customFieldLang === 'fr'} onClick={() => props.setCustomFieldLang('fr')}>French</Button>
      </ButtonGroup>
      <div className="mx-6">
        {props.tagDisplay}
      </div>
      {props.children}
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {displayCustomFields()}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button fullWidth plain onClick={() => props.addCustomField()}>
        <Icon color="success" source={CirclePlusMajor} />
      </Button>
    </FormLayout>
  );
};

export default StaffSectionBlocks;
