import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, DataTable, Select, TextStyle } from '@shopify/polaris';
import { DeleteMajor, PlusMinor } from '@shopify/polaris-icons';
import { useTranslation } from 'react-i18next';
import { days, hours, meridiemHours } from '../Shared/Constants/Hours';

const Schedule = (props) => {
  const [init, setInit] = useState(false);
  const [availability, setAvailability] = useState(props.value);

  const { t } = useTranslation();

  const modifier = (tableData) => (Array.isArray(tableData) ? tableData : []).map((row, i) => {
    const modifiedRow = [];

    modifiedRow.push(
      <Select
        options={days(t)}
        value={row.day}
        onChange={handleChange('day', i)}
      />
    );
    modifiedRow.push(
      <Select
        options={props.lang === 'en' ? meridiemHours : hours}
        onChange={handleChange('start', i)}
        value={row.start}
      />
    );
    modifiedRow.push(
      <Select
        options={props.lang === 'en' ? meridiemHours : hours}
        onChange={handleChange('end', i)}
        value={row.end}
      />
    );
    modifiedRow.push(<Button plain destructive icon={DeleteMajor} onClick={() => removeDay(i)} />);

    return modifiedRow;
  });

  const handleChange = (field, i) => (value) => {
    if (props.useHooks) {
      const temp = [...props.value];
      temp[i][field] = value;
      props.handleChange(temp);
    } else {
      const temp = [...availability];
      temp[i][field] = value;
      setAvailability(temp);
      props.handleChange(temp);
    }
  };

  const addDay = () => {
    if (props.useHooks) {
      const temp = [...props.value, { day: '0', start: '00:00:00', end: '24:00:00' }];
      props.handleChange(temp);
    } else {
      let temp = [];
      if (Array.isArray(availability)) {
        temp = [...availability, { day: '0', start: '00:00:00', end: '24:00:00' }];
      } else {
        temp = [{ day: '0', start: '00:00:00', end: '24:00:00' }];
      }
      setAvailability(temp);
      props.handleChange(temp);
    }
  };

  const removeDay = (i) => {
    if (props.useHooks) {
      const temp = [...props.value];
      temp.splice(i, 1);
      props.handleChange(temp);
    } else {
      const temp = [...availability];
      temp.splice(i, 1);
      setAvailability(temp);
      props.handleChange(temp);
    }
  };

  useEffect(() => {
    if (init && !props.useHooks) {
      props.handleChange(availability);
    }

    setInit(true);
  // eslint-disable-next-line
  }, [availability]);

  return (
    <TextStyle>
      <DataTable
        verticalAlign="middle"
        columnContentTypes={[
          'text',
          'text',
          'text'
        ]}
        headings={['Day', t('shared.start'), t('shared.end'), '']}
        rows={modifier(props.useHooks ? props.value : availability)}
        footerContent={<Button icon={PlusMinor} onClick={() => addDay()}>{t('shared.add')}</Button>}
      />
    </TextStyle>
  );
};

const mapStateToProps = (state) => ({
  lang: state.auth.lang
});

export default connect(mapStateToProps)(Schedule);
