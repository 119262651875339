/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {
  ContextualSaveBar, Page, Layout, Card, Select, FormLayout, Button, Form, Toast,
  TextField, Badge, Stack, TextStyle, Thumbnail, DropZone, Tabs
} from '@shopify/polaris';
import { useLocation, useNavigate } from 'react-router-dom';
import languages from '../Shared/Constants/Languages';
import SavedAddressModalForm from '../Shared/SavedAddressModalForm';
import * as actions from '../../store/actions/index';
import Banners from '../Shared/Banners';
import { getTabId, getTabIndex, onTabSelect } from '../FilterEngine/Tabs/tabs';
import { stateConverter } from '../FilterEngine/filterParams';
import StaffSectionBlocks from '../PublicProfile/StaffSectionBlocks';
import Tags from '../Tags/Tags';
import TagModifier from '../Tags/TagModifier';
import usePrevious from '../../hooks/usePrevious';

const Settings = (props) => {
  const [showSaveBar, setShowSaveBar] = useState(false);
  const [avatar, setAvatar] = useState('');
  const [label, setLabel] = useState('');
  const [banner, setBanner] = useState([]);
  const [initialTags, setInitialTags] = useState([]);
  const [init, setInit] = useState(false);
  const [initTags, setInitTags] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [profileCustomFields, setProfileCustomFields] = useState([]);
  const [customFieldError, setCustomFieldError] = useState(false);
  const [customFieldLang, setCustomFieldLang] = useState('en');
  const [savedAddress, setSavedAddress] = useState({});
  const [showSavedAddressModal, setShowSavedAddressModal] = useState(false);
  const [userData, setUserData] = useState({
    email: null,
    firstName: null,
    lastName: null,
    password: '',
    passwordConfirmation: '',
    lang: '',
    phoneNumber: '',
    savedAddresses: []
  });

  const { t } = useTranslation();

  const history = useNavigate();
  const location = useLocation();
  const [tableFilters, setTableFilters] = useState(stateConverter(location));

  const [toastMessage, setToastMessage] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const tabs = [
    {
      id: 'profile',
      content: 'Profile',
      accessibilityLabel: 'Profile',
      panelID: 'profile'
    }
  ];

  if (props.hasBookingProfile) {
    tabs.push({
      id: 'bookingProfile',
      content: 'Booking profile',
      accessibilityLabel: 'Booking profile',
      panelID: 'bookingProfile'
    });
  }

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const handleChange = (field) => (value) => {
    setShowSaveBar(true);
    setUserData({ ...userData, [field]: value });
  };

  const handleProfileEdit = () => {
    setLoading(true);

    const formData = new FormData();

    formData.append('user[email]', userData.email);
    formData.append('user[first_name]', userData.firstName);
    formData.append('user[last_name]', userData.lastName);
    if (userData.password) formData.append('user[password]', userData.password);
    if (userData.password) formData.append('user[userData]', userData.passwordConfirmation);
    if (userData.lang) formData.append('user[lang]', userData.lang);
    formData.append('user[phone_number]', userData.phoneNumber || '');

    if (avatar?.size > 0) {
      formData.append('user[avatar]', avatar);
    }

    if (!avatar) {
      formData.append('remove_logo', true);
    }

    customFields.forEach((cf) => {
      if (cf.touched) {
        const params = {
          custom_field: {
            value: cf.value
          }
        };

        axios.patch(`/v1/clients/${cf.custom_fieldable_id}/custom_fields/${cf.id}`, params)
          .then(() => {})
          .catch(() => {});
      }
    });

    if (props.hasBookingProfile && profileCustomFields.length) {
      const profileData = new FormData();

      if (props.isInstructor) {
        profileCustomFields.forEach((cf, i) => {
          if (cf.id) profileData.append(`user[profile_template_fields_attributes][${i}][id]`, cf.id);
          profileData.append(`user[profile_template_fields_attributes][${i}][label]`, cf.label);
          profileData.append(`user[profile_template_fields_attributes][${i}][value]`, cf.value);
          // profileData.append(`user[profile_template_fields_attributes][${i}][category]`, cf.category);
          // profileData.append(`user[profile_template_fields_attributes][${i}][required]`, cf.required);
          profileData.append(`user[profile_template_fields_attributes][${i}][order_number]`, cf.order_number);
          profileData.append(`user[profile_template_fields_attributes][${i}][language]`, cf.language);
          if (cf._destroy) {
            profileData.append(`user[profile_template_fields_attributes][${i}][_destroy]`, cf._destroy);
          }
        });

        axios.patch(`/v1/users/${props.userId}/profile_template`, profileData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(() => {
            fetchUserData();
          })
          .catch(() => {});
      } else {
        profileCustomFields.forEach((cf, i) => {
          if (cf.id) profileData.append(`staff[profile_template_fields_attributes][${i}][id]`, cf.id);
          profileData.append(`staff[profile_template_fields_attributes][${i}][label]`, cf.label);
          profileData.append(`staff[profile_template_fields_attributes][${i}][value]`, cf.value);
          // profileData.append(`staff[profile_template_fields_attributes][${i}][category]`, cf.category);
          // profileData.append(`staff[profile_template_fields_attributes][${i}][required]`, cf.required);
          profileData.append(`staff[profile_template_fields_attributes][${i}][order_number]`, cf.order_number);
          profileData.append(`staff[profile_template_fields_attributes][${i}][language]`, cf.language);
          if (cf._destroy) {
            profileData.append(`staff[profile_template_fields_attributes][${i}][_destroy]`, cf._destroy);
          }
        });

        axios.patch(`/v1/staffs/${props.userId}`, profileData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(() => {
            fetchUserData();
          })
          .catch(() => {});
      }
    }

    axios.patch(`/v1/users/${props.userId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        const { email, firstName, lastName, lang, phoneNumber, savedAddresses } = response.data;
        setUserData((user) => ({ ...user, email, firstName, lastName, lang, phoneNumber, savedAddresses }));
        props.onUpdateSuccess(response.data);
        setAvatar(response.data.avatar || '');
        toggleToastActive(t('user.update_successful'));
        setShowSaveBar(false);
      })
      .catch((error) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: error.response.data.errors }]);
      })
      .then(() => {
        setShowSavedAddressModal(false);
        setLoading(false);
      });
  };

  const handleAddressEdit = (formData) => {
    const params = {
      user: formData
    };

    axios.patch(`/v1/users/${props.userId}`, params)
      .then((response) => {
        const { email, firstName, lastName, lang, phoneNumber, savedAddresses } = response.data;
        setUserData((user) => ({ ...user, email, firstName, lastName, lang, phoneNumber, savedAddresses }));
        setAvatar(response.data.avatar || '');
        toggleToastActive(t('user.update_successful'));
      })
      .catch((error) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: error.response.data.errors, exception: error.dig('response', 'data', 'exception') }]);
      })
      .then(() => {
        setShowSavedAddressModal(false);
      });
  };

  const fetchUserData = useCallback(() => {
    axios.get('/v1/users/info')
      .then((response) => {
        const { email, firstName, lastName, lang, phoneNumber, savedAddresses } = response.data;
        setUserData((user) => ({ ...user, email, firstName, lastName, lang, phoneNumber, savedAddresses }));
        setAvatar(response.data.avatar || '');
        setInit(true);
        setSelectedTags(response.data.selectedTags);
        setInitialTags(response.data.selectedTags);
        setProfileCustomFields(response.data.custom_fields || []);
      })
      .catch(() => {
        console.log('Can\'t load User');
      })
      .then(() => {
        setInitTags(true);
      });
  }, []);

  const fetchCustomFields = useCallback(() => {
    axios.get('/v1/users/custom_fields')
      .then((response) => {
        setCustomFields(response.data.custom_fields);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  useEffect(() => {
    fetchCustomFields();
  }, [fetchCustomFields]);

  const handleDeleteSavedAddress = (savedAddressToDelete) => {
    if (!window.confirm(`${t('shared.are_you_sure')} '${savedAddressToDelete.label}'?`)) {
      return;
    }

    handleAddressEdit({ saved_addresses_attributes: [{ id: savedAddressToDelete.id, _destroy: '1' }] });
  };

  const openAddressForm = (savedAddressToEdit) => {
    setSavedAddress(savedAddressToEdit);
    setShowSavedAddressModal(true);
  };

  const handleDropZoneDrop = useCallback((_dropFiles, acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      setBanner([{ title: t('shared.upload_avatar_validation'), status: 'critical' }]);
    } else {
      if (label === '') {
        setLabel(acceptedFiles[0].name);
      }
      setShowSaveBar(true);
      setAvatar(acceptedFiles[0]);
    }
  }, [label, t]);
  const resetAvatar = () => {
    setShowSaveBar(true);
    setAvatar('');
  };
  const uploadedFile = avatar && (
    <Stack alignment="center">
      {avatar.length ? <Thumbnail size="large" name="avatar" source={avatar} /> : ''}
      {label ? <div>{`${t('settings.selected_file')}: ${label}`}</div> : ''}
      <Button plain onClick={() => resetAvatar()}>{t('shared.change')}</Button>
    </Stack>
  );

  const savedAddressModal = showSavedAddressModal ? (
    <SavedAddressModalForm
      title={t('address.saved_addresses')}
      active={showSavedAddressModal}
      cleanup={() => setShowSavedAddressModal(false)}
      handleSubmit={handleAddressEdit}
      savedAddress={savedAddress}
    />
  ) : null;

  const handleCustomFieldChange = (idx) => (value) => {
    const temp = [...customFields];
    temp[idx].value = value;
    temp[idx].touched = true;
    setCustomFields(temp);
    setShowSaveBar(true);
  };

  const handleProfileCustomFieldChange = (idx) => (field) => (value) => {
    const temp = [...profileCustomFields];
    temp[idx][field] = value;

    setProfileCustomFields(temp);
    setShowSaveBar(true);
  };

  const displayCustomFields = () => customFields.map((customField, idx) => (
    <Card.Section key={idx}>
      <TextField
        label={customField.label}
        value={customField.value}
        disabled={!customField.editable_by_client}
        onChange={handleCustomFieldChange(idx)}
      />
    </Card.Section>
  ));

  const settingsForm = (
    <Form onSubmit={handleProfileEdit}>
      <Banners banners={banner} onDismissBanner={() => setBanner([])} />
      <FormLayout>
        <Layout>
          <Layout.AnnotatedSection
            title={t('shared.avatar')}
            description={t('settings.change_avatar')}
          >
            <Card sectioned>
              <Card.Section title={t('shared.avatar')}>
                {!avatar.length && !label ? (
                  <DropZone type="image" accept="image/*" allowMultiple={false} onDrop={handleDropZoneDrop}>
                    <DropZone.FileUpload />
                  </DropZone>
                ) : uploadedFile}
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title={t('settings.personal_information')}
            description={t('settings.change_personal_info')}
          >
            <Card sectioned>
              <Card.Section>
                <FormLayout>
                  <TextField label={t('user.first_name')} value={userData.firstName} onChange={handleChange('firstName')} />
                  <TextField label={t('user.last_name')} value={userData.lastName} onChange={handleChange('lastName')} />
                  <TextField label={t('address.phone_number')} value={userData.phoneNumber} onChange={handleChange('phoneNumber')} />
                  <Select
                    label={t('user.language')}
                    value={userData.lang}
                    onChange={handleChange('lang')}
                    options={languages}
                  />
                </FormLayout>
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
          {customFields.length ? (
            <Layout.AnnotatedSection
              title={t('custom_fields.layout_annotation')}
            >
              <Card sectioned>
                {displayCustomFields()}
              </Card>
            </Layout.AnnotatedSection>
          ) : null}
          <Layout.AnnotatedSection
            title={t('address.addresses')}
            description={t('address.manage')}
          >
            <Card>
              <Card.Section
                actions={[{ content: t('shared.add'), onAction: (() => { openAddressForm({}); }) }]}
              />
              {userData.savedAddresses.map((a, i) => (
                <Card.Section
                  title={(
                    <Stack>
                      <Stack.Item>
                        <TextStyle variation="strong">{a.label}</TextStyle>
                      </Stack.Item>
                      {a.preferred && <Badge status="info">{t('address.preferred')}</Badge>}
                    </Stack>
                  )}
                  actions={[
                    { content: t('shared.edit'), onAction: () => openAddressForm(a) },
                    { content: t('shared.delete'), onAction: () => handleDeleteSavedAddress(a) }
                  ]}
                  key={i}
                >
                  <div>{a.address?.attentionName}</div>
                  <div>{a.address?.address}</div>
                  <div>{a.address?.addressLineTwo}</div>
                  <div>{`${a.address?.city}, ${a.address?.state || ''} ${a.address?.zipCode}`}</div>
                </Card.Section>
              ))}
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title={t('user.password')}
            description={t('user.change_password')}
          >
            <Card sectioned>
              <Card.Section>
                <FormLayout>
                  <TextField
                    label={t('user.password')}
                    type="password"
                    value={userData.password}
                    onChange={handleChange('password')}
                    autoComplete="new-password"
                  />
                  <TextField
                    label={t('user.confirm_password')}
                    type="password"
                    value={userData.passwordConfirmation}
                    onChange={handleChange('passwordConfirmation')}
                    autoComplete="new-password"
                  />
                </FormLayout>
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
      </FormLayout>
    </Form>
  );

  const deleteCustomField = (id) => {
    const temp = [...profileCustomFields];
    temp[id]._destroy = '1';
    setProfileCustomFields([...temp]);
    setShowSaveBar(true);
  };

  const addCustomField = () => {
    setProfileCustomFields([...profileCustomFields, {
      language: customFieldLang,
      order_number: customFields.length
    }]);
  };

  const handleDragnDropChange = (items) => {
    setProfileCustomFields(items);
    setShowSaveBar(true);
  };

  const updateTags = useCallback(() => {
    setInitTags(false);

    const params = {
      user: {
        booking_tags_attributes: TagModifier(selectedTags, initialTags, customFieldLang)
      }
    };

    axios.patch(`/v1/users/${props.userId}/update_tags`, params)
      .then((response) => {
        setSelectedTags(response.data.selectedTags);
        setInitialTags(response.data.selectedTags);
        setInitTags(true);
      })
      .catch(() => {
      });
  // eslint-disable-next-line
  }, [props.userId, selectedTags, customFieldLang]);

  const tagDisplay = (
    <Tags
      language={customFieldLang}
      selectedTags={selectedTags}
      setSelectedTags={setSelectedTags}
      tagUrl={`/v1/users/${props.userId}/available_user_booking_tags`}
    />
  );

  const previousTags = usePrevious(selectedTags);

  useEffect(() => {
    if (initTags && JSON.stringify(previousTags) !== JSON.stringify(selectedTags)) {
      updateTags();
    }
  // eslint-disable-next-line
  }, [selectedTags]);

  return (
    <Page
      separator
    >
      {showSaveBar && (
        <ContextualSaveBar
          message={t('shared.unsaved_changes')}
          saveAction={{
            onAction: () => handleProfileEdit(),
            loading,
            disabled: false
          }}
          discardAction={{
            onAction: () => window.location.reload()
          }}
        />
      )}
      {savedAddressModal}
      {toastMarkup}
      <Tabs
        tabs={tabs}
        selected={getTabIndex(tabs, tableFilters.tab)}
        onSelect={(tabIndex) => onTabSelect({ history, location }, tabs, tabIndex, setTableFilters)}
      >
        {getTabId(tabs, tableFilters.tab) === 'profile' && (
          init && settingsForm
        )}
        {getTabId(tabs, tableFilters.tab) === 'bookingProfile' && props.hasBookingProfile && (
          <>
            <br />
            {!loading && (
              <StaffSectionBlocks
                customFields={profileCustomFields || []}
                handleCustomFieldChange={handleProfileCustomFieldChange}
                deleteCustomField={deleteCustomField}
                addCustomField={addCustomField}
                error={customFieldError}
                tagDisplay={tagDisplay}
                setError={setCustomFieldError}
                setCustomFieldLang={setCustomFieldLang}
                customFieldLang={customFieldLang}
                setCustomFields={handleDragnDropChange}
                title={t('custom_fields.plural')}
              />
            )}
          </>
        )}
      </Tabs>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  userId: state.auth.token,
  isClient: state.auth.client,
  lang: state.auth.lang,
  isInstructor: state.auth.instructor,
  hasBookingProfile: state.auth.role === 'staff' || state.auth.instructor
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateSuccess: (data) => dispatch(actions.authSuccess(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
