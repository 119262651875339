/* eslint-disable max-len */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AvailabilityModal from './AvailabilityModal';

const AvailabilityTable = (props) => {
  const [open, setOpen] = useState(false);
  const [selection, setSelection] = useState({});
  const matchParams = useParams();

  const dateConverter = (schedule, date) => {
    if (schedule.custom_schedule) {
      let json = [];

      if (typeof schedule.custom_schedule === 'string') {
        json = JSON.parse(schedule.custom_schedule);
      } else if (Array.isArray(schedule.custom_schedule)) {
        json = schedule.custom_schedule;
      } else {
        json = [];
      }

      const matches = [];

      json.forEach((entry) => {
        if (entry.day === date) {
          matches.push(entry);
        }
      });

      return matches.map((match) => (
        <>
          {`${match.start} - ${match.end}`}
          <br />
        </>
      ));
    }

    return '-';
  };

  const openEdit = (select) => {
    setSelection(select);
  };

  useEffect(() => {
    if (Object.keys(selection).length) {
      setOpen(true);
    }
  }, [selection]);

  const cleanup = () => {
    setSelection({});
    setOpen(false);
  };

  const handleSubmit = (data) => {
    const params = {
      data
    };

    if ('assigned_staff_id' in selection) {
      params.assigned_staff_id = selection.assigned_staff_id;

      axios.post(`/v1/products/${matchParams.token}/update_staff_availability`, params)
        .then(() => {
          props.refetch();
        })
        .catch(() => {
        });
    } else if ('product_token' in selection) {
      const formData = new FormData();

      if (Array.isArray(data)) {
        formData.append('product[schedule]', JSON.stringify(data) || '');
      } else {
        formData.append('product[schedule]', data || '');
      }

      axios.patch(`/v1/products/${matchParams.token}`, formData)
        .then(() => {
          props.refetch();
        })
        .catch(() => {
        });
    }
  };

  const scheduleConverter = (schedule) => {
    if (schedule && typeof schedule === 'string') {
      return JSON.parse(schedule);
    }

    return schedule;
  };

  let availabilityModal = null;

  if (open) {
    availabilityModal = (
      <AvailabilityModal
        title={selection.name}
        data={scheduleConverter(selection.custom_schedule)}
        handleSubmit={handleSubmit}
        close={() => cleanup()}
      />
    );
  }

  return (
    <>
      {availabilityModal}
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Monday
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Tuesday
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Wednesday
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Thursday
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Friday
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Saturday
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Sunday
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.data.map((day, i) => (
                    <tr key={i} className={i % 2 === 0 ? 'bg-white hover:bg-gray-100' : 'bg-gray-50 hover:bg-gray-100'}>
                      <td className="px-6 py-4 whitespace-nowrap text-xs font-medium text-gray-900">{day.name}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500">{dateConverter(day, '0')}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500">{dateConverter(day, '1')}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500">{dateConverter(day, '2')}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500">{dateConverter(day, '3')}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500">{dateConverter(day, '4')}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500">{dateConverter(day, '5')}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500">{dateConverter(day, '6')}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-xs font-medium">
                        <button
                          type="button"
                          className="inline-flex items-center px-3 py-1 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => openEdit(props.data[i])}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AvailabilityTable;
