/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Filters, Card, Toast, Layout, Button, IndexTable, useIndexResourceState, Page } from '@shopify/polaris';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import useDebounce from '../../hooks/useDebounce';
import { stateConverter } from '../FilterEngine/filterParams';
import { handleFilterChange } from '../FilterEngine/FilterEngine';
import TablePagination from '../FilterEngine/TablePagination/TablePagination';
import NotificationBanner from '../Shared/NotificationBanner/NotificationBanner';
import AddInstructorModal from './AddInstructorModal';

const InstructorAssignment = (props) => {
  const [showAddInstructor, setShowAddInstructor] = useState(false);
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(true);
  const [products, setInstructors] = useState([]);
  const [lastPage, setLastPage] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [toastActive, setToastActive] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const { t } = useTranslation();
  const location = useLocation();
  const matchParams = useParams();
  const history = useNavigate();

  const [tableFilters, setTableFilters] = useState(stateConverter(location));
  const debouncedSearch = useDebounce(tableFilters, 400);

  const fetchProduct = useCallback(() => {
    const params = {
      per_page: 30,
      page: tableFilters.page,
      search: tableFilters.queryValue,
      q: '',
      s: { sorts: tableFilters.sorts }
    };

    axios.post(`/v1/public_profiles/${props.publicProfile.url_name}/search_assigned_instructors?program_space=${props.publicProfile.program_space}`, params)
      .then((res) => {
        setLastPage(res.data.lastPage);
        setInstructors(res.data.instructors);
        setSelectedProducts(res.data.instructor_tokens);
        setLoading(false);
      })
      .catch((err) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: err.response }]);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [debouncedSearch]);

  const filters = [];

  const placeHolderText = t('filter.title_description');

  const filterControl = (
    <Filters
      queryValue={tableFilters.queryValue}
      filters={filters}
      onQueryChange={handleFilterChange({ history, location }, setTableFilters, 'queryValue')}
      onQueryClear={handleFilterChange({ history, location }, setTableFilters, 'queryValue', 'reset', '')}
      onClearAll={() => {}}
      appliedFilters={[]}
      queryPlaceholder={placeHolderText}
    />
  );

  const handleProductUnassign = () => {
    const params = {
      ids: selectedResources
    };

    axios.post(`/v1/public_profiles/${props.publicProfile.url_name}/unassign_instructor?program_space=${props.publicProfile.program_space}`, params)
      .then(() => {
        toggleToastActive(t('shared.success'));
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
        fetchProduct();
      })
      .catch(() => {
        toggleToastActive(t('shared.something_went_wrong'));
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
      });
  };

  useEffect(() => {
    fetchProduct();
  }, [fetchProduct]);

  const headings = [
    { title: 'Name' },
    { title: 'E-Mail' }
  ];

  const resourceName = {
    singular: 'Instructor',
    plural: 'Instructors'
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange
  } = useIndexResourceState(products);

  const rowMarkup = products.map(({ id, token, name, email }, index) => (
    <IndexTable.Row
      id={id}
      key={id}
      selected={selectedResources?.includes(id)}
      position={index}
    >
      <IndexTable.Cell>
        <Button plain url={`/staffs/${token}`}>{name}</Button>
      </IndexTable.Cell>
      <IndexTable.Cell>
        {email}
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  const promotedBulkActions = [
    {
      content: t('products.unassign'),
      onAction: () => handleProductUnassign()
    }
  ];

  // eslint-disable-next-line no-unused-vars
  const content = (
    <IndexTable
      loading={loading}
      resourceName={resourceName}
      headings={headings}
      rows={products}
      itemCount={products.length}
      selectedItemsCount={
        allResourcesSelected ? 'All' : selectedResources.length
      }
      onSelectionChange={handleSelectionChange}
      promotedBulkActions={promotedBulkActions}
    >
      {rowMarkup}
    </IndexTable>
  );

  let instructorModal = null;

  if (showAddInstructor) {
    instructorModal = (
      <AddInstructorModal
        selectedProducts={selectedProducts}
        title="Manage instructors"
        handleSubmit={() => fetchProduct()}
        publicProfile={props.publicProfile}
        close={() => setShowAddInstructor(false)}
      />
    );
  }

  return (
    <Page fullWidth>
      {instructorModal}
      {toastMarkup}
      <Layout>
        <Layout.Section>
          <NotificationBanner label="Assigned instructors" buttonLabel="Edit" onClick={() => setShowAddInstructor(true)} />
          <Card sectioned>
            <Card.Subsection>
              {filterControl}
            </Card.Subsection>
            <Card.Subsection>
              {content}
            </Card.Subsection>
          </Card>
          <TablePagination
            pageFilter={tableFilters.page}
            setTableFilters={setTableFilters}
            records={products}
            lastPage={lastPage}
          />
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default InstructorAssignment;
