import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CircleTickMajor, CircleCancelMajor, PlusMinor } from '@shopify/polaris-icons';
import { Avatar, Filters, Card, Loading, Page, SkeletonPage, Button, Icon, useIndexResourceState, IndexTable, Toast } from '@shopify/polaris';
import { useLocation, useNavigate } from 'react-router-dom';
import Banners from '../Shared/Banners';
import NewClientModal from './NewClientModal';
import useDebounce from '../../hooks/useDebounce';
import { stateConverter } from '../FilterEngine/filterParams';
import { appliedFiltersQuery, handleFilterChange } from '../FilterEngine/FilterEngine';
import TablePagination from '../FilterEngine/TablePagination/TablePagination';
import Badges from '../Shared/Badges';
import Download from '../Shared/Download';
import PnbStats from '../Tailwind/PnbStats';
import stateFilterActive from '../FilterEngine/State/stateFilterActive';

const Clients = (props) => {
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState([]);
  const [lastPage, setLastPage] = useState(null);
  const [stats, setStats] = useState([]);
  const [showNewClientModal, setShowNewClientModal] = useState(false);
  const [mounted, setMounted] = useState(true);

  const { t } = useTranslation();
  const location = useLocation();
  const history = useNavigate();

  const [tableFilters, setTableFilters] = useState(stateConverter(location, [{ key: 'state', value: [props.isInstructor ? 'validated' : ''] }]));
  const debouncedSearch = useDebounce(tableFilters, 400);

  const [toastActive, setToastActive] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const appliedFilters = useCallback(() => {
    const af = [];

    if (tableFilters.state) af.push(stateFilterActive(t, { history, location }, setTableFilters, tableFilters.state));

    return af;
  // eslint-disable-next-line
  }, [t, tableFilters.state]);

  const fetchClients = useCallback(() => {
    const params = {
      per_page: 30,
      page: tableFilters.page,
      search: tableFilters.queryValue,
      q: appliedFiltersQuery(appliedFilters()),
      s: { sorts: tableFilters.sorts }
    };

    axios.post('/v1/clients/search', params)
      .then((res) => {
        if (mounted) {
          setClients(res.data.clients);
          setLastPage(res.data.lastPage);
          setLoading(false);
          setStats(res.data.stats);
        }
      })
      .catch(() => {
        // setBanner([{ title: t('client.cannot_get_list'), status: 'critical', details: err.response.data.errors }]);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [debouncedSearch]);

  const filters = [];

  const placeHolderText = t('client.filter');

  const filterControl = (
    <Filters
      queryValue={tableFilters.queryValue}
      filters={filters}
      onQueryChange={handleFilterChange({ history, location }, setTableFilters, 'queryValue')}
      onQueryClear={handleFilterChange({ history, location }, setTableFilters, 'queryValue', 'reset', '')}
      onClearAll={() => {}}
      appliedFilters={[]}
      queryPlaceholder={placeHolderText}
    />
  );

  const handleNew = (client) => {
    axios.post('/v1/clients', client)
      .then(() => {
        if (mounted) {
          fetchClients();
        }
        setBanner([{ title: t('client.added'), status: 'success' }]);
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
      })
      .catch((err) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: err.response.data.errors }]);
      });
  };

  const exportSessionHistories = () => {
    axios.get('/v1/clients/export_session_histories', { responseType: 'blob' })
      .then((response) => {
        Download(response);
      })
      .catch(() => {});
  };

  const resourceName = {
    singular: 'Client',
    plural: 'Clients'
  };

  const handleStatusToggle = (type) => {
    const params = {
      ids: selectedResources
    };

    axios.post(`/v1/clients/${type}`, params)
      .then(() => {
        toggleToastActive(t('shared.success'));
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
        fetchClients();
      })
      .catch(() => {
        toggleToastActive(t('shared.something_went_wrong'));
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
      });
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange
  } = useIndexResourceState(clients);

  const headings = [
    { title: '' },
    { title: t('user.name') },
    { title: t('user.email') },
    { title: t('user.active') },
    { title: 'Tags' },
    { title: '' }
  ];

  const rowMarkup = clients.map(({ token, name, avatar, email, active, tags }, index) => (
    <IndexTable.Row
      id={token}
      key={token}
      selected={selectedResources?.includes(token)}
      position={index}
    >
      <IndexTable.Cell>
        <Avatar size="small" name="client_logo" source={avatar} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Button plain url={`/clients/${token}`}>{name}</Button>
      </IndexTable.Cell>
      <IndexTable.Cell>
        {email}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {active
          ? <Icon backdrop source={CircleTickMajor} color="success" />
          : <Icon backdrop source={CircleCancelMajor} color="critical" />}
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Badges data={tags} />
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  const promotedBulkActions = [
    {
      content: 'New application',
      onAction: () => handleStatusToggle('new_application')
    },
    {
      content: 'Pending',
      onAction: () => handleStatusToggle('pending')
    },
    {
      content: 'Complete',
      onAction: () => handleStatusToggle('validate')
    },
    {
      content: 'Decline',
      onAction: () => handleStatusToggle('decline')
    }
  ];

  const content = (
    <IndexTable
      resourceName={resourceName}
      headings={headings}
      rows={clients}
      itemCount={clients.length}
      selectedItemsCount={
        allResourcesSelected ? 'All' : selectedResources.length
      }
      onSelectionChange={handleSelectionChange}
      promotedBulkActions={props.isInstructor ? promotedBulkActions : []}
      selectable={props.isInstructor}
    >
      {rowMarkup}
    </IndexTable>
  );

  useEffect(() => {
    setMounted(true);
    fetchClients();
    return () => {
      setMounted(false);
    };
  }, [fetchClients]);

  const dismissBanner = () => {
    setBanner([]);
  };

  let newClientForm = null;

  if (showNewClientModal) {
    newClientForm = (
      <NewClientModal
        title={t('client.add_new_client')}
        handleSubmit={handleNew}
        close={() => setShowNewClientModal(false)}
      />
    );
  }

  let loadingContainer = null;
  if (loading) {
    loadingContainer = <Loading />;
  }

  const pnbStatsFilterChange = (action) => {
    if (!action) {
      handleFilterChange({ history, location }, setTableFilters, 'state', 'reset', '')('', '');
    } else {
      handleFilterChange({ history, location }, setTableFilters, 'state')([action]);
    }
  };

  return (
    <Page
      title={t('client.clients')}
      fullWidth
      primaryAction={{ icon: PlusMinor, content: t('client.add_new_client'), onAction: () => setShowNewClientModal(true) }}
      secondaryActions={[{
        content: t('client.download_sessions'),
        onAction: () => exportSessionHistories()
      }]}
      separator
    >
      {toastMarkup}
      <Banners banners={banner} onDismissBanner={dismissBanner} />
      {loadingContainer}
      {newClientForm}
      {props.isInstructor ? <PnbStats stats={stats} onClick={pnbStatsFilterChange} activeFilter={tableFilters.state?.[0]} /> : ''}
      {!loading ? (
        <Card sectioned>
          <Card.Subsection>
            {filterControl}
          </Card.Subsection>
          <Card.Subsection>
            {content}
          </Card.Subsection>
        </Card>
      ) : <SkeletonPage />}
      <TablePagination
        pageFilter={tableFilters.page}
        setTableFilters={setTableFilters}
        records={clients}
        lastPage={lastPage}
        length={30}
      />
    </Page>
  );
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.admin,
  isInstructor: state.auth.instructor
});

export default connect(mapStateToProps)(Clients);
