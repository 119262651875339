/* eslint-disable */
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Toast, Button, Page, Layout, TextField } from '@shopify/polaris';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { DeleteMinor } from '@shopify/polaris-icons';
import moment from 'moment';
import { BanknotesIcon, CodeBracketIcon } from '@heroicons/react/20/solid';
import { ClockIcon, PencilSquareIcon, CurrencyDollarIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const GiftCardTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [giftCard, setGiftCard] = useState({});
  const [toastActive, setToastActive] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [newExpiresAt, setNewExpiresAt] = useState('');
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);
  const [showExpiresAtEdit, setShowExpiresAtEdit] = useState(false);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const { t } = useTranslation();
  const matchParams = useParams();
  const history = useNavigate();

  const handleCurrentValueChange = (val) => {
    setGiftCard({ ...giftCard, real_current_value: val });
  }

  const fetchGiftCardTransactions = useCallback(() => {
    axios.get(`/v1/gift_cards/${matchParams.token}/gift_card_transactions`)
      .then((res) => {
        setTransactions(res.data.gift_card_transactions || []);
        setGiftCard(res.data.gift_card);
      })
      .catch(() => {
        toggleToastActive(t('shared.something_went_wrong'));
      });
    // eslint-disable-next-line
  }, []);

  const updateGiftCard = () => {
    const params = {
      gift_card: {
        expires_at: newExpiresAt || giftCard.expires_at,
        current_value: giftCard.real_current_value
      }
    };

    axios.patch(`/v1/gift_cards/${matchParams.token}`, params)
      .then(() => {
        fetchGiftCardTransactions();
        toggleExpiresAtEdit();
      })
      .catch(() => {
        toggleToastActive(t('shared.something_went_wrong'));
      });
    // eslint-disable-next-line
  };

  useEffect(() => {
    fetchGiftCardTransactions();
  }, [fetchGiftCardTransactions]);

  const downloadInvoice = (id) => {
    axios.get(`/v1/invoices/${id}/download`)
      .then((response) => {
        window.open(response.data.url);
      })
      .catch(() => {
        toggleToastActive(t('shared.something_went_wrong'));
      });
  };

  const deleteTransaction = (id) => {
    if (!window.confirm(t('shared.are_you_sure'))) {
      return;
    }

    axios.post(`/v1/gift_cards/${matchParams.token}/delete_gift_card_transactions`, { gc_token: id })
      .then(() => {
        toggleToastActive(t('shared.success'));
        fetchGiftCardTransactions();
      })
      .catch(() => {
        toggleToastActive(t('shared.something_went_wrong'));
      });
  };

  const toggleExpiresAtEdit = () => {
    setShowExpiresAtEdit(!showExpiresAtEdit);
  };

  const handleExpiresAtChange = (expiresAt) => {
    setNewExpiresAt(expiresAt);
  };

  const handleSave = () => {
    updateGiftCard();
  };

  return (
    <Page
      title="Gift Card Transactions"
      separator
      breadcrumbs={[{ content: t('shared.back'), onAction: () => history(-1) }]}
    >
      {toastMarkup}
      <Layout>
        <Layout.Section oneHalf>
          <div className="flow-root rounded-lg shadow-sm ring-1 bg-white ring-gray-900/5">
            {!transactions.length ? (
              <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
                <div className="text-center">
                  <p className="text-base font-semibold text-indigo-600">404</p>
                  <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">No transactions</h1>
                  <p className="mt-6 text-base leading-7 text-gray-600">There have been no transactions yet</p>
                </div>
              </main>
            ) : (
              <ul className="-mb-8 max-w-2xl bg-white p-4 rounded-lg shadow-sm ring-1 ring-gray-900/5">
                {transactions.map((event, eventIdx) => (
                  <li key={event.id}>
                    <div className="relative pb-8">
                      {eventIdx !== transactions.length - 1 ? (
                        <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                      ) : null}
                      <div className="relative flex space-x-3">
                        <div>
                          <span
                            className={classNames(
                              event.iconBackground,
                              'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                            )}
                          >
                            <BanknotesIcon className="h-5 w-5 text-white" aria-hidden="true" />
                          </span>
                        </div>
                        <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                          <div>
                            <p className="text-sm text-gray-500">
                              <Button plain onClick={() => downloadInvoice(event.invoice_token)}>{event.pretty_invoice_number}</Button>
                              {' '}
                            </p>
                            <div className="whitespace-nowrap text-right text-sm text-gray-500">
                              <time dateTime={event.created_at}>{moment(event.created_at).format('LLLL')}</time>
                            </div>
                          </div>
                          <div className="whitespace-nowrap text-right text-sm text-gray-500">
                            {event.amount}
                            {' '}
                            <Button icon={DeleteMinor} destructive onClick={() => deleteTransaction(event.token)} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </Layout.Section>
        <Layout.Section oneHalf>
          <div className="lg:col-start-3 lg:row-end-1">
            <h2 className="sr-only">Summary</h2>
            <div className="rounded-lg bg-white shadow-sm ring-1 ring-gray-900/5 p-4">
              <dl className="flex flex-wrap">
                <div className="flex-auto pl-6 pt-6">
                  <dt className="text-sm font-semibold leading-6 text-gray-900">Remaining amount</dt>
                  <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">{giftCard.current_value}</dd>
                </div>
                <PencilSquareIcon
                  className="h-5 w-5 cursor-pointer"
                  onClick={() => toggleExpiresAtEdit()}
                />
                <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                  <dt className="flex">
                    <span className="sr-only">Code</span>
                    <CodeBracketIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </dt>
                  <div className="flex-1">Code</div>
                  <dd className="text-sm font-medium leading-6 text-gray-900">{giftCard.code}</dd>
                </div>
                {showExpiresAtEdit ? (
                  <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                    <dt className="flex">
                      <span className="sr-only">Current value</span>
                      <CurrencyDollarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </dt>
                    <div className="flex-1">Current value</div>
                    <dd className="text-sm font-medium leading-6 text-gray-900"><TextField value={giftCard.real_current_value} onChange={handleCurrentValueChange} /></dd>
                  </div>
                ) : ''}
                {giftCard.expires_at || showExpiresAtEdit ? (
                  <div className="mt-4 flex w-full flex-none gap-x-4 px-6 align-middle">
                    <dt className="flex text-center align-middle">
                      <span className="sr-only">Expires at</span>
                      <ClockIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </dt>
                    <div className="flex-1">Expires at</div>
                    <div className="flex-none self-end">
                      <dt className="sr-only">Expires at</dt>
                      {showExpiresAtEdit ? (
                        <div>
                          <TextField type="date" value={newExpiresAt} onChange={handleExpiresAtChange} />
                          <button
                            type="button"
                            onClick={handleSave}
                            className="mt-2 rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Save
                          </button>
                        </div>
                      ) : (
                        <dd onClick={toggleExpiresAtEdit} className={`inline-flex items-center rounded-md ${moment(giftCard.expires_at).isBefore() ? 'bg-red-50 text-red-700 ring-red-600/20' : 'bg-green-50 text-green-700 ring-green-600/20'} px-2 py-1 text-xs font-medium ring-1 ring-inset`}>
                          <time dateTime={giftCard.expires_at}>{moment(giftCard.expires_at).format('LL')}</time>
                        </dd>
                      )}
                    </div>
                  </div>
                ) : ''}
              </dl>
            </div>
          </div>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default GiftCardTransactions;
