/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { Button, Card, EmptyState, Heading, Layout, Stack, TextStyle, Spinner, FormLayout } from '@shopify/polaris';
import { ArrowLeftMinor, SlideshowMajor } from '@shopify/polaris-icons';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import styles from './PublicProduct.module.scss';
import BookNowModal from './BookNowModal';
import LoginModal from './LoginModal';
import BookingPage from './BookingPage';
import Banners from '../Shared/Banners';
import BookingAvatar from './BookingAvatar';
import ProhibitedPage from './ProhibitedPage';
import StackList from '../Shared/StackList/StackList';
import Accordion from '../Accordion/Accordion';
import TimezoneSelect from '../Shared/TimezoneSelect/TimezoneSelect';
import DatePicker from '../Shared/DatePicker/DatePicker';
import useDebounce from '../../hooks/useDebounce';
import * as actions from '../../store/actions/index';
import Stepper from '../Shared/Stepper';

const PublicProduct = (props) => {
  const currentDate = new Date();
  const yesterday = new Date(currentDate);
  yesterday.setDate(yesterday.getDate() - 1);

  const [prohibited, setProhibited] = useState(false);
  const [slotLoading, setSlotLoading] = useState(true);
  const [timeSlotsMonth, setTimeSlotsMonth] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [banner, setBanner] = useState([]);
  const [timezone, setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [showBookNowModal, setShowBookNowModal] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState('');
  const [product, setProduct] = useState({
    product_settings: {},
    form_data: []
  });
  const [timeSlots, setTimeSlots] = useState([]);
  const [{ month, year }, setDate] = useState({ month: currentDate.getMonth(), year: currentDate.getFullYear() });
  const [selectedDates, setSelectedDates] = useState({
    start: currentDate,
    end: currentDate
  });

  const { t } = useTranslation();
  const [booking, setBooking] = useState({});
  const location = useLocation();
  const history = useNavigate();
  const matchParams = useParams();

  let queryParams = new URLSearchParams(location.search);
  const hideNav = queryParams.get('hideNav');

  const [token] = useState(props.token || matchParams.token);

  const getTimeZone = () => (typeof timezone === 'string' ? timezone : timezone.value);

  useEffect(() => {
    props.onTryAutoSignin();
  // eslint-disable-next-line
  }, [props.onTryAutoSignin]);

  if (timezone) {
    moment.tz.setDefault(getTimeZone());
  }

  const handleMonthChange = (m, y) => {
    setDate({ month: m, year: y });
  };

  const fetchProduct = useCallback(() => {
    setProhibited(false);
    setLoading(true);
    axios.get(`/v1/products/${token}`)
      .then((res) => {
        setProduct(res.data);
      })
      .catch((error) => {
        if (error.response?.data?.error?.message === 'Access denied') {
          setProhibited(true);
        }
      })
      .then(() => {
        setLoading(false);
      });
  }, [token]);

  useEffect(() => {
    fetchProduct();
  }, [fetchProduct]);

  const debouncedSearch = useDebounce(month, 400);

  const getTimeSlotsMonth = useCallback(() => {
    setSlotLoading(true);

    const params = {
      year,
      month: month + 1,
      day: selectedDates.start.getDate(),
      profileToken: props.profileToken,
      public_profile_token: props.publicProfile.token,
      public_profile_program_space: props.publicProfile.program_space
    };

    axios.post(`/v1/products/${token}/time_slots_month`, params)
      .then((res) => {
        setTimeSlotsMonth(res.data.time_slots_month);
      })
      .catch(() => {
      })
      .then(() => {
        setSlotLoading(false);
      });
  // eslint-disable-next-line
  }, [debouncedSearch]);

  const getTimeSlots = useCallback(() => {
    setSlotLoading(true);

    const params = {
      year: selectedDates.start.getFullYear(),
      month: selectedDates.start.getMonth() + 1,
      day: selectedDates.start.getDate(),
      profileToken: props.profileToken,
      public_profile_token: props.publicProfile.token,
      public_profile_program_space: props.publicProfile.program_space
    };

    axios.post(`/v1/products/${token}/time_slots`, params)
      .then((res) => {
        setTimeSlots(res.data.slots);
      })
      .catch(() => {
      })
      .then(() => {
        setSlotLoading(false);
      });
  }, [selectedDates.start, props.profileToken, props.publicProfile.token, props.publicProfile.program_space, token]);

  useEffect(() => {
    getTimeSlotsMonth();
  }, [getTimeSlotsMonth]);

  useEffect(() => {
    getTimeSlots();
  }, [getTimeSlots]);

  const cleanup = () => {
    setBanner([]);
    setShowBookNowModal(false);
    setShowLoginModal(false);
  };

  const handleSubmit = (client) => {
    setSlotLoading(true);

    const params = {
      year: selectedDates.start.getFullYear(),
      month: selectedDates.start.getMonth() + 1,
      day: selectedDates.start.getDate(),
      email: client.email,
      first_name: client.first_name,
      last_name: client.last_name,
      user_token: selectedSlot.user_token,
      slot: selectedTimeSlot,
      product_booking_custom_fields: client.product_booking_custom_fields,
      gift_card_code: client.code,
      public_profile_token: props.publicProfile.token,
      public_profile_program_space: props.publicProfile.program_space,
      form_data_reply: client.form_data_reply,
      paymentMethod: client.paymentMethod,
      eventPackage: client.eventPackage
    };

    axios.post(`/v1/products/${token}/book`, params)
      .then((response) => {
        localStorage.setItem('wasLoggedIn', 'true');
        if (!response.data.session_id) {
          setBooking(response.data);
          cleanup();
        } else if (response.data.paid) {
          // setPaid(true);
        } else {
          window.location.replace(response.data.session_id);
        }
      })
      .catch((error) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: error.response.data.errors }]);
      })
      .then(() => {
        localStorage.setItem('wasLoggedIn', 'true');
        setSlotLoading(false);
      });
  };

  let loginModalForm = null;

  if (showLoginModal) {
    loginModalForm = (
      <LoginModal
        close={() => cleanup()}
      />
    );
  }

  const getDisabledStartDate = () => {
    const dd = yesterday;
    let startDate = '';
    let dateToReturn = '';

    if (product.product_settings.cutoff_days) {
      dd.setDate(dd.getDate() + parseInt(product.product_settings.cutoff_days, 10));
    }

    if (product.product_settings.cutoff_hours) {
      dd.setHours(dd.getHours() + parseInt(product.product_settings.cutoff_hours, 10));
    }

    if (product.product_settings.cutoff_minutes) {
      dd.setMinutes(dd.getMinutes() + parseInt(product.product_settings.cutoff_minutes, 10));
    }

    if (product.product_settings.start_date) {
      startDate = new Date(product.product_settings.start_date);
      startDate.setDate(startDate.getDate() - 1);
    }

    if (dd && startDate) {
      dateToReturn = dd > startDate ? dd : startDate;
    } else if (dd && !startDate) {
      dateToReturn = dd;
    } else if (!dd && startDate) {
      dateToReturn = startDate;
    }

    return dateToReturn;
  };

  const getDisabledDate = () => {
    let dd = '';
    let endDate = '';
    let dateToReturn = '';

    if (!product.product_settings.book_days_in_the_future && !product.product_settings.end_date) return '';

    if (product.product_settings.book_days_in_the_future) {
      dd = new Date();
      dd.setDate(dd.getDate() + parseInt(product.product_settings.book_days_in_the_future, 10));
    }

    if (product.product_settings.end_date) {
      endDate = new Date(product.product_settings.end_date);
    }

    if (dd && endDate) {
      dateToReturn = dd > endDate ? endDate : dd;
    } else if (dd && !endDate) {
      dateToReturn = dd;
    } else if (!dd && endDate) {
      dateToReturn = endDate;
    }

    return dateToReturn;
  };

  const displaySlots = () => {
    if (!timeSlots.map((slot) => slot.availability).flat().length) {
      return (
        <EmptyState
          heading={t('product.no_time_slots_available')}
          image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
        >
          <p>{t('product.time_slot_select_another_one')}</p>
        </EmptyState>
      );
    }

    return timeSlots.map((slot) => {
      if (slot.availability.length === 0) return '';

      const slots = slot.availability.map((availability, ii) => (
        <div className={styles['flex-item']} key={ii}>
          <Button
            fullWidth
            onClick={() => {
              setSelectedSlot(slot);
              setSelectedTimeSlot(availability);
              setShowBookNowModal(true);
            }}
          >
            {moment(availability).format(props.lang === 'en' ? 'hh:mm A' : 'HH:mm')}
          </Button>
        </div>
      ));

      if (props.type === 'profile') {
        return <div className="max-h-96 overflow-auto">{slots}</div>;
      }

      return (
        <Accordion title={slot.user_name} content={slots} open={timeSlots.length === 1} />
      );
    });
  };

  const goHome = () => {
    if (props.type === 'profile') {
      props.setIndividual({});
    }

    props.setSelected('');
    props.hideSearch(false);
    queryParams = new URLSearchParams(location.search);
    queryParams.delete('profile_token');
    const productOnly = queryParams.get('productOnly');

    if (productOnly) {
      history(`/booking/${matchParams.name}?tab=booking&productOnly=true`);
    } else {
      history(`/booking/${matchParams.name}?tab=booking`);
    }
  };

  const goBackChange = () => {
    if (props.type === 'profile') {
      props.setSelected({});
      return;
    }

    props.setSelected('');
    setSelectedTimeSlot(null);
    props.hideSearch(false);
    queryParams = new URLSearchParams(location.search);
    queryParams.delete('token');
    const productOnly = queryParams.get('productOnly');

    if (productOnly) {
      history(`/booking/${matchParams.name}?tab=booking&productOnly=true`);
    } else {
      history(`/booking/${matchParams.name}?tab=booking`);
    }
  };

  const displayOneTimeEvent = () => {
    const items = [
      {
        term: t('shared.start'),
        description: moment(product.p_start_time).format('LLLL')
      },
      {
        term: t('shared.end'),
        description: moment(product.p_end_time).format('LLLL')
      }
    ];

    if (product.pretty_price) {
      items.push({
        term: t('shared.price'),
        description: product.pretty_price
      });
    }

    return <StackList items={items} />;
  };

  const confirmpage = (
    <BookNowModal
      selectedSlot={selectedSlot}
      title={product.title}
      price={product.pretty_price}
      product={product}
      timeSlot={moment(selectedTimeSlot).format('LLL')}
      duration={`${product.duration} ${t('shared.minutes_lowercase')}`}
      customFields={product.custom_fields}
      handleSubmit={(client) => handleSubmit(client)}
      close={() => cleanup()}
      isLoading={slotLoading}
    />
  );

  const nav = (
    <nav className="flex mb-8 pb-4 border-b items-center" aria-label="Breadcrumb">
      {hideNav !== 'true' ? (
        <ol className="flex flex-1 items-center space-x-4">
          <li>
            <button type="button" className="text-gray-400 hover:text-gray-500" onClick={() => goHome()}>
              <HomeIcon className="flex-shrink-0 h-6 w-6" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </button>
          </li>
          {props.individual?.name && (
            <li>
              <button type="button" onClick={() => goBackChange()} className="flex items-center font-medium text-md">
                <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400 mr-4" aria-hidden="true" />
                {props.individual?.name}
              </button>
            </li>
          )}
          <li>
            <button type="button" onClick={() => { if (showBookNowModal) setShowBookNowModal(false); }} className="flex items-center font-medium text-md">
              <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400 mr-4" aria-hidden="true" />
              {product.title}
            </button>
          </li>
          {showBookNowModal && (
            <li>
              <button type="button" className="flex items-center font-medium text-md">
                <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400 mr-4" aria-hidden="true" />
                Checkout
              </button>
            </li>
          )}
        </ol>
      ) : (
        <div className="flex flex-1 font-medium text-xl">
          {product.title}
        </div>
      )}
      {props.user.token ? (
        <BookingAvatar />
      ) : (
        <Button plain onClick={() => setShowLoginModal(true)}>{t('product.login')}</Button>
      )}
      {/* {(product.one_time_event) && (
        <div className="ml-2">
          <Button primary onClick={() => setShowBookNowModal(true)}>{t('product.book_now')}</Button>
        </div>
      )} */}
    </nav>
  );

  const showConfirmPage = () => {
    if (showBookNowModal) {
      return true;
    }

    if (product.one_time_event) {
      return true;
    }

    if (product.type === 'Product::LessonPackage') {
      return true;
    }

    return false;
  };

  const productpage = (
    !Object.keys(booking).length ? (
      showConfirmPage() ? (
        <div>
          {nav}
          {confirmpage}
        </div>
      ) : (
        <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '1100px' }}>
          {nav}
          <DatePicker
            month={month}
            year={year}
            timeSlotsMonth={timeSlotsMonth}
            displaySlots={displaySlots}
            onChange={setSelectedDates}
            isLoading={slotLoading}
            onMonthChange={handleMonthChange}
            selected={selectedDates}
            timezoneSelect={(
              <TimezoneSelect
                value={timezone}
                onChange={setTimezone}
              />
            )}
            disableDatesBefore={getDisabledStartDate()}
            disableDatesAfter={getDisabledDate()}
          />
          {product.description && (
            <div className="mt-4">
              <Card sectioned>
                <div dangerouslySetInnerHTML={{ __html: product.description }} />
              </Card>
            </div>
          )}
        </div>
      )
    ) : (
      <FormLayout>
        <BookingPage booking={booking} setBooking={setBooking} />
      </FormLayout>
    )
  );

  return (
    <div className="bg-white">
      {loginModalForm}
      <Banners banners={banner} onDismissBanner={() => setBanner([])} />
      {prohibited ? <ProhibitedPage setSelected={props.setSelected} hideSearch={props.hideSearch} /> : loading ? <Spinner /> : productpage}
    </div>
  );
};

const mapPropsToDispatch = (dispatch) => ({
  onTryAutoSignin: () => dispatch(actions.authCheckState())
});

const mapStateToProps = (state) => ({
  user: state.auth,
  lang: state.auth.lang
});

export default connect(mapStateToProps, mapPropsToDispatch)(PublicProduct);
