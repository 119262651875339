import React, { useState } from 'react';
import { Banner, Card, Form, FormLayout, Layout, TextField, TextStyle } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { TrashIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { v4 as uuidv4 } from 'uuid';
import DragnDropArray from '../Shared/DragnDropArray';
import TinymceEditor from '../Tinymce/TinymceEditor';
import SwitchToggle from '../Shared/SwitchToggle';

const PageBuilder = (props) => {
  const [pageReorderMode, setPageReorderMode] = useState(false);
  const [selectedPage, setSelectedPage] = useState(props.pages[0]);

  const { t } = useTranslation();

  const getUpdatedPage = () => props.pages.find((page) => page.id === selectedPage.id);

  const addNewPage = () => {
    const page = {
      id: uuidv4(),
      content: 'new',
      accessibilityLabel: 'new',
      panelID: uuidv4(),
      data: ''
    };

    props.onChange([...props.pages, page]);

    setSelectedPage(page);
  };

  const removePage = (page) => {
    if (page.id === 'booking') {
      props.removeBookingPage('show_bookings')(false);
    }

    const temp = [...props.pages];
    const index = temp.findIndex((data) => data.id === page.id);
    temp.splice(index, 1);
    props.onChange(temp);

    setSelectedPage(props.pages[0]);
  };

  const displayPagesSelect = () => (
    <ul className="bg-slate-50 p-2 sm:px-6 sm:pt-4 sm:pb-6 lg:p-2 xl:px-6 xl:pt-4 xl:pb-6 grid gap-2 text-base leading-6">
      {props.pages.map((page, i) => (
        <li className="flex" key={i}>
          <div className="w-full">
            <dl className="grid sm:block lg:grid xl:block grid-cols-1 grid-rows-1 items-center">
              <div className="flex">
                <button
                  type="button"
                  className={`flex text-left hover:border-blue-500 border hover:border-solid hover:shadow-md group rounded-md p-3 ${page.id === selectedPage.id ? 'border-2 border-blue-500' : ''} bg-white ring-1 ring-slate-200 shadow-sm w-full`}
                  onClick={() => setSelectedPage(page)}
                >
                  <dt className="sr-only">{t('page_builder.title')}</dt>
                  <dd className="font-semibold text-slate-900 text-sm flex-auto">
                    {page.accessibilityLabel}
                  </dd>
                  {page.hidden && <EyeSlashIcon className="h-4 w-4" />}
                </button>
                {!page.disabled && (
                  <button type="button" onClick={() => removePage(page)}>
                    <div className="ml-2 hover:bg-red-500 hover:ring-red-500 hover:shadow-md group rounded-md p-3 bg-white ring-1 ring-slate-200 shadow-sm">
                      <TrashIcon className="h-4 w-4 group-hover:text-white " />
                    </div>
                  </button>
                )}
              </div>
            </dl>
          </div>
        </li>
      ))}
      <li className="flex">
        <button
          type="button"
          onClick={() => addNewPage()}
          className="hover:border-blue-500 hover:border-solid hover:bg-white hover:text-blue-500 group w-full flex flex-col items-center justify-center rounded-md border-2 border-dashed border-slate-300 text-sm leading-6 text-slate-900 font-medium py-3"
        >
          <svg className="group-hover:text-blue-500 mb-1 text-slate-400" width="20" height="20" fill="currentColor" aria-hidden="true">
            <path d="M10 5a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H6a1 1 0 1 1 0-2h3V6a1 1 0 0 1 1-1Z" />
          </svg>
          {t('page_builder.new_page')}
        </button>
      </li>
    </ul>
  );

  return (
    <Layout>
      <Layout.Section secondary>
        <Card title="Pages" actions={[{ content: pageReorderMode ? t('page_builder.builder_tool') : t('page_builder.reorder_tool'), onAction: () => setPageReorderMode(!pageReorderMode) }]}>
          <Card.Section>
            {pageReorderMode ? (
              <DragnDropArray data={props.pages} onChange={props.onChange} lockIndexes={[0]} labelKey="content" />
            ) : displayPagesSelect()}
          </Card.Section>
          <Card.Section title="Tools">
            <button
              type="button"
              className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
                />
              </svg>
              <span className="mt-2 block text-sm font-medium text-gray-900">{t('page_builder.coming_soon')}</span>
            </button>
          </Card.Section>
        </Card>
      </Layout.Section>
      <Layout.Section>
        <Card title={`Selected Page: ${selectedPage.accessibilityLabel}`}>
          <Card.Section title="Meta Data">
            <Form>
              <FormLayout>
                <div className="flex space-x-10">
                  <TextField
                    label="Label"
                    value={getUpdatedPage().content}
                    onChange={(data) => {
                      props.handlePageUpdate(selectedPage.id, 'content')(data);
                      props.handlePageUpdate(selectedPage.id, 'accessibilityLabel')(data);
                    }}
                    autoComplete="new-password"
                  />
                  {!['booking', 'faq'].includes(getUpdatedPage().id) && (
                    <>
                      <div>
                        <TextStyle>{t('page_builder.custom_settings')}</TextStyle>
                        <br />
                        <SwitchToggle
                          handleChange={props.handlePageUpdate(selectedPage.id, 'customMeta')}
                          checked={getUpdatedPage().customMeta}
                        />
                      </div>
                      <div>
                        <TextStyle>{t('page_builder.external_link')}</TextStyle>
                        <br />
                        <SwitchToggle
                          handleChange={props.handlePageUpdate(selectedPage.id, 'isEternalLink')}
                          checked={getUpdatedPage().isEternalLink}
                        />
                      </div>
                      <div>
                        <TextStyle>{t('page_builder.hidden')}</TextStyle>
                        <br />
                        <SwitchToggle
                          handleChange={props.handlePageUpdate(selectedPage.id, 'hidden')}
                          checked={getUpdatedPage().hidden}
                        />
                      </div>
                    </>
                  )}
                </div>
                {!['home', 'booking', 'faq'].includes(getUpdatedPage().id) && (
                  <>
                    <br />
                    {getUpdatedPage().customMeta && (
                      <FormLayout>
                        <TextField
                          label={t('page_builder.copyright')}
                          value={getUpdatedPage().imageURL}
                          onChange={props.handlePageUpdate(selectedPage.id, 'imageURL')}
                          autoComplete="new-password"
                        />
                        {getUpdatedPage().isEternalLink && (
                          <TextField
                            label={t('page_builder.external_url')}
                            value={getUpdatedPage().externalURL}
                            onChange={props.handlePageUpdate(selectedPage.id, 'externalURL')}
                            autoComplete="new-password"
                          />
                        )}
                      </FormLayout>
                    )}
                  </>
                )}
              </FormLayout>
            </Form>
          </Card.Section>
          <Card.Section title={selectedPage.accessibilityLabel}>
            <TinymceEditor
              value={getUpdatedPage().data || ''}
              handleChange={props.handlePageUpdate(selectedPage.id, 'data')}
            />
          </Card.Section>
          {selectedPage.id === 'booking' && (
            <Card.Section>
              <Banner status="info">
                <p>{t('page_builder.booking_section_will_show_up')}</p>
              </Banner>
            </Card.Section>
          )}
        </Card>
      </Layout.Section>
    </Layout>
  );
};

export default PageBuilder;
