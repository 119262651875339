import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import Clients from '../components/Clients/Clients';
import Calendar from '../components/Calendar/Calendar';
import Client from '../components/Clients/Client';
import Event from '../components/Events/Event';
import ParticipationManagement from '../components/Participations/ParticipationManagement';
import Profile from '../components/Settings/Profile';
import Invoices from '../components/Invoices/Invoices';
import NewInvoice from '../components/Invoices/NewInvoice';
import Home from '../components/Home/Home';
import ClientEvents from '../components/Clients/ClientEvents';
// import ClientConversation from '../components/Clients/ClientConversation';
// import ClientIndividualConversation from '../components/Clients/ClientIndividualConversation';
import Payments from '../components/Payments/Payments';
import CreateInvoice from '../components/Invoices/CreateInvoice';
import AutoCreateInvoice from '../components/Invoices/AutoCreateInvoice';
import PlanSettings from '../components/Settings/PlanSettings';
import Help from '../components/Help/Help';
import ClientSettings from '../components/Settings/ClientSettings';
import EmailSettings from '../components/Settings/EmailSettings';
import CustomDomain from '../components/Settings/CustomDomain';
import Integrations from '../components/Settings/Integrations';
import InvoiceSettings from '../components/Settings/InvoiceSettings';
import CalendarSettings from '../components/Settings/CalendarSettings';
import PublicProfileEdit from '../components/PublicProfile/PublicProfileEdit';
import ReadOnly from '../components/PublicProfile/ReadOnly';
import Product from '../components/Products/Product';
import PublicProduct from '../components/Products/PublicProduct';
import Events from '../components/Events/Events';
import ProductBooking from '../components/ProductBookings/ProductBooking';
import StaffAccounts from '../components/StaffAccounts/StaffAccounts';
import StaffAccount from '../components/StaffAccounts/StaffAccount';
import Products from '../components/Products/Products';
import Documentation from './Documentation';
import ZoomDocumentation from './ZoomDocumentation';
import MediaLibrary from '../components/MediaLibrary/MediaLibrary';
import EmailTemplate from '../components/Settings/EmailTemplates/EmailTemplate';
import Google from '../components/Settings/Integrations/Google';
import NewProduct from '../components/Products/NewProduct';
import Membership from '../components/Products/Membership';
import SessionHistoryDetails from '../components/Clients/SessionHistory/SessionHistoryDetails';
import Memberships from '../components/Products/Memberships';
import Accountants from '../components/Accountants/Accountants';
import Accountant from '../components/Accountants/Accountant';
import Subscriptions from '../components/Subscriptions/Subscriptions';
import PublicBooking from '../components/Products/PublicBooking';
import Surveys from '../components/Surveys/Surveys';
import Survey from '../components/Surveys/Survey';
import Replies from '../components/Surveys/Replies';
import FillOutForm from '../components/Surveys/FillOutForm';
import FilledOutForms from '../components/Surveys/FilledOutForms';
import ProgramSpaces from '../components/Products/ProgramSpaces';
import GiftCards from '../components/GiftCards/GiftCards';
import Flows from '../components/Flows/Flows';
import Flow from '../components/Flows/Flow';
import EmailTemplates from '../components/EmailTemplates/EmailTemplates';
import GiftCardTransactions from '../components/GiftCards/GiftCardTransactions';
// import Planning from '../components/Planning/Planning';
import EventPackages from '../components/EventPackages/EventPackages';
import LessonPackage from '../components/Products/LessonPackage';
import HolidayCalendar from '../components/HolidayCalendar/HolidayCalendar';
import EventPackageTransactions from '../components/EventPackages/EventPackageTransactions';
import ForwardBookingPortal from '../components/Shared/ForwardBookingPortal';
import RecurringProduct from '../components/Products/RecurringProduct';
// import Messages from '../components/Messages/Messages';

const InstructorRoutes = (props) => {
  const canAccessInvoices = () => {
    if (props.hasInvoiceAccess) return true;

    return false;
  };

  const canAccessPayments = () => {
    if (props.hasInvoiceAccess) return true;

    return false;
  };

  return (
    <Routes>
      <Route exact path="/home" element={<Home />} />
      <Route exact path="/calendar" element={<Calendar />} />
      <Route exact path="/clients" element={<Clients />} />
      <Route exact path="/clients/:id" element={<Client />} />
      <Route exact path="/clients/:id/session_histories" element={<SessionHistoryDetails />} />
      <Route exact path="/clients/:id/events" element={<ClientEvents />} />
      {/* <Route exact path="/clients/:id/feedback" element={<ClientConversation />} /> */}
      {/* <Route exact path="/clients/:id/feedback/:feedbackId" element={<ClientIndividualConversation />} /> */}
      <Route exact path="/client_settings" element={<ClientSettings />} />
      {props.isPremiumPlusMember ? <Route exact path="/custom_settings" element={<CustomDomain />} /> : null}
      {!props.isFreeMember ? <Route exact path="/email_settings" element={<EmailSettings />} /> : null}
      <Route exact path="/email_templates" element={<EmailTemplates />} />
      <Route exact path="/events" element={<Events />} />
      <Route exact path="/events/:id" element={<Event />} />
      <Route exact path="/flows/:id" element={<Flow />} />
      <Route exact path="/flows" element={<Flows />} />
      {/* <Route exact path="/planning" element={<Planning />} /> */}
      {/* <Route exact path="/messages" element={<Messages />} /> */}
      <Route exact path="/surveys" element={<Surveys />} />
      <Route exact path="/surveys/:id" element={<Survey />} />
      <Route exact path="/surveys/:id/replies" element={<Replies />} />
      <Route exact path="/survey_reply/:token" element={<FillOutForm />} />
      <Route exact path="/events/:id/participation_management" element={<ParticipationManagement />} />
      <Route exact path="/help" element={<Help />} />
      {canAccessInvoices() ? <Route exact path="/invoices/auto" element={<AutoCreateInvoice />} /> : null}
      {canAccessInvoices() ? <Route exact path="/invoices/create" element={<CreateInvoice />} /> : null}
      {canAccessInvoices() ? <Route exact path="/invoices" element={<Invoices />} /> : null}
      {canAccessInvoices() ? <Route exact path="/invoices/:id" element={<NewInvoice />} /> : null}
      <Route exact path="/integrations" element={<Integrations />} />
      {canAccessInvoices() ? <Route exact path="/accountants" element={<Accountants />} /> : null}
      {canAccessInvoices() ? <Route exact path="/accountants/:token" element={<Accountant />} /> : null}
      <Route exact path="/integrations/google" element={<Google />} />
      <Route exact path="/v1/zoom" element={<Integrations />} />
      {canAccessInvoices() ? <Route exact path="/invoice_settings" element={<InvoiceSettings />} /> : null}
      <Route exact path="/calendar_settings" element={<CalendarSettings />} />
      {canAccessPayments() ? <Route exact path="/payments" element={<Payments />} /> : null}
      {!props.isStaff ? <Route exact path="/plans" element={<PlanSettings />} /> : null}
      {props.isStaff ? <Route exact path="/answered_surveys" element={<FilledOutForms />} /> : null}
      <Route exact path="/profile_settings" element={<Profile />} />
      {props.hasStaffAccounts && !props.isStaff && <Route exact path="/staff_accounts" element={<StaffAccounts />} />}
      {props.hasStaffAccounts && !props.isStaff && <Route exact path="/staffs/:token" element={<StaffAccount />} />}
      {props.bookingPageAccess && <Route exact path="/public_profile_edit" element={<PublicProfileEdit />} />}
      <Route exact path="/booking/:name" element={<ReadOnly />} />
      <Route exact path="/products" element={<Products />} />
      {props.hasInvoiceAccess && props.hasStripe && <Route exact path="/memberships" element={<Memberships />} />}
      {props.hasInvoiceAccess && props.hasStripe && <Route exact path="/memberships/new" element={<Membership />} />}
      {props.hasInvoiceAccess && props.hasStripe && <Route exact path="/memberships/:token" element={<Membership />} />}
      <Route exact path="/products/:token" element={<Product />} />
      <Route exact path="/products/new" element={<NewProduct />} />
      <Route exact path="/products/:token" element={<Product />} />
      {!props.isStaff ? <Route exact path="/gift_cards" element={<GiftCards />} /> : null}
      {!props.isStaff ? <Route exact path="/event_packages" element={<EventPackages />} /> : null}
      <Route exact path="/event_package/new" element={<LessonPackage />} />
      <Route exact path="/event_package/:token" element={<LessonPackage />} />
      <Route exact path="/recurring_event/new" element={<RecurringProduct />} />
      <Route exact path="/recurring_event/:token" element={<RecurringProduct />} />
      {!props.isStaff ? <Route exact path="/event_packages/:token/event_package_transactions" element={<EventPackageTransactions />} /> : null}
      {!props.isStaff ? <Route exact path="/gift_cards/:token/gift_card_transactions" element={<GiftCardTransactions />} /> : null}
      <Route exact path="/program_spaces" element={<ProgramSpaces />} />
      <Route exact path="/product_booking/:token" element={<ProductBooking />} />
      <Route exact path="/embeddedbooking/:token" element={<PublicProduct />} />
      <Route path="/manage_booking/:token" exact element={<PublicBooking />} />
      <Route path="/time_off" exact element={<HolidayCalendar />} />
      <Route exact path="/media_library" element={<MediaLibrary />} />
      <Route exact path="/documentation" element={<Documentation />} />
      <Route exact path="/subscriptions" element={<Subscriptions />} />
      <Route exact path="/email_settings/email_template/:path" element={<EmailTemplate />} />
      <Route exact path="/zoom_documentation" element={<ZoomDocumentation />} />
      <Route exact path="/my_portal" element={<ForwardBookingPortal />} />
      <Route path="*" element={<Navigate to="/home" />} />
    </Routes>
  );
};

const mapStateToProps = (state) => ({
  isFreeMember: state.auth.freeMember,
  isBasicMember: state.auth.basicMember,
  isPremiumMember: state.auth.premiumMember,
  isPremiumPlusMember: state.auth.premiumPlusMember,
  isStaff: state.auth.role === 'staff',
  hasStaffAccounts: state.auth.instructor || state.auth.admin,
  bookingPageAccess: state.auth.bookingPageAccess,
  hasInvoiceAccess: state.auth.hasInvoiceAccess,
  hasStripe: state.auth.hasStripe
});

export default connect(mapStateToProps)(InstructorRoutes);
