import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Form, FormLayout, TextField, TextStyle, Banner, Spinner } from '@shopify/polaris';
import classes from './Auth.module.scss';
import logo from '../../assets/images/planubo-blue.png';
import * as actions from '../../store/actions/index';

const Auth = (props) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [password, setPassword] = useState('');

  const { t } = useTranslation('translation');
  const location = useLocation();
  const history = useNavigate();

  const query = new URLSearchParams(location.search);
  const next = query.get('next');
  const process = query.get('process');
  const zoomCode = query.get('code');

  const handleSubmit = (event) => {
    event.preventDefault();
    props.onAuth(email, password, history, zoomCode, `?next=${next}`);
  };

  const handleEmailChange = useCallback((value) => setEmail(value), []);
  const handlePasswordChange = useCallback((value) => setPassword(value), []);

  const emailField = (
    <TextField
      value={email}
      onChange={handleEmailChange}
      placeholder={t('user.email')}
      type="email"
    />
  );

  const passwordfield = (
    <TextField
      value={password}
      onChange={handlePasswordChange}
      placeholder={t('user.password')}
      type="password"
    />
  );

  const { error } = props;

  useEffect(() => {
    if (error) {
      if (error.response?.data?.error === 'Your account is not activated yet.') {
        setMessage(<Banner title={t('user.deactivated_acount')} status="critical" />);
      } else {
        setMessage(<Banner title={error.response?.data?.error || t('user.not_authorized')} status="critical" />);
      }
      setTimeout(() => {
        setMessage('');
      }, 3000);
    } else {
      switch (process) {
        case 'passwordreset':
          setMessage(<Banner title={t('user.password_changed')} status="success" />);
          break;
        default:
      }
    }
  }, [error, process, t]);

  const showRegistration = () => {
    switch (window.location.host) {
      case 'bookyourexpert.planubo.com': return false;
      default: return true;
    }
  };

  return (
    <div className={classes.Auth}>
      <Form onSubmit={handleSubmit}>
        <FormLayout>
          <div className="flex justify-center">
            {!props.loadingLogo ? <img src={props.brand?.loginLogoUrl || logo} alt="planubo" /> : (
              <Spinner />
            )}
          </div>
          {message}
          {emailField}
          {passwordfield}
          <Button fullWidth primary submit>{t('user.sign_in')}</Button>
          {showRegistration() ? <Button fullWidth secondary url="/signup">{t('user.register')}</Button> : null}
          <Button plain url="/forgot-password">{t('user.forgot_password')}</Button>
          <TextStyle variation="subdued">© 2024 Planubo. All Rights Reserved.</TextStyle>
        </FormLayout>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  brand: state.auth.brand,
  loading: state.auth.loading,
  loadingLogo: state.auth.loadingLogo,
  error: state.auth.error,
  isAuthenticated: state.auth.token !== null
});

const mapDispatchToProps = (dispatch) => ({
  checkAuthState: (url, history) => dispatch(actions.authCheckState(url, history)),
  onAuth: (email, password, history, zoomCode, next) => dispatch(actions.auth(email, password, history, false, false, zoomCode, next))
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
