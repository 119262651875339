import { Card, FormLayout, Layout, Page } from '@shopify/polaris';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ExternalLink from '../Shared/ExternalLink';

const Settings = () => {
  const { t } = useTranslation();

  return (
    <Page
      title={t('help.help')}
      separator
    >
      <FormLayout>
        <Layout>
          <Layout.AnnotatedSection
            title={t('help.bug')}
            description={t('help.something_isnt_working')}
          >
            <Card sectioned>
              <Card.Section>{t('help.bug_description')}</Card.Section>
              <Card.Section>
                help@planubo.com
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>

          <Layout.AnnotatedSection
            title="Feedback"
          >
            <Card sectioned>
              <Card.Section>{t('help.feedback_desc')}</Card.Section>
              <Card.Section>
                feedback@planubo.com
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>

          <Layout.AnnotatedSection
            title={t('help.documentation')}
          >
            <Card sectioned>
              <Card.Section>
                {/* Deutsch - https://planubo.notion.site/Planubo-Handbuch-1ea1960ec8f643a495d6f33340488b08 */}
                <ExternalLink label={t('help.help_doc')} url="https://www.notion.so/Planubo-Help-Doc-63b7b34ee37c402383211473b58be8fb" />
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>

          <Layout.AnnotatedSection
            title="Roadmap"
            description={t('help.roadmap_desc')}
          >
            <Card sectioned>
              <Card.Section>
                <ExternalLink label="https://roadmap.planubo.com" url="https://roadmap.planubo.com/public-2022" />
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>

        </Layout>
      </FormLayout>
    </Page>
  );
};

export default Settings;
