/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import { Filters, Card, SkeletonPage, Page, DataTable, Button } from '@shopify/polaris';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import useDebounce from '../../hooks/useDebounce';
import { stateConverter } from '../FilterEngine/filterParams';
import { handleFilterChange, searchFilterToURL, appliedFiltersQuery } from '../FilterEngine/FilterEngine';
import TablePagination from '../FilterEngine/TablePagination/TablePagination';
import { defaultSortDirection, handleSort, initialSortColumnIndex } from '../FilterEngine/Sort/sort';
import { ReactFormGenerator } from '../Shared/FormBuilder';

const Replies = () => {
  const [title, setTitle] = useState('');
  const [viewReply, setViewReply] = useState(null);
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState([]);
  const [mounted, setMounted] = useState(true);
  const [init, setInit] = useState(false);
  const [lastPage, setLastPage] = useState(null);

  const { t } = useTranslation();
  const history = useNavigate();
  const location = useLocation();
  const matchParams = useParams();

  const [tableFilters, setTableFilters] = useState(stateConverter(location));
  const debouncedSearch = useDebounce(tableFilters, 400);

  const filters = [];

  const appliedFilters = useCallback(() => {
    const af = [];

    return af;
  }, []);

  const openReply = (row) => {
    setViewReply({
      data: JSON.parse(row.data),
      answerData: JSON.parse(row.answerData)
    });
  };

  const deleteAnswer = (token) => {
    if (!window.confirm(t('shared.are_you_sure'))) return;

    axios.post(`/v1/surveys/${matchParams.id}/remove_user_reply`, { token })
      .then(() => {
        fetchData();
      })
      .catch(() => {});
  };

  const modifier = (tableData) => tableData.map((row, i) => {
    const modifiedRow = [];

    modifiedRow.push(row.name);
    modifiedRow.push(row.email);
    modifiedRow.push(moment(row.createdAt).format('LLLL'));
    modifiedRow.push(row.ip);
    modifiedRow.push(
      <div className="flex">
        <Button onClick={() => openReply(row)}>{t('suvey.view')}</Button>
        <Button destructive onClick={() => deleteAnswer(row.token)}>Delete</Button>
      </div>
    );

    return modifiedRow;
  });

  const fetchData = useCallback(() => {
    const params = {
      per_page: 30,
      page: tableFilters.page,
      search: tableFilters.queryValue,
      status: tableFilters.tab === 'All' ? '' : tableFilters.tab,
      q: appliedFiltersQuery(appliedFilters())
    };

    axios.post(`/v1/surveys/${matchParams.id}/replies`, params)
      .then((res) => {
        if (mounted) {
          setTitle(res.data.title);
          setRecords(res.data.survey_answers);
          setLastPage(res.data.lastPage);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  // eslint-disable-next-line
  }, [debouncedSearch]);

  const filterControl = (
    <Filters
      queryValue={tableFilters.queryValue}
      filters={filters}
      onQueryChange={handleFilterChange({ history, location }, setTableFilters, 'queryValue')}
      onQueryClear={handleFilterChange({ history, location }, setTableFilters, 'queryValue', 'reset', '')}
      onClearAll={handleFilterChange({ history, location }, setTableFilters, '/surveys', 'resetAll')}
      appliedFilters={appliedFilters()}
      queryPlaceholder={t('user.search')}
    />
  );

  useEffect(() => {
    setMounted(true);
    fetchData();
    return () => {
      setMounted(false);
    };
  }, [fetchData]);

  useEffect(() => {
    if (init) {
      setTableFilters((f) => ({ ...f, page: 1 }));
      searchFilterToURL({ history, location }, 'page', null, 'delete');
    }
    setInit(true);
  // eslint-disable-next-line
  }, [tableFilters.queryValue, tableFilters.role, tableFilters.userType]);

  const indexSortMapping = {
    status: true,
    created_at: false
  };

  const headings = [
    t('user.name'),
    t('user.email'),
    t('survey.completed_on'),
    'IP',
    ''
  ];

  const content = (
    <DataTable
      columnContentTypes={[
        'text',
        'text',
        'text',
        'text',
        'text'
      ]}
      verticalAlign="middle"
      headings={headings}
      rows={modifier(records)}
      sortable={Object.values(indexSortMapping)}
      defaultSortDirection={defaultSortDirection(tableFilters.sorts)}
      initialSortColumnIndex={initialSortColumnIndex(indexSortMapping, tableFilters.sorts)}
      onSort={handleSort({ location, history }, setTableFilters, indexSortMapping)}
    />
  );

  return (
    <Page
      title={viewReply ? '' : `${title} - ${t('survey.replies')}`}
      fullWidth
      separator
      narrowWidth={viewReply}
      breadcrumbs={viewReply ? [{ content: 'Back', onAction: () => setViewReply(null) }] : [{ content: 'Back', onAction: () => history(-1) }]}
    >
      {viewReply ? (
        <Card sectioned>
          <ReactFormGenerator
            read_only
            hide_actions
            data={viewReply.data || []}
            answer_data={viewReply.answerData || []}
          />
        </Card>
      ) : (
        <>
          {!loading ? (
            <Card sectioned>
              <Card.Subsection>
                {filterControl}
              </Card.Subsection>
              <Card.Subsection>
                {content}
              </Card.Subsection>
            </Card>
          ) : <SkeletonPage />}
          <TablePagination
            pageFilter={tableFilters.page}
            setTableFilters={setTableFilters}
            records={records}
            lastPage={lastPage}
          />
        </>
      )}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.admin
});

export default connect(mapStateToProps)(Replies);
