import React, { useState, useCallback, useEffect } from 'react';
import { Card, Layout, SkeletonPage, DataTable, Filters, Button, Page, Badge, Stack } from '@shopify/polaris';
import axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { ExportMinor } from '@shopify/polaris-icons';
import Banners from '../Shared/Banners';
import useDebounce from '../../hooks/useDebounce';
import { stateConverter } from '../FilterEngine/filterParams';
import { defaultSortDirection, initialSortColumnIndex, handleSort } from '../FilterEngine/Sort/sort';
import { handleFilterChange, appliedFiltersQuery } from '../FilterEngine/FilterEngine';
import createdAtAfterFilterDefinition from '../FilterEngine/CreatedAtAfter/createdAtAfterFilterDefinition';
import createdAtAfterFilterActive from '../FilterEngine/CreatedAtAfter/createdAtAfterFilterActive';
import createdAtBeforeFilterDefinition from '../FilterEngine/CreatedAtBefore/createdAtBeforeFilterDefinition';
import createdAtBeforeFilterActive from '../FilterEngine/CreatedAtBefore/createdAtBeforeFilterActive';
import TablePagination from '../FilterEngine/TablePagination/TablePagination';
import StatusBadge from '../Shared/StatusBadge';
import clientFilterDefinition from '../FilterEngine/Client/clientFilterDefinition';
import productFilterDefinition from '../FilterEngine/Product/productFilterDefinition';
import isEmpty from '../Shared/isEmpty';
import clientFilterActive from '../FilterEngine/Client/clientFilterActive';
import productFilterActive from '../FilterEngine/Product/productFilterActive';
import staffFilterActive from '../FilterEngine/Staff/staffFilterActive';
import staffFilterDefinition from '../FilterEngine/Staff/staffFilterDefinition';
import Download from '../Shared/Download';

const Events = (props) => {
  const [loading, setLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [banner, setBanner] = useState([]);
  const [events, setEvents] = useState([]);
  const [lastPage, setLastPage] = useState(null);
  const [mounted, setMounted] = useState(true);
  const [totalEvents, setTotalEvents] = useState(0);
  const [filterValues, setFilterValues] = useState({
    clients: [],
    staffs: []
  });

  const { t } = useTranslation();
  const history = useNavigate();
  const location = useLocation();

  const [tableFilters, setTableFilters] = useState(stateConverter(location, [
    { key: 'sorts', value: 'start_time desc ' },
    { key: 'createdAtAfter', value: { start: new Date(moment().clone().startOf('month')), end: new Date(moment().clone().startOf('month')) } },
    { key: 'createdAtBefore', value: { start: new Date(moment().add(1, 'M').clone().startOf('month')), end: new Date(moment().add(1, 'M').clone().startOf('month')) } }
  ]));
  const debouncedSearch = useDebounce(tableFilters, 400);

  const participationBadges = (participations) => {
    // eslint-disable-next-line
    const badges = (participations || []).map((p, i) => <div key={i} role="button" style={{ cursor: 'pointer' }} onClick={() => history(`/clients/${p.token}`)}><Badge>{p.name}</Badge></div>);

    return <Stack>{badges}</Stack>;
  };

  const modifier = useCallback(
    (tableData) => tableData.map((row) => {
      const modifiedRow = [];

      modifiedRow.push(<Button plain url={`/events/${row.id}`}>{moment(row.start).format('llll')}</Button>);
      modifiedRow.push(row.product_token ? <div className="whitespace-pre-wrap"><Button plain url={`/products/${row.product_token}`}>{row.title}</Button></div> : <div className="whitespace-pre-wrap">{row.title}</div>);
      modifiedRow.push(participationBadges(row.clients));
      modifiedRow.push(<StatusBadge active={row.product_token} />);
      const b = row.online ? <Badge status="success" size="small">Online</Badge> : <Badge status="info" size="small">In-Person</Badge>;
      modifiedRow.push(b);
      if (props.hasStaffAccounts) {
        modifiedRow.push(<Badge status="warning" size="small">{row.user}</Badge>);
      }

      return modifiedRow;
    }),
    // eslint-disable-next-line
    []
  );

  const exportEvents = () => {
    setIsExporting(true);

    const params = {
      per_page: 25,
      page: tableFilters.page,
      search: tableFilters.queryValue,
      q: appliedFiltersQuery(appliedFilters(), false),
      m: appliedFiltersQuery(appliedFilters(), true),
      s: { sorts: tableFilters.sorts }
    };

    axios.post('/v1/events/export', params, { responseType: 'blob' })
      .then((res) => {
        Download(res);
        setIsExporting(false);
      })
      .catch((err) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: err.response.data.errors }]);
        setIsExporting(false);
      });
  };

  const fetchEvents = useCallback(() => {
    const params = {
      per_page: 25,
      page: tableFilters.page,
      search: tableFilters.queryValue,
      q: appliedFiltersQuery(appliedFilters(), false),
      m: appliedFiltersQuery(appliedFilters(), true),
      s: { sorts: tableFilters.sorts }
    };

    axios.post('/v1/events/search_dash', params)
      .then((res) => {
        if (mounted) {
          setEvents(modifier(res.data.events));
          setLastPage(res.data.lastPage);
          setTotalEvents(res.data.eventCount);
          setLoading(false);
        }
      })
      .catch((err) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: err.response.data.errors }]);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [debouncedSearch]);

  useEffect(() => {
    setMounted(true);
    fetchEvents();
    return () => {
      setMounted(false);
    };
  }, [fetchEvents]);

  const fetchFilterValues = useCallback(() => {
    axios.get('/v1/clients/filter_values')
      .then((res) => {
        setFilterValues(res.data);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    fetchFilterValues();
  }, [fetchFilterValues]);

  const filters = [
    createdAtAfterFilterDefinition(t, { history, location }, setTableFilters, tableFilters.createdAtAfter, tableFilters.createdAtAfterMonthYear),
    createdAtBeforeFilterDefinition(t, { history, location }, setTableFilters, tableFilters.createdAtBefore, tableFilters.createdAtBeforeMonthYear),
    clientFilterDefinition(t, { history, location }, setTableFilters, filterValues.clients, tableFilters.client),
    staffFilterDefinition(t, { history, location }, setTableFilters, filterValues.staffs, tableFilters.staff),
    productFilterDefinition(t, { history, location }, setTableFilters, filterValues.products, tableFilters.product)
  ];

  // add booking information when displaying an event

  // graph with product, how often product booked in current selection + total bookings + only product bookings

  const appliedFilters = useCallback(() => {
    const af = [];

    if (!tableFilters.createdAtAfter.default) {
      af.push(createdAtAfterFilterActive(t, { history, location }, setTableFilters, tableFilters.createdAtAfter, 'start_time_gteq'));
    }
    if (!tableFilters.createdAtBefore.default) {
      af.push(createdAtBeforeFilterActive(t, { history, location }, setTableFilters, tableFilters.createdAtBefore, 'start_time_lteq'));
    }
    if (!isEmpty(tableFilters.client)) {
      af.push(clientFilterActive(t, { history, location }, setTableFilters, filterValues.clients, tableFilters.client, ['product_bookings_client_token_eq', 'participations_client_token_eq']));
    }
    if (!isEmpty(tableFilters.product)) {
      af.push(productFilterActive(t, { history, location }, setTableFilters, filterValues.products, tableFilters.product));
    }

    if (props.hasStaffAccounts && !props.isStaff) {
      if (!isEmpty(tableFilters.staff)) {
        af.push(staffFilterActive(t, { history, location }, setTableFilters, filterValues.staffs, tableFilters.staff));
      }
    }

    return af;
  }, [tableFilters.createdAtAfter, tableFilters.createdAtBefore, tableFilters.client, tableFilters.product, tableFilters.staff, props.hasStaffAccounts, props.isStaff, t, history, location, filterValues.clients, filterValues.products, filterValues.staffs]);

  const placeHolderText = '';

  const filterControl = (
    <Filters
      queryValue={tableFilters.queryValue}
      filters={filters}
      onQueryChange={handleFilterChange({ history, location }, setTableFilters, 'queryValue')}
      onQueryClear={handleFilterChange({ history, location }, setTableFilters, 'queryValue', 'reset', '')}
      onClearAll={() => {}}
      appliedFilters={appliedFilters()}
      queryPlaceholder={placeHolderText}
    />
  );

  const indexSortMapping = {
    start_time: true,
    no_show: false,
    paid: false
  };

  const headings = [
    t('shared.time'),
    t('shared.title'),
    t('client.clients'),
    t('public_profile.portal'),
    t('shared.type'),
    'Assignee'
  ];

  if (!props.hasStaffAccounts) {
    headings.pop();
  }

  const content = (
    <DataTable
      columnContentTypes={[
        'text',
        'text',
        'text',
        'text',
        'text',
        'text'
      ]}
      verticalAlign="middle"
      headings={headings}
      rows={events}
      sortable={Object.values(indexSortMapping)}
      defaultSortDirection={defaultSortDirection(tableFilters.sorts)}
      initialSortColumnIndex={initialSortColumnIndex(indexSortMapping, tableFilters.sorts)}
      onSort={handleSort({ location, history }, setTableFilters, indexSortMapping)}
      footerContent={`${events.length} ${t('shared.of')} ${totalEvents}`}
    />
  );

  return (
    <Page
      primaryAction={(props.isAdmin || props.isInstructor) && { icon: ExportMinor, content: 'Export', onAction: () => exportEvents(), disabled: isExporting, loading: isExporting }}
      title={props.title ?? t('calendar.events')}
      fullWidth
      separator
    >
      {loading ? <SkeletonPage /> : (
        <>
          <Banners banners={banner} onDismissBanner={() => setBanner([])} />
          <Layout>
            <Layout.Section>
              {!loading ? (
                <Card sectioned>
                  <Card.Subsection>
                    {filterControl}
                  </Card.Subsection>
                  <Card.Subsection>
                    {content}
                  </Card.Subsection>
                </Card>
              ) : <SkeletonPage />}
              <TablePagination
                pageFilter={tableFilters.page}
                setTableFilters={setTableFilters}
                records={events}
                length={25}
                lastPage={lastPage}
              />
            </Layout.Section>
          </Layout>
        </>
      )}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  hasInvoiceAccess: state.auth.hasInvoiceAccess,
  isInstructor: state.auth.instructor,
  isStaff: state.auth.role === 'staff',
  isAdmin: state.auth.admin,
  hasStaffAccounts: state.auth.instructor || state.auth.admin
});

export default connect(mapStateToProps)(Events);
