/* eslint-disable camelcase */
import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Page, Layout, Toast, Card, TextStyle, Tooltip, Icon, ResourceList, ResourceItem, Stack, EmptyState, TextField, ContextualSaveBar, Checkbox } from '@shopify/polaris';
import { EditMinor, ViewMinor } from '@shopify/polaris-icons';
import Select from 'react-select';
import CustomField from '../CustomFields/CustomField';

const defaultClientStatuses = [
  {
    label: 'Completed',
    value: 'validated'
  }, {
    label: 'New application',
    value: 'new_application'
  }, {
    label: 'Declined',
    value: 'declined'
  }, {
    label: 'Pending',
    value: 'pending'
  }
];

const ClientSettings = () => {
  const [selected, setSelected] = useState({});
  const [customFields, setCustomFields] = useState([]);
  const [showCustomFields, setShowCustomFields] = useState(false);
  const [eventCancelPolicyTime, setEventCancelPolicyTime] = useState('');
  const [deleteEventSetting, setDeleteEventSetting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [surveyLoading, setSurveyLoading] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState({});
  const [surveys, setSurveys] = useState([]);
  const [showSaveBar, setShowSaveBar] = useState(false);
  const [restrictedClientVisiblity, setRestrictedClientVisibility] = useState(false);
  const [defaultClientStatus, setDefaultClientStatus] = useState({});

  const { t } = useTranslation();

  const [toastMessage, setToastMessage] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const fetchSettings = useCallback(() => {
    axios.get('/v1/client_settings/custom_fields')
      .then((response) => {
        setCustomFields(response.data);
      })
      .catch(() => {
      });
  }, []);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  const fetchSurveys = useCallback(() => {
    setSurveyLoading(true);

    axios.get('/v1/client_settings/user_surveys')
      .then((response) => {
        setSurveys(response.data.surveys);
      })
      .catch(() => {
      })
      .then(() => {
        setSurveyLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchSurveys();
  }, [fetchSurveys]);

  const updateClientSettings = () => {
    setLoading(true);

    const params = {
      client_setting: {
        event_cancel_policy_time: eventCancelPolicyTime,
        delete_event_on_cancel: deleteEventSetting,
        survey_token: selectedSurvey?.value || null,
        default_client_status: defaultClientStatus?.value || 'validated',
        restricted_client_visibility: restrictedClientVisiblity
      }
    };

    axios.patch('/v1/client_settings', params)
      .then(() => {
        toggleToastActive(t('shared.saved'));
      })
      .catch(() => {
        toggleToastActive(t('shared.unable_to_save'));
      })
      .then(() => {
        setShowSaveBar(false);
        setLoading(false);
      });
  };

  const fetchClientSettings = useCallback(() => {
    axios.get('/v1/client_settings')
      .then((response) => {
        setEventCancelPolicyTime(response.data.event_cancel_policy_time);
        setSelectedSurvey(response.data.survey_token);
        setDeleteEventSetting(response.data.delete_event_on_cancel);
        setDefaultClientStatus(defaultClientStatuses.find((s) => s.value === response.data.default_client_status));
        setRestrictedClientVisibility(response.data.restricted_client_visibility);
      })
      .catch(() => {
      });
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchClientSettings();
  }, [fetchClientSettings]);

  const handleClientSettingUpdate = (value) => {
    setEventCancelPolicyTime(value);
    setShowSaveBar(true);
  };

  const handleCreate = (data) => {
    const params = {
      custom_field: data
    };

    axios.post('/v1/client_settings/custom_fields/', params)
      // eslint-disable-next-line no-unused-vars
      .then((response) => {
        setCustomFields([...customFields, response.data.custom_field]);
        toggleToastActive(t('shared.saved'));
      })
      .catch(() => {
        toggleToastActive(t('shared.unable_to_save'));
      });
  };

  const handleSurveyChange = (val) => {
    setSelectedSurvey(val);
    setShowSaveBar(true);
  };

  const handleDefaultClientStatusChange = (val) => {
    setDefaultClientStatus(val);
    setShowSaveBar(true);
  };

  const handleUpdate = (data) => {
    const params = {
      custom_field: data
    };

    axios.patch(`/v1/client_settings/custom_fields/${selected.id}`, params)
      // eslint-disable-next-line no-unused-vars
      .then(() => {
        const temp = [...customFields];
        setCustomFields(temp.map((cf) => (data.id === cf.id ? data : cf)));
        setSelected({});
        toggleToastActive(t('shared.saved'));
      })
      .catch(() => {
        toggleToastActive(t('shared.unable_to_save'));
      });
  };

  const handleDelete = () => {
    axios.delete(`/v1/client_settings/custom_fields/${selected.id}`)
      // eslint-disable-next-line no-unused-vars
      .then(() => {
        const temp = [...customFields];
        const index = temp.findIndex((r) => r.id === selected.id);
        temp.splice(index, 1);
        setCustomFields(temp);
        setSelected({});
        toggleToastActive(t('shared.saved'));
      })
      .catch(() => {
        toggleToastActive(t('shared.unable_to_save'));
      });
  };

  const customFieldsAttributes = showCustomFields
    ? (
      <CustomField
        active={showCustomFields}
        close={() => setShowCustomFields(false)}
        handleSubmit={handleCreate}
        customField={{}}
        title={t('custom_fields.singular')}
      />
    ) : null;

  const customFieldsEditAttributes = Object.keys(selected).length
    ? (
      <CustomField
        active={Object.keys(selected).length}
        close={() => setSelected({})}
        handleSubmit={handleUpdate}
        handleDelete={handleDelete}
        customField={selected}
        title={t('custom_fields.singular')}
      />
    ) : null;

  const handleDeleteEventSettingChange = (value) => {
    setDeleteEventSetting(value);
    setShowSaveBar(true);
  };

  const handleRestrictedClientSettingChange = (value) => {
    setRestrictedClientVisibility(value);
    setShowSaveBar(true);
  };

  return (
    <Page
      title={t('navigation.client_settings')}
      separator
    >
      {toastMarkup}
      {customFieldsAttributes}
      {customFieldsEditAttributes}
      {showSaveBar && (
        <ContextualSaveBar
          message={t('shared.unsaved_changes')}
          saveAction={{
            onAction: () => updateClientSettings(),
            loading,
            disabled: false
          }}
          discardAction={{
            onAction: () => window.location.reload()
          }}
        />
      )}
      <Layout>
        <Layout.AnnotatedSection
          title={t('custom_fields.plural')}
          description={t('custom_fields.layout_annotation_desc')}
        >
          <Card sectioned actions={[{ content: t('shared.add'), onAction: () => setShowCustomFields(true) }]}>
            <Card.Section>
              <div style={{ borderBottom: '1px solid #cfcfcf' }}>
                <Card.Section>
                  <Stack>
                    <Stack.Item fill>{t('shared.label')}</Stack.Item>
                    <Stack.Item>{t('shared.value')}</Stack.Item>
                  </Stack>
                </Card.Section>
              </div>
              {customFields.length ? (
                <ResourceList
                  resourceName={{ singular: 'custom field', plural: 'custom fields' }}
                  items={customFields}
                  renderItem={(item) => {
                    const { id, label, value, editable_by_client, viewable_by_client } = item;

                    return (
                      <ResourceItem
                        id={id}
                        onClick={() => setSelected(item)}
                        accessibilityLabel={`View details for ${label}`}
                      >
                        <Stack spacing="tight" wrap={false}>
                          <TextStyle variation="strong">{label}</TextStyle>
                          {editable_by_client && <Tooltip content={t('custom_fields.editable')}><Icon source={EditMinor} color="base" /></Tooltip>}
                          {viewable_by_client && <Tooltip content={t('custom_fields.viewable')}><Icon source={ViewMinor} color="base" /></Tooltip>}
                          <Stack.Item fill />
                          <div>{value}</div>
                        </Stack>
                      </ResourceItem>
                    );
                  }}
                />
              ) : (
                <EmptyState image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png" />
              )}
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title={t('client.cancellation_policy')}
          description={t('client.cancellation_policy_desc')}
        >
          <Card sectioned>
            <TextField
              suffix={t('shared.minutes')}
              type="number"
              value={eventCancelPolicyTime}
              onChange={handleClientSettingUpdate}
            />
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title={t('client_settings.delete_event')}
        >
          <Card sectioned>
            <Checkbox
              checked={deleteEventSetting}
              onChange={handleDeleteEventSettingChange}
              label={t('client_settings.delete_event_desc')}
            />
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title={t('client_settings.login_popup')}
          description={t('client_settings.login_popup_desc')}
        >
          <Card sectioned>
            <div className="react-select">
              <Select
                value={selectedSurvey}
                isClearable
                onChange={handleSurveyChange}
                options={surveys}
                isLoading={surveyLoading}
                getOptionValue={(c) => c.value}
                getOptionLabel={(c) => c.label}
              />
            </div>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="Default client status"
          description="The default status of a new client"
        >
          <Card sectioned>
            <div className="react-select">
              <Select
                value={defaultClientStatus}
                isClearable
                onChange={handleDefaultClientStatusChange}
                options={defaultClientStatuses}
                getOptionValue={(c) => c.value}
                getOptionLabel={(c) => c.label}
              />
            </div>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="Restricted client visibility from staff accounts"
        >
          <Card sectioned>
            <div className="react-select">
              <Checkbox
                checked={restrictedClientVisiblity}
                onChange={handleRestrictedClientSettingChange}
                label="This policy ensures staff members can only access information about clients who have directly booked their services."
              />
            </div>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  lang: state.auth.lang
});

export default connect(mapStateToProps)(ClientSettings);
